import styled from '@emotion/styled';
import EastIcon from '@mui/icons-material/East';
import {
  Avatar,
  Button,
  Card as MuiCard,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import QuillHtmlRenderer from './QuillHtmlRenderer';

export const CardStyle = styled<any>(MuiCard)((props: any) => ({
  width: '32%',
  flexBasis: props.desktop === 'true' ? '32%' : '100%',
  maxWidth: props.desktop === 'true' ? '32.5%' : '100%',
  background: props.mode === 'light' ? ' #f2f2f2' : '#46485B',
  boxShadow: props.disabled ? 'none' : '0 6px 10px rgba(0,0,0,.15)',
  flexGrow: 1,
  flexShrink: 1,
  borderRadius: '5px',
  display: 'flex',
  alignContent: 'stretch',
  justifyContent: 'stretch',
  cursor: props.disabled ? 'default' : 'pointer',
  '*': {
    filter: props.disabled ? 'opacity(.8)' : 'none',
  },
  ...props.sx,
}));

const ImageStyle = styled(Avatar)`
  width: 70px;
  height: 70px;
  margin-right: 16px;
`;
const LabelStyle = styled(Typography)`
  margin-bottom: 8px;
`;

interface IProps {
  name: string;
  description: string;
  image?: string;
  linkTitle?: string;
  onClick: () => void;
  disabled?: boolean;
}

export const Card = ({
  name,
  description,
  image,
  onClick,
  linkTitle = 'Prepare Path',
  disabled,
}: IProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const themeMode = useTheme().palette.mode;

  return (
    <CardStyle desktop={`${isDesktop}`} mode={themeMode} disabled={disabled} onClick={disabled ? undefined : onClick}>
      <CardContent sx={{ padding: '24px 16px', display: 'flex' }}>
        <ImageStyle alt={image} srcSet={image || '/no_avatar.png'}></ImageStyle>
        <Grid container flexDirection='column'>
          <LabelStyle variant='h2' gutterBottom sx={{ overflowWrap: 'anywhere' }}>
            {name}
          </LabelStyle>
          <div>
            <QuillHtmlRenderer html={description} />
          </div>
          <Grid item flexGrow={1}></Grid>
          <Grid item container>
            <Button
              disabled={disabled}
              sx={{ textTransform: 'uppercase', paddingTop: '8px', paddingBottom: '8px' }}
              onClick={onClick}
              size='small'
              endIcon={<EastIcon />}
            >
              {linkTitle}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </CardStyle>
  );
};

