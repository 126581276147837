import EastIcon from '@mui/icons-material/East';
import {
  Button,
  CardContent,
  Chip,
  ClickAwayListener,
  Grid,
  styled,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import {CardStyle} from '../../components';
import {TPath} from '../../redux/api/dashboardsApiSlice';
import {convertSecondsToHMS, numberSignRequired} from '../../shared/convertor';
import {getFontSize} from '../../theme';

const DescriptionStyle = styled(Typography)`
    font-size: ${getFontSize(-4)};
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
`;
const ButtonStyle = styled(Button)`
  text-transform: uppercase;
`;

export type TDashboardCardProps = {
  card: TPath;
  onPrepare: () => void;
  effectDescription: string;
  effectName: string;
  isIneffective: boolean;
}

export default function DashboardCard({
                                        card,
                                        effectName,
                                        effectDescription,
                                        onPrepare,
                                        isIneffective,
                                      }: TDashboardCardProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const themeMode = useTheme().palette.mode;
  const [open, setOpen] = React.useState(false);

  return <CardStyle key={card._id} desktop={`${isDesktop}`}
                    mode={themeMode}>
    <CardContent sx={{display: 'flex', flexGrow: 1}}>
      <Grid container flexDirection='column'>
        <Typography variant='h2' gutterBottom sx={{overflowWrap: 'anywhere'}}>
          {card.name}
        </Typography>
        <DescriptionStyle variant='body1' sx={{overflowWrap: 'anywhere'}}
                          gutterBottom>
          {card.componentNames.join(', ')}
        </DescriptionStyle>
        <Typography variant='body1' gutterBottom sx={{overflowWrap: 'anywhere'}}>
          {convertSecondsToHMS(card.audioLength)} | {card.voiceName}
        </Typography>
        <Grid item flexGrow={1}></Grid>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  sx: {marginTop: '0px!important'},
                }}
                onClose={() => setOpen(false)}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <Grid container direction='column'
                        sx={{
                          background: '#D4F1F9',
                          padding: '16px',
                          borderRadius: '5px',
                          '& .MuiTypography-root': {
                            color: '#46485B',
                          },
                        }}
                  >
                    <Typography variant='body1'>
                      {effectDescription}
                    </Typography>
                  </ Grid>
                }
                placement='bottom'
              >
                <Chip
                  color={isIneffective ? 'warning' : 'info'}
                  label={`${effectName}: ${numberSignRequired(card.result)}`}
                  onClick={() => setOpen(true)}
                />
              </Tooltip>
            </ClickAwayListener>
          </Grid>
          <Grid item>
            <ButtonStyle onClick={onPrepare} size='small'
                         endIcon={<EastIcon/>}>
              Prepare Path
            </ButtonStyle>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </CardStyle>;
}