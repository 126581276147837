import { useState } from 'react';
import * as React from 'react';
import { IOption, IQuestion } from '../../../redux/api/surveysApiSlice';
import { FormGroup, FormControlLabel, Checkbox, TextField, Theme, useMediaQuery } from '@mui/material';
import { TSurveyAnswer } from '../types';

type Props = {
  onChange: (result: string, otherValue?: string) => void;
  item: IQuestion;
  data?: TSurveyAnswer;
};
const getAnswersData = (data: string, item: IQuestion) => {
  const answersArray = data.slice(1);
  const defaultValues =
    item.questionOptions?.reduce<Record<string, boolean>>((acc, current, index) => {
      acc[current.name] = !!+answersArray[index];
      return acc;
    }, {}) ?? {};
  return { defaultValues };
};

export default function CheckboxField({
  data: { result: defaultValue, otherValue: otherAnswer } = { result: '', id: '', otherValue: '' },
  item,
  onChange,
}: Props) {
  const { defaultValues } = getAnswersData(defaultValue, item);
  const [values, setValues] = useState<Record<string, boolean>>(defaultValues ?? {});
  const [otherValue, setOtherValue] = useState(otherAnswer);
  const [isOtherSelected, setIsOtherSelected] = useState(!!otherAnswer);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const getResult = React.useCallback(
    (newValues: Record<string, boolean>, newOtherValue?: string): [string, string | undefined] => {
      let stringValue = item.questionOptions?.map((o) => (newValues[o.name] ? 1 : 0)).join('') ?? '';

      stringValue = `1${stringValue}`;
      
      if (item.other) {
        if (isOtherSelected && newOtherValue) {
          stringValue = `${stringValue}1`;
        } else {
          stringValue = `${stringValue}0`;
        }
      }

      return [stringValue, newOtherValue];
    },
    [item, values, isOtherSelected, otherValue],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newResult = {
      ...values,
      [event.target.name]: event.target.checked,
    };

    setValues(newResult);

    const [stringValue, newOtherValue] = getResult(newResult, otherValue);
    onChange(stringValue, newOtherValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherValue(event.target.value);
    const [stringValue, newOtherValue] = getResult(values, event.target.value);
    onChange(stringValue, newOtherValue);
  };

  return (
    <FormGroup>
      {item.questionOptions?.map((option: IOption) => (
        <FormControlLabel
          key={option.value}
          control={<Checkbox checked={values[option.name]} onChange={(e) => handleChange(e)} name={option.name} />}
          value={option.value}
          label={option.name}
        />
      ))}
      {item?.other && (
        <FormControlLabel
          key="other"
          value="other"
          control={
            <Checkbox
              checked={isOtherSelected}
              onChange={() => {
                setIsOtherSelected(!isOtherSelected);
                const [stringValue, newOtherValue] = getResult(values, !isOtherSelected ? otherValue : '');
                onChange(stringValue, newOtherValue);
                setOtherValue('');
              }}
            />
          }
          label={item.otherName}
          componentsProps={{ typography: { variant: 'body1' } }}
        />
      )}
      {item?.other && isOtherSelected && (
        <TextField
        onChange={handleInputChange} 
        value={otherValue}   
         placeholder={item.placeholder || ''}
        multiline
        sx={{ width: isDesktop ? '50%' : '100%' }}
        inputProps={{ maxLength: item.limit }}
      />
      )}
    </FormGroup>
  );
}

