import {
  DialogActions,
  DialogContent, DialogTitle,
  FormControlLabel, Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import SharedDialog, { TSharedDialogProps } from '../../components/SharedDialog';
import { sortMap } from './Favorites';

type TSortDialogProps = TSharedDialogProps & {
  onSortSelected: (sortBy: 'length' | 'listened' | 'saved' | 'alphabetical') => void;
  current: 'length' | 'listened' | 'saved' | 'alphabetical';
}

export default function SortDialog(props: TSortDialogProps) {
  const {onSortSelected, current, ...rest} = props;
  const {open, onClose} = rest;
  const [currentChoice, setCurrentChoice] = useState(current);

  useEffect(() => {
    setCurrentChoice(current);
  }, [current, open]);

  return <SharedDialog {...rest}>
    <DialogTitle>Sort the list of favorite paths by</DialogTitle>
    <DialogContent>
      <RadioGroup
        value={currentChoice}
        onChange={ev => setCurrentChoice(ev.target.value as 'length' | 'listened' | 'saved' | 'alphabetical')}
      >
        {Object.entries(sortMap).map(([value, label]) => (
          <FormControlLabel value={value} control={<Radio />} label={label} />
        ))}
      </RadioGroup>
    </DialogContent>
    <DialogActions>
      <Grid container justifyContent="space-around" gap='10px'>
        <Button variant="outlined" onClick={(ev) => onClose && onClose(ev, 'escapeKeyDown')}>Cancel</Button>
        <Button variant="contained" onClick={() => onSortSelected(currentChoice)}>Confirm and close</Button>
      </Grid>
    </DialogActions>
  </SharedDialog>
}