import * as React from 'react';
import { Breadcrumbs, Container, ContentWrapper, Title } from '../components';
import {
  Stack,
  useMediaQuery,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function EmailConfirmed() {
  const isDesktop = useMediaQuery('md');
  const navigate = useNavigate();
  return (
    <Container>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src='/logo.png' alt='Logo' height='64px' width='64px' />
      </Box>
      <Title title={'Email confirmed'} />
      <Breadcrumbs firstTitle={'Login'}
                   breadcrumbs={[{ label: 'Email confirmed', route: '' }]} />
      <ContentWrapper
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '32px' }}>
        <Typography variant='h6' color='success'>
          Congratulations!
        </Typography>
        <Typography component='p' variant='body1' sx={{ marginBottom: '24px' }}>
          Your email address is now confirmed. You can now Sign In to Rose.
        </Typography>
        <Stack
          component='div'
          sx={{
            alignItems: 'stretch',
            flexDirection: 'column',
            width: isDesktop ? '351px' : '100%',
          }}
        >
          <Button type='button'
                  variant='contained'
                  onClick={() => navigate('/login/email')}>
            Sign In
          </Button>
        </Stack>
      </ContentWrapper>
    </Container>
  );
}

