import {
  Alert,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CardStyle, Title} from '../../components';
import CardsContainer from '../../components/CardsContainer';
import {
  TDashboard,
  TPath,
  useGetDashboardCardsQuery
} from '../../redux/api/dashboardsApiSlice';
import {createPathUri} from '../../shared/factories';
import DashboardCard from './DashboardCard';
import DashboardChart from './DashboardChart';

export type TDashboardProps = {
  dashboard: TDashboard
}

export default function Dashboard(
  {
    dashboard: {
      _id,
      name,
      showGraph,
      graphName,
      effectDescription,
      effectName,
      min,
      max,
      notFitMessage
    }
  }: TDashboardProps) {
  const {data = []} = useGetDashboardCardsQuery(_id);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isPortrait = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm') && '(orientation: portrait)');
  const [displayGraph, setDisplayGraph] = useState(true);
  useEffect(() => {
    setDisplayGraph(isDesktop);
  }, [isDesktop]);
  const themeMode = useTheme().palette.mode;
  const navigate = useNavigate();
  const onPrepare = (card: TPath) => navigate(createPathUri(
    card.goalId,
    'dashboard',
    card._id,
  ));
  const isNotFitPaths = data.some(path => path.result > max || path.result < min);
  return <>
    <Title title={name}/>
    {isNotFitPaths &&
      <Alert severity='info' sx={{marginBottom: '24px'}}>{notFitMessage}</Alert>}
    <CardsContainer>
      {data.map(card => <DashboardCard
        effectDescription={effectDescription}
        effectName={effectName}
        onPrepare={() => onPrepare(card)}
        card={card}
        isIneffective={card.result < min || card.result > max}
      />)}
      {data.length > 0 && showGraph &&
        <CardStyle
          desktop={`${isDesktop}`}
          mode={themeMode}
          sx={{
            overflow: 'visible', minWidth:
              '100%',
          }}
        >
          <CardContent sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            paddingBottom: displayGraph ? '40px!important' : 0,
          }}>
            <Typography variant='h2' gutterBottom
                        sx={{overflowWrap: 'anywhere', marginBottom: 0}}>
              {graphName}
            </Typography>
            {displayGraph
              ? <>
                {isPortrait && !isDesktop
                  ? <Alert severity='info'>
                    Please rotate your phone for best view of graph
                </Alert>
                  : <Alert severity='info'>
                    Tap or click on the grid to see legend
                  </Alert> }
                <DashboardChart id={_id}/>
              </>
              : <Button
                variant='text'
                onClick={() => setDisplayGraph(true)}
              >
                Display Graph
              </Button>}
          </CardContent>
        </CardStyle>}
      <Grid item flexGrow={1}>
        {data.length < 1 &&
          <Alert severity='info'>Listen at least one path from this topic to see
            results</Alert>}
      </Grid>
    </CardsContainer>
  </>;
}