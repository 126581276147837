import * as React from 'react';
import { Box, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { NavLink } from 'react-router-dom';
export interface IBreadcrumb {
  label: string;
  route: string;
}
interface IProps {
  breadcrumbs: IBreadcrumb[];
  firstTitle?: string;
}
export const Breadcrumbs = ({ breadcrumbs, firstTitle = 'Home' }: IProps) => {
  return (
    <Box sx={{ marginLeft: '16px' }}>
      <MuiBreadcrumbs sx={{ marginBottom: '24px' }} separator={<EastIcon />} aria-label="breadcrumb">
        <NavLink className={'breadcrumb'} to={'/home'}>
          {firstTitle}
        </NavLink>

        {breadcrumbs.map((item, index) => {
          if (index !== breadcrumbs.length - 1) {
            return (
              <NavLink className={'breadcrumb'} key={`${item}-${index}`} to={item.route}>
                {item.label}
              </NavLink>
            );
          } else
            return (
              <Typography key={`${item}-${index}`} className="inActiveBreadcrumb">
                {item.label}
              </Typography>
            );
        })}
      </MuiBreadcrumbs>
    </Box>
  );
};

