import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Container, ContentWrapper } from '../../components';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <ContentWrapper sx={{
        maxWidth: '50vmin',
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignContent: 'center',
        alignItems: 'center'
      }}>
        <Typography variant='h1' sx={{fontSize: '800%'}} color='#46485B'>404</Typography>
        <Typography variant='h3'>Page not found</Typography>
        <Typography variant='body1' textAlign='center'>We are sorry but the page you’re
          looking for doesn't exist. Please go to Home page.</Typography>
        <Button variant='contained' onClick={() => navigate('/')}>Go to Home</Button>
      </ContentWrapper>
    </Container>
  );
};

