import { Button, Card, Typography } from '@mui/material';
import React from 'react';


export default function PlayerWellDone({ onContinue }: { onContinue: () => void }) {
  return (
    <Card
      sx={{
        margin: ' 0 auto',
        maxWidth: '470px',
        padding: '30px 24px',
        borderRadius: '6px',
      }}
    >
      <Typography variant='h2' textAlign={'center'} sx={{ marginBottom: '24px' }}>
        Well Done!
      </Typography>
      <Typography variant='body1' textAlign={'center'}>
        You have completed a path. Take the time you need to absorb the benefits. When you
        are ready, click "Go ahead"
        button."
      </Typography>
      <Button sx={{ width: '100%', marginTop: '24px' }} variant='contained'
              onClick={onContinue}>
        Go ahead
      </Button>
    </Card>
  );
}