import { apiSlice } from './apiSlice';

export type ISession = {
  sessionId: string;
}

export const historyApi = apiSlice.injectEndpoints({
endpoints: (builder) => ({
    generateSessionId: builder.query<ISession, {pathId: string, source: string}>({
      query: (body) => ({
        url: 'client/history',
        method: 'POST',
        body
      }),
      keepUnusedDataFor: 1,
    }),
  }),
});

export const { useGenerateSessionIdQuery } = historyApi;

