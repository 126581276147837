import { z } from 'zod';

export const registerSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(8, { message: 'Password should be at least 8 characters' })
    .regex(/[a-z]/, { message: 'Password should contain at least 1 lowercase character' })
    .regex(/[A-Z]/, { message: 'Password should contain at least 1 uppercase character' })
    .regex(/[0-9]/, { message: 'Password should contain at least 1 number' }),
  retypePassword: z
    .string()
    .min(8, { message: 'Password should be at least 8 characters' })
    .regex(/[a-z]/, { message: 'Password should contain at least 1 lowercase character' })
    .regex(/[A-Z]/, { message: 'Password should contain at least 1 uppercase character' })
    .regex(/[0-9]/, { message: 'Password should contain at least 1 number' }),
}).superRefine(({password, retypePassword}, ctx) => {
  if (password !== retypePassword) {
    ctx.addIssue({
      code: 'custom',
      message: 'Passwords should be equal',
      path: ['retypePassword'],
      fatal: true
    })
  }
});

export type TRegisterData = z.infer<typeof registerSchema>;

export const loginSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(8, { message: 'Too short password to be correct' })
});

export type TLoginData = z.infer<typeof loginSchema>;

export const registerGoogleSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(8, { message: 'Password should be at least 8 characters' })
    .regex(/[a-z]/, { message: 'Password should contain at least 1 lowercase character' })
    .regex(/[A-Z]/, { message: 'Password should contain at least 1 uppercase character' })
    .regex(/[0-9]/, { message: 'Password should contain at least 1 number' }),
  retypePassword: z
    .string()
    .min(8, { message: 'Password should be at least 8 characters' })
    .regex(/[a-z]/, { message: 'Password should contain at least 1 lowercase character' })
    .regex(/[A-Z]/, { message: 'Password should contain at least 1 uppercase character' })
    .regex(/[0-9]/, { message: 'Password should contain at least 1 number' }),
}).superRefine(({password, retypePassword}, ctx) => {
  if (password !== retypePassword) {
    ctx.addIssue({
      code: 'custom',
      message: 'Passwords should be equal',
      path: ['retypePassword'],
      fatal: true
    })
  }
});

export type TRegisterGoogleData = z.infer<typeof registerGoogleSchema>;

export const forgotPasswordSchema = z.object({
  email: z.string().email(),
});

export type TForgotPasswordData = z.infer<typeof forgotPasswordSchema>;


export const resetPasswordSchema = z.object({
  password: z
    .string()
    .min(8, { message: 'Password should be at least 8 characters' })
    .regex(/[a-z]/, { message: 'Password should contain at least 1 lowercase character' })
    .regex(/[A-Z]/, { message: 'Password should contain at least 1 uppercase character' })
    .regex(/[0-9]/, { message: 'Password should contain at least 1 number' }),
  retypePassword: z
    .string()
    .min(8, { message: 'Password should be at least 8 characters' })
    .regex(/[a-z]/, { message: 'Password should contain at least 1 lowercase character' })
    .regex(/[A-Z]/, { message: 'Password should contain at least 1 uppercase character' })
    .regex(/[0-9]/, { message: 'Password should contain at least 1 number' }),
}).superRefine(({password, retypePassword}, ctx) => {
  if (password !== retypePassword) {
    ctx.addIssue({
      code: 'custom',
      message: 'Passwords should be equal',
      path: ['retypePassword'],
      fatal: true
    })
  }
});

export type TResetPasswordData = z.infer<typeof resetPasswordSchema>;

export const changePasswordSchema = z.object({
  currentPassword: z
    .string()
    .min(8, { message: 'This password could not be right'}),
  password: z
    .string()
    .min(8, { message: 'Password should be at least 8 characters' })
    .regex(/[a-z]/, { message: 'Password should contain at least 1 lowercase character' })
    .regex(/[A-Z]/, { message: 'Password should contain at least 1 uppercase character' })
    .regex(/[0-9]/, { message: 'Password should contain at least 1 number' }),
  retypePassword: z
    .string()
    .min(8, { message: 'Password should be at least 8 characters' })
    .regex(/[a-z]/, { message: 'Password should contain at least 1 lowercase character' })
    .regex(/[A-Z]/, { message: 'Password should contain at least 1 uppercase character' })
    .regex(/[0-9]/, { message: 'Password should contain at least 1 number' }),
}).superRefine(({password, retypePassword}, ctx) => {
  if (password !== retypePassword) {
    ctx.addIssue({
      code: 'custom',
      message: 'New password and Retype new password should be equal',
      path: ['retypePassword'],
      fatal: true
    })
  }
});

export type TChangePasswordData = z.infer<typeof changePasswordSchema>;