import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import AdaptiveSelect from '../../../components/AdaptiveSelect';
import { IOption, IQuestion } from '../../../redux/api/surveysApiSlice';
import { TSurveyAnswer } from '../types';

interface Props {
  item: IQuestion;
  onChange: (result: string) => void;
  data?: TSurveyAnswer;
}

export default function GradeField({
  data: { result: defaultValue } = { result: '', id: '' },
  item,
  onChange,
}: Props) {
  const [value, setValue] = useState(defaultValue);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return !isDesktop ? (
    <AdaptiveSelect
      value={value}
      data={item.questionOptions?.map(opt => ({
        _id: opt.value?.toString() || '',
        name: opt.name,
        helperText: opt.description,
        parent: null
      })) || []}
      onChange={val => handleChange(val)}
      placeholder={item.placeholder || ''}
    />
  ) : (
    <FormControl sx={{ width: '100%' }}>
      <RadioGroup
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        value={value}
        onChange={(ev) => handleChange(ev.target.value)}
      >
        {item.questionOptions?.map((option: IOption) => (
          <FormControlLabel
            key={option.value}
            value={option.value?.toString()}
            control={<Radio />}
            label={
              <>
                {option.name}
                <Typography
                  fontSize='12px'
                  component='div'
                  sx={{ maxWidth: '100%' }}
                  className='gradeDescription'
                >
                  {option.description}
                </Typography>
              </>
            }
            labelPlacement='bottom'
            componentsProps={{ typography: { variant: 'body1' } }}
            sx={{
              marginLeft: 0,
              marginRight: 0,
              textAlign: 'center',
              flexBasis: `${100 / (item.questionOptions?.length || 1)}%`,
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}