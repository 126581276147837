import { Grid, Pagination as MuiPagination, Theme, useMediaQuery } from '@mui/material';

export type TPaginationProps = {
  itemsCount: number;
  itemsPerPage: number;
  page?: number;
  onPageChange?: (page: number) => void;
};

export default function Pagination(props: TPaginationProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const pageCount = Math.ceil(props.itemsCount / props.itemsPerPage);
  return (
    <Grid container justifyContent="center" marginTop="24px">
      <MuiPagination
        siblingCount={isDesktop ? 2 : 0}
        count={pageCount}
        page={props.page || 1}
        color="primary"
        onChange={(ev, value) => props.onPageChange && props.onPageChange(value)}
        shape="rounded"
      />
    </Grid>
  );
}

