import { apiSlice } from './apiSlice';

export type TUserType = 'beta' | 'production';
export type TTheme = 'light' | 'dark';
export type TAuthHelperFields =
  'facebookUserId'
  | 'googleUserId'
  | 'twitterUserId'
  | 'instagramUserId';
export type TAuthHelpers = Partial<Record<TAuthHelperFields, string>>;
type TPaidTier = 'free' | 'paid' | 'unpaid';

export type TSettings = {
  theme: TTheme;
  voice: string;
  timeLimit: number;
  onboarding: {
    consent?: boolean;
    demographicSurvey?: boolean;
    introductionCompleted?: boolean;
    welcomeTrip?: boolean;
    firstPathListened?: boolean;
  };
  configuration: {
    'favorites-pages': number;
    'intro-interval': number;
    'rewind-time': number;
    'free-tier-favorites-count': number;
    'dashboard-chart-pre-point-title': string;
    'dashboard-chart-post-point-title': string;
  };
  paidTier?: TPaidTier;
  paidDueDate?: string;
  freeTierEndDate?: string;
  activeSubscription: boolean;
  type: TUserType;
  helpers?: TAuthHelpers;
};
const HOUR_SECONDS = 60 * 60;

export const settingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadSettings: builder.query<TSettings, void>({
      query: () => ({
        url: 'settings',
        method: 'GET',
      }),
      keepUnusedDataFor: HOUR_SECONDS,
      providesTags: ['Settings'],
    }),
    getSettings: builder.mutation<TSettings, void>({
      query: () => ({
        url: 'settings',
        method: 'GET',
      }),
    }),
    saveSettings: builder.mutation<TSettings, { theme: TTheme; voice: string }>({
      query: (data) => ({
        url: 'settings',
        method: 'PUT',
        body: data,
      }),
      transformResponse(response) {
        window.localStorage.setItem('settings', JSON.stringify(response))
        return response as TSettings
      },
      invalidatesTags:['Settings'],
    }),
    saveSettingsSilent: builder.mutation<TSettings, { theme: TTheme; voice: string }>({
      query: (data) => ({
        url: 'settings',
        method: 'PUT',
        body: data,
      }),
      transformResponse(response) {
        window.localStorage.setItem('settings', JSON.stringify(response))
        return response as TSettings
      },
    }),
    updateOnboarding: builder.mutation<
      TSettings,
      {
        consent?: boolean;
        demographicSurvey?: boolean;
        introductionCompleted?: boolean;
        welcomeTrip?: boolean;
        firstPathListened?: boolean;
      }
    >({
      query: (data) => ({
        url: 'settings/onboarding',
        method: 'PUT',
        body: data,
      }),
      transformResponse(response) {
        window.localStorage.setItem('settings', JSON.stringify(response))
        return response as TSettings
      },
      invalidatesTags:['Settings'],
    }),
    updateOnboardingSilent: builder.mutation<
      TSettings,
      {
        consent?: boolean;
        demographicSurvey?: boolean;
        introductionCompleted?: boolean;
        welcomeTrip?: boolean;
        firstPathListened?: boolean;
      }
    >({
      query: (data) => ({
        url: 'settings/onboarding',
        method: 'PUT',
        body: data,
      }),
      transformResponse(response) {
        window.localStorage.setItem('settings', JSON.stringify(response))
        return response as TSettings
      },
    }),
  }),
});

export const {
  useLoadSettingsQuery,
  useGetSettingsMutation,
  useSaveSettingsMutation,
  useSaveSettingsSilentMutation,
  useUpdateOnboardingMutation,
  useUpdateOnboardingSilentMutation,
} = settingsApi;

