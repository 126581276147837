import { useState } from 'react';
import * as React from 'react';
import {
  Grid,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useLoadSettingsQuery } from '../../redux/api/settingsApiSlice';
import { convertSecondsToHMS } from '../../shared/convertor';
import { ReactComponent as CloseIcon } from '../../svg/close-circle.svg';
import { ReactComponent as ClockIcon } from '../../svg/clock.svg';

export type TTimerProps = {
  playbackTime: number;
}

export const PlayerTimer = ({ playbackTime }: TTimerProps) => {
  const [displayed, setDisplayed] = useState(false);
  const { data: settings } = useLoadSettingsQuery();
  const currentTimer = Math.max(settings?.timeLimit! - playbackTime, 0);
  return displayed ? (<Stack className='PlayerTimer' direction='row' alignItems='center'>
    <Grid item container direction='column'>
      <Typography align='center'
                  variant='body1'>{convertSecondsToHMS(currentTimer, true)}</Typography>
      <Typography noWrap>remaining time today</Typography>
    </Grid>
    <SvgIcon
      component={CloseIcon}
      inheritViewBox
      sx={{
        marginLeft: '20px',
        height: '36px',
        width: '36px',
        cursor: 'pointer',
      }}
      onClick={() => setDisplayed(false)}
    />
  </Stack>) : (<SvgIcon
    component={ClockIcon}
    inheritViewBox
    sx={{
      height: '36px',
      width: '36px',
      cursor: 'pointer',
    }}
    onClick={() => setDisplayed(true)}
  />);
};

