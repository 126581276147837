import {Alert, LinearProgress, Stack, TextField, Theme, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Breadcrumbs, Container, ContentWrapper, Title} from '../components';
import {
  clearToken,
  getHelperToken,
  getHelperType,
  login,
} from '../redux/actions/userActions';
import {
  useEmailLoginMutation,
  useGoogleRegisterMutation
} from '../redux/api/authApiSlice';
import {useAppSelector} from '../redux/hooks';
import {extractError} from '../shared/extractors';
import {useValidate} from '../shared/useValidate';
import {loginSchema, TLoginData} from './schemas';
import { useGetSettingsMutation } from '../redux/api/settingsApiSlice';
import { useGetSurveysMutation, useLoadHooksMutation } from '../redux/api/surveysApiSlice';
import useLocalStorageState from 'use-local-storage-state';

export default function LoginEmail() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [isLoading, setIsLoading] = useState(false);

  const [getSettings] = useGetSettingsMutation();
  const [loadHooks] = useLoadHooksMutation();
  const [getSurvey] = useGetSurveysMutation();
  
  const [, setLocalSettings] = useLocalStorageState('settings');
  const [, setLocalHooks] = useLocalStorageState('hooks');
  const [, setLocalSurveys] = useLocalStorageState('surveys');

  const [data, setData] = useState<TLoginData>({
    email: '',
    password: '',
  });

  const {
    validate,
    getErrorProps,
    clearOneError,
  } = useValidate({scheme: loginSchema});

  function updateData<Key extends keyof TLoginData>(
    field: Key,
    value: TLoginData[Key],
  ) {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    clearOneError(field);
    setServerError('');
  }

  const [serverError, setServerError] = useState<string | undefined>();
  const [logIn] = useEmailLoginMutation();
  const [googleRegister] = useGoogleRegisterMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useAppSelector(getHelperToken);
  const helperType = useAppSelector(getHelperType);

  const handleSubmit = async () => {
    if (!validate(data)) {
      return;
    }
    setIsLoading(true);
    try {
      const user = token
        ? await googleRegister({
          email: data.email,
          password: data.password,
          type: 'email',
          accessToken: token,
          helperType: helperType!,
        }).unwrap()
        : await logIn({
          email: data.email,
          password: data.password,
        }).unwrap();
      dispatch(login(user));
      dispatch(
        clearToken(),
      );
      setServerError('');

      const settings = await getSettings().unwrap();
      const hooks = await loadHooks().unwrap();

      if (hooks?.length) {
        const idsSurveys = hooks?.reduce((acc, hook) => {
        if (hook.endSurveyId) {
          acc.push(hook.startSurveyId, hook.endSurveyId);
        }
        else {
          acc.push(hook.startSurveyId);
        }
        return acc;
        }, [] as string[]);

        if (idsSurveys) {
          const surveys = await getSurvey({
            ids: idsSurveys,
          })
            .unwrap();
            setLocalSurveys(surveys);
        }
      }
      setLocalSettings(settings);
      setLocalHooks(hooks);

      navigate('/home');
    } catch (e) {
      setServerError(extractError(e));
      setIsLoading(false);
    }
  };

  const canLogin = data.email !== '' && data.password !== '';

  return (
    <Container>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src='/logo.png' alt='Logo' height={isDesktop ? '64px' : '64px'}
             width={isDesktop ? '64px' : '64px'}/>
      </Box>
      <Title title='Sign In by Email' backButton/>
      <Breadcrumbs firstTitle={'Login'}
                   breadcrumbs={[{label: 'Sign In by Email', route: ''}]}/>
      <ContentWrapper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position:'relative',
          gap: '24px',
        }}>
        <Typography component='p' variant='body1' align='center'>
          Enter your Email Address and Password to Sign In to Rose. Use the "Forgot
          password" option if you don't remember your password.
        </Typography>
        <Stack
          component='div'
          sx={{
            alignItems: 'stretch',
            flexDirection: 'column',
            width: isDesktop ? '351px' : '100%',
            gap: '19px 10px',
          }}
        >
          <TextField
            label='Email'
            {...getErrorProps('email')}
            required
            autoFocus
            type='email'
            value={data.email}
            onChange={ev => updateData('email', ev.target.value)}
            name='code'
            placeholder='Email'
          />
          <TextField
            required
            {...getErrorProps('password')}
            value={data.password}
            onChange={ev => updateData('password', ev.target.value)}
            name='code'
            label='Password'
            type='password'
          />
          {serverError && <Alert severity='error'>{serverError}</Alert>}
          <Button
            type='button'
            disabled={!canLogin || isLoading}
            variant='contained'
            onClick={handleSubmit}
          >
            Sign In
        {isLoading &&
        <Box sx={{ width: '100%', position: 'absolute', bottom: '0', }}>
          <LinearProgress />
        </Box>
        }
        </Button>
          <Typography
            component='p'
            variant='body1'
            sx={{margin: '10px auto 0px auto', textAlign: 'center'}}
          >
            <Button
              type='button'
              variant='text'
              disabled={isLoading}
              onClick={() => navigate('/forgot-password/request-forgot-password')}
            >
              Forgot password
            </Button>
          </Typography>
        </Stack>
      </ContentWrapper>
    </Container>
  );
}

