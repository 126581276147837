import {
  DialogActions,
  DialogContent, DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import {  useState } from 'react';
import SharedDialog from '../../components/SharedDialog';

interface IProps{
  onSave: (name:string,isRedirect:boolean ) => void;
  onClose: (ev?:any, action?: string ) => void;
  defaultName:string;
  open:boolean;
}

export default function SaveToFavoritesDialog({onSave, onClose,open, defaultName, ...rest}:IProps) {
  const [name, setName] = useState(defaultName);

  return <SharedDialog open={open} {...rest}>
    <DialogTitle sx={{padding:0}}>Save to Favorites</DialogTitle>
    <DialogContent sx={{padding:'20px 0 !important'}}>
    <TextField
      fullWidth
      label="Path name (optional)"
      variant="outlined"
      value={name}
      onChange={(ev:any) => setName(ev.target.value)}
            />
  </DialogContent>
    <DialogActions sx={{padding:0}}>
      <Grid container justifyContent="space-around" gap='10px'>
        <Button variant="outlined" onClick={(ev) => onClose && onClose(ev, 'escapeKeyDown')}>Cancel</Button>
        <Button variant="contained" onClick={() => {
          onSave(name, true);
          onClose()
        }}>Save and Close</Button>
        <Button variant="contained" onClick={() => {
          onSave(name, false);
          onClose()
        }}>Save and Start</Button>
      </Grid>
    </DialogActions>
  </SharedDialog>
}