import {
  Grid,
  Step,
  StepConnector,
  stepConnectorClasses,
  Stepper as MUIStepper,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.mode === 'dark' ? '#9E768F' : '#416873',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: theme.palette.mode === 'dark' ? '#9E768F' : '#416873',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#DFDFE5',
    borderRadius: 1,
  },
}));

export type TStepperBodyProps = {
  stepsCount: number;
  activeStep: number;
};

export default function StepperBody({ activeStep, stepsCount }: TStepperBodyProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const themeMode = useTheme().palette.mode;

  return (
    <Grid
      item
      sx={!isDesktop ? { width: '100%' } : undefined}
      container
      alignItems="center"
      justifyContent="stretch"
    >
      <MUIStepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{
          flexGrow: 1,
          gap: '5px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ color: themeMode === 'dark' ? '#9E768F' : '#416873',
          }}
        >
          {`${activeStep}/${stepsCount}`}
        </Typography>
        {Array.from({ length: stepsCount }, (label, index) => (
          <Step
            key={index}
            sx={{
              margin: 'auto',
              paddingRight: '5px',
              paddingLeft: 0,
              '& .MuiStepConnector-root': {
                position: 'static',
              },
            }}
          />
        ))}
      </MUIStepper>
    </Grid>
  );
}

