import { Grid, Radio, Typography } from '@mui/material';
import { Component } from '../../redux/api/componentsApiSlice';
import { convertSecondsToHMS } from '../../shared/convertor';

export type SingleComponentProps = {
  component: Component;
  selected: boolean;
  onSelect: () => void;
}

export default function SingleComponent({
  component,
  selected,
  onSelect,
}: SingleComponentProps) {
  return <Grid key={component._id} item container gap='8px' alignItems='center'
               direction='row' flexWrap='nowrap'>
    <Grid item>
      <Radio checked={selected}
             onChange={() => onSelect()} />
    </Grid>
    <Grid item flexDirection='column' alignItems='flex-start'>
      <Typography variant='body1' sx={{overflowWrap: 'anywhere'}}>
        {component.name} ({convertSecondsToHMS(component.audioLength, false)})
      </Typography>
      <Typography variant='body1' sx={{ fontSize: '80%' }}>
        {component.goals.map(goal => goal.name).join(', ')}
      </Typography>
    </Grid>
  </Grid>;
}