import { Navigate } from 'react-router-dom';
import { TSettings, useLoadSettingsQuery } from '../redux/api/settingsApiSlice';
import { ISurveyMount, useGetHooksQuery } from '../redux/api/surveysApiSlice';
import { useAppSelector } from '../redux/hooks';
import { CONST_TOKEN_COOKIE, login } from '../redux/actions/userActions';
import { useDispatch } from 'react-redux';
import React, { ComponentProps, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { ThemeContext } from './ThemeContext';
import SurveysChain from '../features/Surveys/SurveysChain';
import useLocalStorageState from 'use-local-storage-state';

const ProtectedRoute = ({ children }: ComponentProps<any>) => {
  const user = useAppSelector((state) => state.user);
  const [localSettings] = useLocalStorageState<TSettings>('settings');
  const [localHooks = []] = useLocalStorageState<ISurveyMount[]>('hooks');
  const {data: settings = localSettings} = useLoadSettingsQuery(undefined, {
    skip: !user.authenticated
  });

  const {data: hooks = localHooks} = useGetHooksQuery(undefined, {
    skip: !user.authenticated
  });
  const token = Cookies.get(CONST_TOKEN_COOKIE);
  const dispatch = useDispatch();
  const themeCtx = React.useContext(ThemeContext);

  useEffect(() => {
    if (!settings) {
      themeCtx.selectMode('light');
      return;
    }
    themeCtx.selectMode(settings.theme);
  }, [settings?.theme]);

  useMemo(() => {
    if (!user.authenticated && token) {
      dispatch(login({ accessToken: token }));
    }
  }, [token, dispatch, user.authenticated]);

  if (user.authenticated) {
    if (!settings?.onboarding?.demographicSurvey && hooks.some(hook => hook.startHook === 'First login')) {
      return <SurveysChain hooks={hooks} startState="First login" />;
    }
    return children;
  }
  return <Navigate to="/" replace />;
};
export default ProtectedRoute;

