import {Button, ButtonProps, useTheme} from "@mui/material";
import styled from '@emotion/styled';

export const PulsatingButtonStyle = styled<any>(Button)((props: any) => ({
  textTransform: 'uppercase',
  background: props.mode === 'light' ? ' #f2f2f2' : '#46485B',
  '&.pulsating': {
    animation: 'pulse 1s 1.5',
  },
  '@keyframes pulse': {
    '0%': {
      background: props.mode === 'light' ? 'rgba(65, 104, 115, 0)' : 'rgba(158, 118, 143, 0)',
    },
    '25%': {
      background: props.mode === 'light' ? 'rgba(65, 104, 115, 0.3)' : 'rgba(158, 118, 143, 0.3)',
    },
    '50%': {
      background: props.mode === 'light' ? 'rgba(65, 104, 115, 0)' : 'rgba(158, 118, 143, 0)',
    },
    '75%': {
      background: props.mode === 'light' ? 'rgba(65, 104, 115, 0.3)' : 'rgba(158, 118, 143, 0.3)',
    },
    '100%': {
      background: props.mode === 'light' ? 'rgba(65, 104, 115, 0)' : 'rgba(158, 118, 143, 0)',
    }
  },
  ...props.sx,
}));

export type TPulsatingButtonProps = ButtonProps & {
  isPulsating: boolean;
}

export default function PulsatingButton (props: TPulsatingButtonProps) {
  const themeMode = useTheme().palette.mode;
  let className = props.className;
  if (props.isPulsating && !props.disabled) {
    className = className ? className + ' pulsating' : 'pulsating';
  }
  return <PulsatingButtonStyle mode={themeMode} className={className} {...props} />;
}
