import Cookies from 'js-cookie';
import { io, Socket } from 'socket.io-client';
import { CONST_TOKEN_COOKIE } from '../redux/actions/userActions';

let socket: Socket;

export const getSocket = () => {
  if(socket) {
    return socket;
  }
  initiateSocket();
  return socket;
}

export const initiateSocket = () => {
  const token = Cookies.get(CONST_TOKEN_COOKIE);

  socket = io(process.env.REACT_APP_SOCKET_URL!, {
    query: {
      accessToken: `Bearer ${token}`,
    },
    transports: ['websocket', 'polling'],
    reconnection: true,
    reconnectionAttempts: 1000,
    reconnectionDelay: 1000,
    autoConnect: false
  });
}