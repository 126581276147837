import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, IconButton, Popover, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export default function FavoriteLockedIcon() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'warning' : undefined;

  return <>
    <IconButton
      aria-label='warning'
      aria-describedby={id}
      color='primary'
      onClick={handleClick}
    >
      <WarningAmberIcon />
    </IconButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Typography variant='body1' sx={{ p: 2, maxWidth: '500px' }}>
        One of the path components has been disabled in the system. You can
        build a new path with the same components using <Button
        variant='text' onClick={() => navigate('/build-your-own')}>
        Build Your Own Path
      </Button>.
      </Typography>
    </Popover>
  </>;
}