import { Alert, Stack, TextField, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { Breadcrumbs, Container, ContentWrapper, Title } from '../components';
import { useEmailRegisterMutation } from '../redux/api/userSlice';
import { extractError } from '../shared/extractors';
import { useValidate } from '../shared/useValidate';
import { registerSchema, TRegisterData } from './schemas';

export default function RegisterEmail() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [register] = useEmailRegisterMutation();

  const [data, setData] = useState<TRegisterData>({
    email: '',
    password: '',
    retypePassword: '',
  });
  const [serverError, setServerError] = useState<string | undefined>();
  const [serverMessage, setServerMessage] = useState<string | undefined>();

  const {
    validate,
    clearOneError,
    getErrorProps,
  } = useValidate({ scheme: registerSchema });

  function updateData<Key extends keyof TRegisterData>(
    field: Key,
    value: TRegisterData[Key],
  ) {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    clearOneError(field);
    setServerError('');
  }

  const handleSubmit = async () => {
    if (!validate(data)) {
      return;
    }
    try {
      const message = await register({
        email: data.email,
        password: data.password,
      }).unwrap();
      setServerMessage(message.message);
    } catch (e) {
      setServerError(extractError(e));
    }
  };

  const canLogin = data.email !== '' && data.password !== '' && data.retypePassword !== '';

  return (
    <Container>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src='/logo.png' alt='Logo' height={isDesktop ? '64px' : '64px'}
             width={isDesktop ? '64px' : '64px'} />
      </Box>
      <Title title='Sign Up' backButton />
      <Breadcrumbs firstTitle={'Login'} breadcrumbs={[{ label: 'Sign Up', route: '' }]} />
      <ContentWrapper
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '19px' }}>
        {!serverMessage ? <>
            <Typography component='p' variant='body1'>
              Please, provide a valid Email Address and Password to Create an Account for Rose.
            </Typography>
            <Stack
              component='div'
              sx={{
                alignItems: 'stretch',
                flexDirection: 'column',
                width: isDesktop ? '351px' : '100%',
                gap: '16px',
              }}
            >
              <TextField
                label='Email'
                required
                autoFocus
                value={data.email}
                onChange={ev => updateData('email', ev.target.value)}
                name='code'
                type='email'
                {...getErrorProps('email')}
              />
              <TextField
                label='Password'
                required
                value={data.password}
                onChange={ev => updateData('password', ev.target.value)}
                name='code'
                placeholder='Password'
                type='password'
                {...getErrorProps('password')}
              />
              <TextField
                label='Retype password'
                required
                value={data.retypePassword}
                onChange={ev => updateData('retypePassword', ev.target.value)}
                name='code'
                type='password'
                placeholder='Repeat password'
                {...getErrorProps('retypePassword')}
              />
              {serverError && <Alert severity='error'>{serverError}</Alert>}
              <Button type='button'
                      disabled={!canLogin} variant='contained'
                      onClick={handleSubmit}>
                Create an Account
              </Button>
            </Stack>
          </>
          : <>
            <Alert severity='info'>{serverMessage}</Alert>
            <Button
              href={`https://${data.email.split('@')[1]}`}
              variant='contained'
            >
              Check email
            </Button>
          </>
        }
      </ContentWrapper>
    </Container>
  )
    ;
}

