import styled from '@emotion/styled';
import { East as EastIcon, Edit } from '@mui/icons-material';
import {
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CardStyle } from '../../components';
import { convertSecondsToHMS } from '../../shared/convertor';
import { getFontSize } from '../../theme';
import FavoriteLockedIcon from './FavoriteLockedIcon';
import SelectForFreeTierButton from './SelectForFreeTierButton';
import {useEffect, useState} from 'react';
import PulsatingButton from "../../components/PulsatingButton";

const DescriptionStyle = styled(Typography)`
  font-size: ${getFontSize(-4)};
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
`;

interface IProps {
  id: string;
  name: string;
  description: string;
  voice: string;
  hasInactiveComponent: boolean;
  length: number;
  date?: string;
  onPrepare: () => void;
  onRemove: () => void;
  selectedForFreeTier: boolean;
  isFreeTierSelectionNeeded: boolean;
  isFreeTierLimit: boolean;
  onEdit: () => void;
}

export const FavoriteCard = ({
  id,
  name,
  description,
  voice,
  hasInactiveComponent,
  length,
  date,
  onPrepare,
  onRemove,
  selectedForFreeTier,
  isFreeTierSelectionNeeded,
  isFreeTierLimit,
  onEdit,
}: IProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const themeMode = useTheme().palette.mode;
  const [isPulsating, setIsPulsating] = useState(false);

  const handleClick = () => {
    setIsPulsating(true);
  };

  useEffect(() => {
    const handleAnimationEnd = () =>  setIsPulsating(false);
    addEventListener("animationend", handleAnimationEnd);
    return () => removeEventListener("animationend", handleAnimationEnd);

  },[]);
  const renderButton = () => {
    if (hasInactiveComponent) {
      return <FavoriteLockedIcon />;
    }
    if (!selectedForFreeTier && isFreeTierLimit && isFreeTierSelectionNeeded) {
      return <SelectForFreeTierButton favoriteId={id} isPulsating={isPulsating}/>;
    }
    return <PulsatingButton
      onClick={(e) => {
        e.stopPropagation();
        onPrepare()
      }}
      size='small'
      disabled={isFreeTierLimit && !selectedForFreeTier}
      endIcon={<EastIcon />}
      isPulsating={isPulsating}
    >
      Prepare Path
    </PulsatingButton>;
  };

  return (
    <CardStyle desktop={`${isDesktop}`} mode={themeMode} onClick={handleClick} sx={{cursor: 'default'}}>
      <CardContent sx={{ display: 'flex', flexGrow: 1 }}>
        <Grid container flexDirection='column'>
          <Grid item container alignItems='center' justifyContent='space-between'>
            <Typography variant='h2' gutterBottom sx={{ overflowWrap: 'anywhere' }}>
              {name}
            </Typography>
            <Grid item />
            <PulsatingButton
              sx={{ marginBottom: '0.35em', padding: 0, minWidth: '2em' }}
              size='small'
              onClick={(e) => {
                e.stopPropagation();
                onEdit()
              }}
              isPulsating={isPulsating}
            >
              <Edit fontSize="small" />
            </PulsatingButton>
          </Grid>
          <DescriptionStyle variant='body1' sx={{ overflowWrap: 'anywhere' }}
                            gutterBottom>
            {description}
          </DescriptionStyle>
          <Typography variant='body1' gutterBottom sx={{ overflowWrap: 'anywhere' }}>
            {convertSecondsToHMS(length)} | {voice} {date && `(${date})`}
          </Typography>
          <Grid item flexGrow={1}></Grid>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <PulsatingButton
                onClick={(e)=> {
                  e.stopPropagation();
                  onRemove()
                }}
                size='small'
                isPulsating={isPulsating}
              >
                Remove
              </PulsatingButton>
            </Grid>
            <Grid item>
              {renderButton()}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </CardStyle>
  );
};

