import { Box, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TCustomTheme } from '../theme';

interface IProps {
  children: React.ReactNode;
  sx?: SxProps;
}
export const ContentWrapper = ({ children, sx }: IProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const theme: TCustomTheme = useTheme();

  return (
    <Box
      sx={{
        margin: '0 auto',
        width: '100%',
        maxWidth: '794px',
        flexGrow: 1,
        background: theme.palette.contentBackground.main,
        padding: isDesktop ? '36px' : '16px',
        borderRadius: '5px',
        marginBottom: '32px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

