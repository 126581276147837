import { apiSlice } from './apiSlice';

export interface Voice {
  _id: string;
  name: string;
  introFile: string;
  backFile: string;
  audioLengthBack: number;
  audioLengthIntro: number;
}

export const voicesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVoices: builder.query<Voice[], void>({
      query: () => ({
        url: 'client/voices',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetVoicesQuery } = voicesApi;

