import { z } from 'zod';
import { apiSlice } from './apiSlice';

const registerSchema = z.object({
  message: z.string(),
});

export type DtoEmailRegister = {
  email?: string;
  password?: string;
};

export type DtoChangePassword = {
  currentPassword: string;
  newPassword: string;
}

export type TSuccessMessage = {
  message: string;
}

export const clientUserApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    emailRegister: builder.mutation<TSuccessMessage, DtoEmailRegister>({
      query: (registerData) => ({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        url: 'client-auth/register',
        method: 'POST',
        body: registerData,
      }),
      transformResponse(response) {
        return registerSchema.parse(response);
      },
    }),
    requestForgotPassword: builder.mutation<TSuccessMessage, { email: string }>({
      query: (forgetPasswordDto) => ({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        url: 'client-auth/forget-password',
        method: 'POST',
        body: forgetPasswordDto,
      }),
      transformResponse(response) {
        return registerSchema.parse(response);
      },
    }),
    confirmForgotPassword: builder.mutation<TSuccessMessage, {
      code: string,
      password: string
    }>({
      query: (forgetPasswordDto) => ({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        url: 'client-auth/confirm-forget-password',
        method: 'POST',
        body: forgetPasswordDto,
      }),
      transformResponse(response) {
        return registerSchema.parse(response);
      },
    }),
    confirmInvite: builder.mutation<TSuccessMessage, {
      code: string,
      password: string
    }>({
      query: (inviteDto) => ({
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        url: 'client-auth/invite',
        method: 'POST',
        body: inviteDto,
      }),
      transformResponse(response) {
        return registerSchema.parse(response);
      },
    })
  }),
});

export const {
  useEmailRegisterMutation,
  useConfirmForgotPasswordMutation,
  useRequestForgotPasswordMutation,
  useConfirmInviteMutation,
} = clientUserApi;
