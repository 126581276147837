import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import SharedDialog, { TSharedDialogProps } from '../../components/SharedDialog';
import {
  Favorite,
  useGetFeasibleVoicesQuery,
  useUpdateFavoriteMutation,
} from '../../redux/api/favoritesApiSlice';
import { useGetVoicesQuery } from '../../redux/api/voicesApiSlice';

type TSortDialogProps = TSharedDialogProps & {
  favorite?: Favorite;
}

type TFavoriteData = {
  name: string;
  voice: string;
}

export default function EditDialog(props: TSortDialogProps) {
  const { favorite, ...rest } = props;
  const [data, setData] = useState<TFavoriteData>({
    name: favorite?.name || '',
    voice: favorite?.voiceId || '',
  });
  const { data: voices = [] } = useGetVoicesQuery();
  const { data: feasibleVoices = [] } = useGetFeasibleVoicesQuery(favorite?._id!, {
    skip: !favorite,
  });
  const [updateFavorite] = useUpdateFavoriteMutation();

  useEffect(() => {
    setData({
      name: favorite?.name || '',
      voice: favorite?.voiceId || '',
    });
  }, [favorite]);

  if (!favorite) {
    return null;
  }
  const { onClose } = rest;

  function updateData<Key extends keyof TFavoriteData>(
    field: Key,
    value: TFavoriteData[Key],
  ) {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }

  async function handleEdit(ev: React.MouseEvent<HTMLButtonElement>) {
    if (!favorite) {
      return;
    }
    await updateFavorite({
      id: favorite._id,
      ...data,
    });
    if (onClose) {
      onClose(ev, 'escapeKeyDown');
    }
  }

  const availableVoices = voices.filter(v => feasibleVoices.includes(v._id));

  return <SharedDialog {...rest}>
    <DialogTitle>Edit Favorite Path</DialogTitle>
    <DialogContent sx={{ paddingTop: '1em!important' }}>
      <Grid container flexDirection='column' gap='16px'>
        <TextField
          inputProps={{ maxLength: 30 }}
          label='Name'
          error={data.name.length < 1}
          helperText={data.name.length < 1 ? 'Name field should be filled' : null}
          value={data.name}
          onChange={ev => updateData('name', ev.target.value)}
        />
        {availableVoices.length > 0 ? <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Voice</InputLabel>
          <Select label='Voice' value={data.voice}
                  onChange={ev => updateData('voice', ev.target.value)}>
            {availableVoices.map(voice => <MenuItem key={voice._id} value={voice._id}>
              {voice.name}
            </MenuItem>)}
          </Select>
        </FormControl> : null}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Grid container justifyContent='space-around' gap='8px'>
        <Button
          variant='outlined'
          onClick={(ev) => onClose && onClose(ev, 'escapeKeyDown')}
        >
          Cancel
        </Button>
        <Button
          disabled={data.name.length < 1}
          variant='contained'
          onClick={(ev) => handleEdit(ev)}
        >
          Save Path
        </Button>
      </Grid>
    </DialogActions>
  </SharedDialog>;
}