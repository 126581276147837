import { Container, ContentWrapper, Title } from '../../components';
import QuillHtmlRenderer from '../../components/QuillHtmlRenderer';
import { useGetPageByIdQuery } from '../../redux/api/pagesApiSlice';
import { useNavigate } from 'react-router-dom';

  
  export  const PrivacyPolicy = () => {  
    const navigate = useNavigate();

    const { data: privacyPolicy } = useGetPageByIdQuery('654da20f6e675e2f37ba0e5e');

    const handleBack = () => {
      navigate(-1);
    };

    return (
      <Container>
        <Title title={privacyPolicy?.title || ''} backButton onBack={() => handleBack()}/>
        <ContentWrapper>
        <QuillHtmlRenderer html={privacyPolicy?.text || ''} />
        </ContentWrapper>
      </Container>
    );
  }
  
  