import { TAuthMethod } from '../../pages/SettingsPage/AuthMethodTile';
import { apiSlice } from './apiSlice';

export type DtoChangePassword = {
  currentPassword: string;
  newPassword: string;
};

export type TSuccessMessage = {
  message: string;
};

export const authManagementApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changePassword: builder.mutation<TSuccessMessage, DtoChangePassword>({
      query: (dto) => ({
        url: 'client-auth/change-password',
        method: 'POST',
        body: dto,
      }),
    }),
    disconnectHelper: builder.mutation<TSuccessMessage, TAuthMethod>({
      query: (method) => ({
        url: `client-auth/disconnect/${method}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags:['Settings']
    }),
  }),
});

export const { useChangePasswordMutation, useDisconnectHelperMutation } = authManagementApi;

