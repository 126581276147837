import { Alert, Grid, Stack, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Container, ContentWrapper, Title } from '../components';
import {
  getHelperToken,
  login,
  saveHelperToken,
  TAuthHelperType,
} from '../redux/actions/userActions';
import { AuthModes, useGetAuthConfigQuery } from '../redux/api/configurationSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { extractError } from '../shared/extractors';
import { useEffectOnce } from '../shared/useEffectOnce';
import useHelperLogin from '../shared/useHelperLogin';

type TLoginMiddlewareParams = {
  token?: string;
  helper?: TAuthHelperType;
}

export default function LoginMiddleware() {
  const { token, helper } = useParams<TLoginMiddlewareParams>();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const {
    data: { 'auth-mode': authMode } = { 'auth-mode': AuthModes.CLOSE },
    isLoading: isModeLoading,
  } = useGetAuthConfigQuery();

  const navigate = useNavigate();
  const helperToken = useAppSelector(getHelperToken);
  const dispatch = useAppDispatch();
  const { tryAuth } = useHelperLogin();

  const [isLoading, setIsLoading] = React.useState(false);
  const [serverError, setServerError] = useState<string | null>();

  if (!helperToken && !token) {
    navigate('/home');
  }

  const loginOrSaveToken = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const user = await tryAuth(token!, helper!);
      if (user) {
        dispatch(login(user));
        navigate('/home');
      }
      else {
        dispatch(saveHelperToken({
          helperToken: token!,
          helperType: helper!,
        }));
      }
    } catch (e) {
      setServerError(extractError(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    if (!token || !helper) {
      return;
    }
    loginOrSaveToken();
  });

  useEffect(() => {
    if (!isModeLoading && authMode !== AuthModes.OPEN) {
      navigate('/login/code');
    }
  }, [isModeLoading, authMode]);

  if (!isModeLoading && authMode !== AuthModes.OPEN) {
    navigate('/login/code');
  }

  if (isLoading) return null;

  return (
    <Container>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src='/logo.png' alt='Logo' height={isDesktop ? '64px' : '64px'}
             width={isDesktop ? '64px' : '64px'} />
      </Box>
      <Title title={'Select login method'} backButton />
      <Breadcrumbs firstTitle={'Login'}
                   breadcrumbs={[{ label: 'Select login method', route: '' }]} />
      <ContentWrapper
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component='p' variant='body1' sx={{ marginBottom: '24px' }}>
          To connect this authorization method, you need to log into your account using
          one of the methods presented.
        </Typography>
        {!serverError && <Grid container gap='24px'>
          <Stack
            component='div'
            sx={{
              minWidth: '135px',
              alignItems: 'stretch',
              flexDirection: 'column',
              flexBasis: isDesktop ? '45%' : '100%',
              gap: '30px',
            }}
          >
            <Typography variant='h6' align='center'>
              I have an account
            </Typography>
            <Button
              type='button'
              variant='contained'
              color='primary'
              onClick={() => navigate('/login/code')}
            >
              Sign In using Access code
            </Button>
            <Button
              type='button'
              variant='contained'
              color='primary'
              onClick={() => navigate('/login/email')}
            >
              Sign In using email
            </Button>
          </Stack>
          <Stack
            component='div'
            sx={{
              minWidth: '135px',
              alignItems: 'stretch',
              flexDirection: 'column',
              flexBasis: isDesktop ? '45%' : '100%',
              gap: '30px',
            }}
          >
            <Typography variant='h6' align='center'>
              I need a new account
            </Typography>
            <Button
              type='button'
              variant='contained'
              color='primary'
              onClick={() => navigate('/register/new-user-by-google')}
            >
              Register new account
            </Button>
          </Stack>
        </Grid>}
        {serverError && <Alert severity='error'>{serverError}</Alert>}
      </ContentWrapper>
    </Container>
  );
}

