import { Box, Typography } from '@mui/material';
import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Container, ContentWrapper, Title } from '../../components';
import QuillHtmlRenderer from '../../components/QuillHtmlRenderer';
import { dashboardsApi } from '../../redux/api/dashboardsApiSlice';
import {
  IQuestion,
  ISurvey,
  useCreateSurveyResultMutation,
} from '../../redux/api/surveysApiSlice';
import { useAppDispatch } from '../../redux/hooks';
import SurveyItem from './SurveyItem';
import { TChainPartHandle, THook, TSurveyAnswers } from './types';

export const LockableSurveyWrapper = ({
  name,
  children,
  blockSkip,
  onSkip,
}: {
  children: React.ReactNode;
  name: string;
  blockSkip: boolean;
  onSkip: () => void;
}) => {
  return (
    <div
      style={{
        minWidth: '100vw',
        minHeight: '100dvh',
        zIndex: 9999,
      }}
    >
      <Container sx={{ '.contentWrapper': { paddingTop: '16px !important' } }}>
        <img src='/logo.png' alt='' width={'64px'} height={'64px'}
             style={{ margin: '0 auto', display: 'block' }} />
        <Title
          title={name}
          {...(!blockSkip && {
            rightAction: {
              label: 'Skip step',
              onClick: () => {
                onSkip();
              },
            },
          })}
        />
        <ContentWrapper sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          {children}
        </ContentWrapper>
      </Container>
    </div>
  );
};

function getStepsCount(questions: IQuestion[]) {
  return new Set(questions.map(q => q.step)).size;
}

type TSurveyProps = {
  onCanSkip: (can: boolean) => void;
  onCanProceed: (can: boolean) => void;
  survey: ISurvey;
  mountId: string;
  hook: THook;
  sessionId: string;
  internalStep: number;
}

export const Survey = React.memo(React.forwardRef<TChainPartHandle, TSurveyProps>(({
  survey,
  internalStep: currentStep,
  hook,
  mountId,
  onCanProceed,
  sessionId,
  onCanSkip,
}, ref) => {
  const [answers, setAnswers] = useState<TSurveyAnswers>({});
  const [saveSurveyResults] = useCreateSurveyResultMutation();
  const dispatch = useAppDispatch();

  const stepsCount = getStepsCount(survey.questions);

  const stepQuestions = useMemo((): IQuestion[] => {
    if (!survey?.questions?.length) return [];
    return survey.questions.filter((question) => question.step === currentStep);
  }, [survey?.questions, currentStep]);

  useImperativeHandle(ref, () => ({
    nextStep: (step: number) => {
      if (step > stepsCount) {
        if (hook === 'First login') {
          saveSurveyResults({
            hook: hook,
            mountId: mountId,
            surveyId: survey._id,
            result: Object.values(answers),
          });
        }
        else {
          saveSurveyResults({
            hook: hook,
            mountId: mountId,
            sessionId,
            surveyId: survey._id,
            result: Object.values(answers),
          });
        }
        dispatch(dashboardsApi.util?.invalidateTags(['DashboardCards']));
        dispatch(dashboardsApi.util?.invalidateTags(['DashboardChart']));
      }
      return step;
    },
    getMessage: () => {
      const invalidValues = stepQuestions.filter(q => {
        const answer = answers[q.id];
        if (!answer && q.skippable) {
          return false
        }
        const { result } = answer;
        return ((q.minValue && +result < q.minValue) || (q.maxValue && +result > q.maxValue))
      })

      if (!invalidValues.length) {
        return null;
      }

      const messageParts = invalidValues.map(q => {
        return `${answers[q.id].result} for the ${q.text}`
      })
      return `You have entered ${messageParts.join(', ')}, are you sure?`
    },
  }));

  useEffect(() => {
    const stepFulfilled = stepQuestions.every(q => {
      if (q.skippable) {
        return true;
      }
      if (!answers[q.id]) {
        return false;
      } 
      
      let questionResult = answers[q.id].result;

      if (q.type === 'checkbox' ) {
        if(q.other){
          questionResult = questionResult.slice(1);
        }
        return questionResult.includes('1');   
      }

      return answers[q.id].result !== '';

    });
    onCanProceed(stepFulfilled);
  }, [answers, currentStep]);

  useEffect(() => {
    onCanSkip(stepQuestions.every(q => q.skippable));
  }, [currentStep]);

  useEffect(() => {
    setAnswers({});
  }, [survey._id]);

  const handleOnChange = (result: string, id: string, otherValue?: string) => {
    setAnswers(prev => ({
      ...prev,
      [id]: {
        id,
        result,
        otherValue,
      },
    }));
  };
  const renderContent = () => (
    <Box>
      <div>
        <QuillHtmlRenderer html={survey.description} />
      </div>
      {stepQuestions.length && (
        <Box sx={{ margin: '30px 0' }}>
          {stepQuestions.map((question) => (
            <SurveyItem
              key={question.id}
              question={question}
              onChange={(value, otherValue) => handleOnChange(value, question.id, otherValue)}
              data={answers[question.id]}
            />
          ))}
        </Box>
      )}
    </Box>
  );
  const SimpleSurvey = () => {
    return (
      <Box>
        <Typography variant='h2' sx={{ marginBottom: '22px' }}>
          {survey.name}
        </Typography>
        <Typography variant='body1' sx={{ overflowWrap: 'anywhere' }}>
          {renderContent()}
        </Typography>
      </Box>
    );
  };

  const LockableSurvey = () => {
    return <>{renderContent()}</>;
  };

  return useMemo(
    () => survey.lockDisplay
      ? <LockableSurvey />
      : <SimpleSurvey />,
    [currentStep, survey._id],
  );
}), (prev, next) => {
  return prev.survey._id === next.survey._id &&
    prev.internalStep === next.internalStep &&
    prev.sessionId === next.sessionId;
});

