import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery, FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/react';
import { clearLogout, getToken } from '../redux/actions/userActions';
import { RootState } from '../redux/store';

const RESPONSE_CODE = {
  NO_AUTH: 401,
};

export default function generateBaseQuery() {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      // @ts-ignore
      const token = getToken(getState());

      headers.set('Authorization', `Bearer ${token}`);
    },
  });
  const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions = {}) => {
    const result = await baseQuery(args, api, extraOptions);
    const user = (api.getState() as RootState).user;
    if (result?.error?.status === RESPONSE_CODE.NO_AUTH && (result?.error?.data as any)?.message !== 'Insufficient permissions' && user.authenticated) {
      clearLogout(api.dispatch);
    }

    return result;
  };
  return baseQueryWithReauth;
}