import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { initiateSocket } from '../../shared/socket';
import type { AppDispatch, RootState } from '../store';
import {apiSlice} from "../api/apiSlice";

export type TAuthHelperType = 'google' | 'facebook' | 'instagram' | 'twitter' | 'code';

// Define a type for the slice state
export interface UserState {
  authenticated: boolean;
  id: number;
  token: string;
  helperToken: string;
  authType?: TAuthHelperType;
  loginError: string;
  googleToken: string;
  facebookToken: string;
  instagramToken: string;
  twitterToken: string;
}

// Define the initial state using that type
export const initialState: UserState = {
  authenticated: false,
  id: 0,
  token: '',
  helperToken: '',
  loginError: '',
  googleToken: '',
  facebookToken: '',
  twitterToken: '',
  instagramToken: '',
};
export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        accessToken: string;
      }>,
    ) => {
      state.authenticated = true;
      state.token = action.payload.accessToken;
      Cookies.set(CONST_TOKEN_COOKIE, action.payload.accessToken, {
        expires: 1,
      });
      initiateSocket();
    },
    loginError: (
      state,
      action: PayloadAction<{
        error: string;
      }>,
    ) => {
      state.authenticated = false;
      state.loginError = action.payload.error;
    },
    cleanError: (state) => {
      state.authenticated = false;
      state.loginError = '';
    },
    logout: (state) => {
      state.token = '';
      state.authenticated = false;
      Cookies.remove(CONST_TOKEN_COOKIE);
    },
    saveToken: (
      state,
      action: PayloadAction<{
        token: string;
      }>,
    ) => {
      state.token = action.payload.token;
    },
    saveHelperToken: (
      state,
      action: PayloadAction<{
        helperToken: string;
        helperType: TAuthHelperType;
      }>,
    ) => {
      state.helperToken = action.payload.helperToken;
      state.authType = action.payload.helperType;
    },
    clearToken: (state) => {
      state.helperToken = '';
      state.authType = 'code';
    },
    saveFacebookToken: (
      state,
      action: PayloadAction<{
        facebookToken: string;
      }>,
    ) => {
      state.facebookToken = action.payload.facebookToken;
    },
    saveInstagramToken: (
      state,
      action: PayloadAction<{
        instagramToken: string;
      }>,
    ) => {
      state.instagramToken = action.payload.instagramToken;
    },
    saveTwitterToken: (
      state,
      action: PayloadAction<{
        twitterToken: string;
      }>,
    ) => {
      state.twitterToken = action.payload.twitterToken;
    },
  },
});

export const {
  login,
  logout,
  loginError,
  cleanError,
  saveToken,
  saveHelperToken,
  clearToken,
  saveFacebookToken,
  saveTwitterToken,
  saveInstagramToken,
} = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const clearLogout = (dispatch: AppDispatch) => {
  dispatch(apiSlice.util.resetApiState());
  dispatch(logout());
};
export const getUser = (state: RootState) => state.user;
export const getToken = (state: RootState) => state.user.token;
export const getError = (state: RootState) => state.user.loginError;
export const getGoogleToken = (state: RootState) => state.user.googleToken;
export const getHelperToken = (state: RootState) => state.user.helperToken;
export const getHelperType = (state: RootState) => state.user.authType;
export const getFacebookToken = (state: RootState) => state.user.facebookToken;
export const getInstagramToken = (state: RootState) => state.user.instagramToken;
export const getTwitterToken = (state: RootState) => state.user.twitterToken;
export const CONST_TOKEN_COOKIE = 'R-TOKEN';

export default userSlice.reducer;

