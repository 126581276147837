import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from 'react';
import SharedDialog, { TSharedDialogProps } from './SharedDialog';

export type TAdaptiveSelectItem = {
  _id: string;
  parent?: string | null;
  helperText?: string;
  name: string;
};

export type TAdaptiveSelectProps = {
  data: TAdaptiveSelectItem[];
  value: string;
  onChange: (id: string) => void;
  sx?: SxProps;
  placeholder: string;
};

type TTransformed<T> = {
  data: T;
  children?: TTransformed<T>[];
};

type DataWithLevel = TAdaptiveSelectItem & { level: number };

const transformData = (data: TAdaptiveSelectItem[], parentId: string | null = null) => {
  const result: TTransformed<TAdaptiveSelectItem>[] = [];
  data.forEach((item) => {
    if (item.parent === parentId) {
      const children = transformData(data, item._id);
      const newItem: TTransformed<TAdaptiveSelectItem> = {
        data: item,
      };
      if (children.length > 0) {
        newItem.children = children;
      }
      result.push(newItem);
    }
  });
  return result;
};

function flattenWithLevel(
  structure: TTransformed<TAdaptiveSelectItem>[],
  final: DataWithLevel[] = [],
  level: number = 0,
) {
  structure.forEach((item) => {
    final.push({
      ...item.data,
      level: level,
    });
    if (item.children) {
      flattenWithLevel(item.children, final, level + 1);
    }
  });
  return final;
}

export default function AdaptiveSelect({ data, value, onChange, sx, placeholder }: TAdaptiveSelectProps) {
  const isDesktop = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('md');
  });
  const [isOpened, setIsOpened] = useState(false);

  const structured = transformData(data);
  const flat = flattenWithLevel(structured);

  return isDesktop ? (
    <Select
      fullWidth
      sx={{
        ...sx,
      }}
      displayEmpty
      value={value}
      open={isOpened}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      onChange={(ev) => onChange(ev.target.value as string)}
    >
      <MenuItem value="">
        <span style={{ color: '#BDBDBD' }}>{placeholder}</span>
      </MenuItem>
      {flat.map((item) => (
        <MenuItem
          key={item._id}
          value={item._id}
          sx={{
            paddingLeft: item.level * 2 + 2,
          }}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <>
      <Button
        fullWidth
        sx={{
          padding: '.5em',
          border: '1px solid #BDBDBD',
          fontWeight: 'normal',
          color: 'rgba(0, 0, 0, 0.54)',
          justifyContent: 'space-between',
          marginBottom: '24px',
        }}
        variant="outlined"
        onClick={() => setIsOpened(true)}
      >
        {data.find((opt) => opt._id === value)?.name || placeholder}
        <ArrowDropDownIcon />
      </Button>
      <SelectDialog
        title={placeholder}
        open={isOpened}
        onClose={() => setIsOpened(false)}
        onSelected={onChange}
        value={value}
        options={flat}
      />
    </>
  );
}

export type TSelectDialogProps = TSharedDialogProps & {
  onSelected: (sortBy: string) => void;
  value: string;
  options: DataWithLevel[];
  title: string;
};

export function SelectDialog(props: TSelectDialogProps) {
  const { onSelected, value, title, options, ...rest } = props;
  const { open, onClose } = rest;
  const [currentChoice, setCurrentChoice] = useState(value);

  useEffect(() => {
    setCurrentChoice(value);
  }, [value, open]);

  return (
    <SharedDialog {...rest} PaperProps={{ sx: { marginLeft: '0', marginRight: '0' } }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <RadioGroup value={currentChoice} onChange={(ev) => setCurrentChoice(ev.target.value as string)}>
          {options.map((opt) => (
            <FormControlLabel
              key={opt._id}
              value={opt._id}
              control={<Radio />}
              label={
                <>
                  {opt.name}
                  <Typography fontSize="80%">{opt.helperText}</Typography>
                </>
              }
              sx={{ paddingLeft: opt.level * 2 + 2 }}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-around" gap="16px">
          <Button variant="outlined" onClick={(ev) => onClose && onClose(ev, 'escapeKeyDown')}>
            Cancel
          </Button>
          <Button
            disabled={!currentChoice}
            variant="contained"
            onClick={(ev) => {
              onSelected(currentChoice);
              onClose && onClose(ev, 'escapeKeyDown');
            }}
          >
            Confirm and close
          </Button>
        </Grid>
      </DialogActions>
    </SharedDialog>
  );
}
