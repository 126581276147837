import { Box, Button, Grid, Step, StepButton, StepLabel, Stepper, Typography } from '@mui/material';
import { Modal } from '../../components';
import { useState } from 'react';
import {  useUpdateOnboardingMutation } from '../../redux/api/settingsApiSlice';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';

interface IProps {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const MAX_STEP = 3;

const StepperStyle = styled(Stepper)`
  justify-content: center;
  margin: 30px 0;
  & .MuiStep-root {
    display: flex;
    align-items: center;
    justify-content: center;  
  }

  & .MuiButtonBase-root {
    padding:0;
  }
`;

const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.action.disabled,
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    '& .StepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

const StepIcon = (props: StepIconProps) => {
  const { active, className } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
        <div className="StepIcon-circle" />
    </StepIconRoot>
  );
}

export const WelcomeTrip = ({ open, setIsOpen }: IProps) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [openSkipTripModal, setOpenSkipTripModal] = useState<boolean>(false);

  const [updateOnboarding] = useUpdateOnboardingMutation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    setOpenSkipTripModal(!openSkipTripModal);
  };

  const FirstTextContent = () => (
    <>
      Rose lets you create your own hypnotic experiences to increase your comfort and improve your well-being. We call
      each type of experience a <b>path</b>. Listening to paths helps you reach your chosen goals; for example to{' '}
      <b>Feel Better Now</b>.
    </>
  );
  const SecondTextContent = () => (
    <>
      Paths are made of four components: a <b>cue</b>, then an <b>opening</b>, then an{' '}
      <b>experience</b>, ending with a <b>reminder</b>. Once you have selected a path, you listen as it guides you
      through your hypnotic experience - typically at least once a day. The more you practice, the better you can get.
    </>
  );
  const ThirdTextContent = () => (
    <>
      Start by choosing a goal (either <b>Feel Better Now</b> or <b>Create Enduring Change</b>). Follow the steps on screen, 
      then Rose will prepare a path for you. After you have listened to your first path, you will then be able to{' '} 
      <b>build your own paths</b> and save <b>favorite paths</b>.
    </>
  );

  const stepsContent: { [key: number]: JSX.Element } = {
    1: <FirstTextContent />,
    2: <SecondTextContent />,
    3: <ThirdTextContent />,
  };

  const isLastStep = activeStep === MAX_STEP;
  const handleConfirm = async () => {
    await updateOnboarding({
      welcomeTrip: true,
    });
    setOpenSkipTripModal(false);
    setIsOpen(false);
    setActiveStep(1);
  };

  return (
    <>
      <Modal
        open={openSkipTripModal}
        title="Skip the Trip "
        description='You can launch this trip again anytime in the Settings'
      >
        <Button sx={{ width: '100%' }} variant="contained" onClick={handleConfirm}>
          Confirm Skip
        </Button>
        <Button
          sx={{ width: '100%', marginTop: '24px' }}
          variant="outlined"
          onClick={() => {
            setOpenSkipTripModal(false);
          }}
        >
          Return to Welcome Trip
        </Button>
      </Modal>

      <Modal
        open={open}
        title="Welcome to Rose!"
        sx={{
          maxWidth: '448px',
          minWidth: '318px',
          width: '100%',
        }}
      >
        <img
          src={`/welcome_trip_${activeStep}.svg`}
          alt="welcome trip"
          style={{ width: '100%', maxWidth: '400px', minWidth: '280px' }}
        ></img>
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '12px' }}
        >
          {stepsContent[activeStep]}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <StepperStyle nonLinear activeStep={activeStep - 1} connector={null}>
          {[1,2,3].map((step) => (
            <Step key={step}>
              <StepButton onClick={() => setActiveStep(step)}>
              <StepLabel StepIconComponent={StepIcon}/>
              </StepButton>
            </Step>
          ))}
          </StepperStyle>
        </Box>

        <Grid container flexDirection={'row'}>
          <Grid item xs={isLastStep ? 6 : 4} container justifyContent={'flex=start'}>
            {activeStep !== 1 && (
              <Button size='small' variant='contained' onClick={handleBack}>
                Previous
              </Button>
            )}
          </Grid>
          {!isLastStep && (
            <Grid item xs={4} container justifyContent={'center'}>
            <Button size="small" variant="outlined" onClick={handleSkip}>
              Skip Trip
            </Button>

            </Grid>)}
          <Grid item xs={isLastStep ? 6 : 4} container justifyContent={'flex-end'}>
            <Button size='small' variant='contained'
                    onClick={!isLastStep ? handleNext : handleConfirm}>
            {isLastStep ? 'Let’s Get Started' : 'Next'}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

