import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useLoadSettingsQuery } from '../redux/api/settingsApiSlice';
import { isUnpaidTier } from '../shared/subscriptions';
import SharedDialog from './SharedDialog';

export default function LockedFeatureDialog() {
  const navigate = useNavigate();
  const { data: settings } = useLoadSettingsQuery();

  if (!isUnpaidTier(settings)) {
    return null;
  }

  return <SharedDialog open={true}>
    <DialogTitle>Access denied</DialogTitle>
    <DialogContent>
      This feature is not available during Free Tier.
    </DialogContent>
    <DialogActions>
      <Grid container justifyContent='space-around'>
        <Button variant='contained' onClick={() => navigate('/home')}>To Home
          Page</Button>
      </Grid>
    </DialogActions>
  </SharedDialog>;
}