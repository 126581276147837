import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { TSettings } from '../../redux/api/settingsApiSlice';
import { useGetVoicesQuery } from '../../redux/api/voicesApiSlice';
import useGetFilePath from '../../shared/useGetFilePath';

export type TPlayerAdditionalFragmentsProps = {
  settings: TSettings;
  type: 'intro' | 'back';
  onEnd: () => void;
  onTimeUpdate?: (progress: number) => void;
  onDurationChanges?: (duration: number) => void;
  playing: boolean;
  pathVoice?: string;
}

export type TAdditionalFragmentHandler = {
  play: () => void;
  stop: () => void;
}

export default forwardRef<TAdditionalFragmentHandler, TPlayerAdditionalFragmentsProps>(
  function PlayerAdditionalFragments({
    settings,
    type,
    onEnd,
    onTimeUpdate,
    onDurationChanges,
    pathVoice,
  }, ref) {
    const audioRef = useRef<HTMLAudioElement>(null);
    const { data: voices = [] } = useGetVoicesQuery();
    const file = useGetFilePath(
      `client/voices/audio/${type === 'back' ? pathVoice : settings.voice}/${type}`,
    );

    useImperativeHandle(ref, () => ({
      play() {
        if (!audioRef.current) {
          return;
        }
        audioRef.current.play();
      },
      stop() {
        if (!audioRef.current) {
          return;
        }
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }));

    useEffect(() => {
      if (!voices.length || !settings.voice) {
        return;
      }
      const voice = voices.find(voice => {
        if (type === 'back') {
          return voice._id === pathVoice;
        }
        return voice._id === settings.voice;
      });
      if (!voice) {
        return;
      }
      const duration = type === 'back' ? voice.audioLengthBack : voice.audioLengthIntro;
      onDurationChanges && onDurationChanges(duration);
    }, [voices.length, settings.voice, type, pathVoice]);

    return <audio
      onTimeUpdate={(e) => {
        if (onTimeUpdate) {
          onTimeUpdate((e.target as HTMLAudioElement).currentTime);
        }
      }}
      onEnded={onEnd}
      style={{ display: 'none' }}
      ref={audioRef}
      controls
      src={file}
      playsInline
    />;
  });