import {
  Box,
  Drawer,
  Grid,
  Paper,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { Component } from '../../redux/api/componentsApiSlice';
import { convertSecondsToHMS } from '../../shared/convertor';
import { findComponent } from '../../shared/finders';
import { ReactComponent as DrawerOpener } from '../../svg/small-drawer-opener.svg';
import { SelectedComponents } from './BuildYourOwn';

export type TChosenPlaylistProps = {
  allComponents: Component[];
  selectedComponents: SelectedComponents;
  overallTime: number;
}

export default function ChosenPlaylist({
  selectedComponents,
  allComponents,
  overallTime,
}: TChosenPlaylistProps) {
  const isDesktop = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('sm');
  });
  const ref = useRef<HTMLDivElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  return isDesktop ? <Box position='relative' minWidth='40%'>
    <Grid
      ref={ref}
      item
      container
      direction='column'
      gap='18px'
      width='auto'
      margin='0 24px 18px 24px'
      sx={{
        position: 'sticky',
        top: '80px'
      }}
    >
      <Typography variant='h3'>Chosen playlist</Typography>
      {Object.entries(selectedComponents).map(([step, id]) => {
        const component = findComponent(id, allComponents);
        if (!component) {
          return null;
        }
        return <Grid key={`selected_${component._id}`} item>
          <Typography variant='body1' sx={{ overflowWrap: 'anywhere' }}>
            {component.name} ({convertSecondsToHMS(component.audioLength)})
          </Typography>
          <Typography variant='body1' sx={{ fontSize: '80%', color: '#BDBDBD' }}>
            {step}
          </Typography>
        </Grid>;
      })}
      <Grid item flexWrap='nowrap' container gap='5px'>
        <Grid item sx={{ whiteSpace: 'nowrap' }}>
          Approximate length
        </Grid>
        <Grid item sx={{ whiteSpace: 'nowrap' }}>
          {convertSecondsToHMS(overallTime || 0)}
        </Grid>
      </Grid>
  </Grid>
  </Box> : <>
    <Box sx={{
      transition: 'all .2s ease',
      position: 'fixed',
      right: isOpened ? 'calc(50% - 8px)' : '-8px',
      top: 'calc(50% - 2em)',
      height: '4em',
      width: '2em',
    }}>
      <SvgIcon
        sx={{ width: '100%', height: '100%' }}
        component={DrawerOpener}
        inheritViewBox
        onClick={() => setIsOpened(true)}
      />
    </Box>
    <Drawer
      key='playlist_drawer'
      anchor='right'
      variant='temporary'
      open={isOpened}
      onClose={() => setIsOpened(false)}
      keepMounted
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: {
          boxSizing: 'border-box',
          width: '50%',
          background: 'transparent',
          boxShadow: 'none',
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      }}
    >
      <Paper sx={{ padding: '24px', borderRadius: 0 }}>
        <Grid item container direction='column' gap='18px' width='auto'
              margin='0 24px'>
          <Typography variant='h3'>Chosen playlist</Typography>
          {Object.entries(selectedComponents).map(([step, id]) => {
            const component = findComponent(id, allComponents);
            if (!component) {
              return null;
            }
            return <Grid key={`selected_${component._id}`} item>
              <Typography variant='body1' sx={{ overflowWrap: 'anywhere' }}>
                {component.name} ({convertSecondsToHMS(component.audioLength)})
              </Typography>
              <Typography variant='body1' sx={{ fontSize: '80%', color: '#BDBDBD' }}>
                {step}
              </Typography>
            </Grid>;
          })}
        </Grid>
      </Paper>
    </Drawer>
  </>;
}