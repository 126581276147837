import { useCallback, useEffect, useState } from 'react';
import { getSocket } from './socket';

enum PlayerCommands {
  PLAY = 'play',
  PAUSE = 'pause',
  RESUME = 'resume',
  FINISH = 'finish',
  CLOSE = 'close',
  ERROR = 'error',
}

type TUsePlayerPayload = {
  path: string;
}

export type TUsePlayerActions = {
  play: (payload: TUsePlayerPayload) => void;
  pause: (payload: TUsePlayerPayload) => void;
  resume: (payload: TUsePlayerPayload) => void;
  finish: (payload: TUsePlayerPayload) => void;
  close: (payload: TUsePlayerPayload) => void;
  error: (payload: TUsePlayerPayload) => void;
}

export type TRegularEvent = {
  duration: number;
  timeLimit: number;
}

export type TErrorEvent = {
  message: string;
}

export const usePlayer = (onReconnect: (actions: TUsePlayerActions) => void) => {
  const [timer, setTimer] = useState<{
    timeLimit?: number;
    duration: number
  }>({ timeLimit: undefined, duration: 0 });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const socket = getSocket();

  const disconnect = () => {
    socket.disconnect();
  };

  const connect = () => {
    setErrorMessage('');
    socket.connect();
  }

  const play = useCallback((payload: { path: string }) => {
    socket.emit(PlayerCommands.PLAY, payload);
  }, [socket?.id]);
  const pause = useCallback((payload: { path: string }) => {
    socket.emit(PlayerCommands.PAUSE, payload);
  }, [socket?.id]);
  const resume = useCallback((payload: { path: string }) => {
    socket.emit(PlayerCommands.RESUME, payload);
  }, [socket?.id]);
  const finish = useCallback((payload: { path: string }) => {
    socket.emit(PlayerCommands.FINISH, payload);
  }, [socket?.id]);
  const close = useCallback((payload: { path: string }) => {
    socket.emit(PlayerCommands.CLOSE, payload);
  }, [socket?.id]);
  const error = useCallback((payload: { path: string }) => {
    socket.emit(PlayerCommands.ERROR, payload);
  }, [socket?.id]);

  const actions: TUsePlayerActions = { play, pause, resume, finish, close, error };

  useEffect(() => {
    const eventHandler = (e: TRegularEvent) => {
      setTimer({
        timeLimit: e.timeLimit,
        duration: e.duration
      })
    }
    const finalEventHandler = (e: TRegularEvent) => {
      setTimer({
        timeLimit: e.timeLimit,
        duration: e.duration
      });
      disconnect();
    }
    const errorEventHandler = (e: TErrorEvent) => {
      setErrorMessage(e.message);
      disconnect();
    }
    const reconnectEventHandler = () => {
      onReconnect(actions);
    }
    socket.on(PlayerCommands.PLAY, eventHandler);
    socket.on(PlayerCommands.PAUSE, eventHandler);
    socket.on(PlayerCommands.RESUME, eventHandler);
    socket.on(PlayerCommands.FINISH, finalEventHandler);
    socket.on(PlayerCommands.CLOSE, finalEventHandler);
    socket.on(PlayerCommands.ERROR, errorEventHandler);
    socket.io.on('reconnect', reconnectEventHandler)
    return () => {
      socket.off(PlayerCommands.PLAY, eventHandler);
      socket.off(PlayerCommands.PAUSE, eventHandler);
      socket.off(PlayerCommands.RESUME, eventHandler);
      socket.off(PlayerCommands.FINISH, finalEventHandler);
      socket.off(PlayerCommands.CLOSE, finalEventHandler);
      socket.off(PlayerCommands.ERROR, errorEventHandler);
      socket.io.off('reconnect', reconnectEventHandler)
    }
  }, []);

  return { actions, timer, connect, disconnect, errorMessage };
};

