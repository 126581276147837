import { useNavigate } from 'react-router-dom';
import { Card } from '../../components';
import CardsContainer from '../../components/CardsContainer';
import { useGetParentGoalsQuery } from '../../redux/api/goalsApiSlice';

export default function Goals({disabled}: {disabled:boolean}) {
  const navigate = useNavigate();
  const { data = [] } = useGetParentGoalsQuery();

  return (
    <CardsContainer>
      {data?.map((item) => (
        <Card
          disabled={disabled}
          key={item._id}
          name={item.name}
          description={item.description}
          image={item.image}
          onClick={() => {
            navigate(`/goal/${item._id}`);
          }}
        />
      ))}
    </CardsContainer>
  );
}

