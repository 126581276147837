import { apiSlice } from './apiSlice';

export type TFinancialSubscriptionPlan = {
  '_id': string,
  'remote_id': string,
  'currency': string,
  'interval': string,
  'interval_count': number,
  'amount': number,
  'createdAt': string,
  'updatedAt': string,
}

export type TFinancialCouponType = 'fixed' | 'percent';

export type TFinancialDiscount = {
  type: TFinancialCouponType;
  amount: number;
  currency?: string;
}

export const financialApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generatePortalUrl: builder.mutation<{ url: string }, void>({
      query: () => ({
        url: 'client/stripe/get-subscription-portal-url',
        method: 'GET',
      }),
    }),
    createCheckoutSession: builder.mutation<{ url: string }, {
      subscriptionPlanId: string
    }>({
      query: (data) => ({
        url: 'client/stripe/create-checkout-session',
        method: 'POST',
        body: data,
      }),
    }),
    getSubscriptionPlanList: builder.query<TFinancialSubscriptionPlan[], void>({
      query: (data) => ({
        url: 'client/stripe/get-subscription-list',
        method: 'GET',
        body: data,
      }),
    }),
    getDiscount: builder.query<{ discount: TFinancialDiscount }, void>({
      query: (data) => ({
        url: 'client/stripe/get-discount',
        method: 'GET',
        body: data,
      }),
    }),
  }),
});

export const {
  useCreateCheckoutSessionMutation,
  useGeneratePortalUrlMutation,
  useGetSubscriptionPlanListQuery,
  useGetDiscountQuery,
} = financialApi;

