import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import SharedDialog, { TSharedDialogProps } from '../../components/SharedDialog';
import { useDeleteFavoriteMutation } from '../../redux/api/favoritesApiSlice';

type TSortDialogProps = TSharedDialogProps & {
  id: string;
}

export default function RemoveDialog(props: TSortDialogProps) {
  const { id, ...rest } = props;
  const { onClose } = rest;
  const [deleteFavorite] = useDeleteFavoriteMutation();

  async function handleRemove(ev: React.MouseEvent<HTMLButtonElement>) {
    await deleteFavorite(id);
    if (onClose) {
      onClose(ev, 'escapeKeyDown');
    }
  }

  return <SharedDialog {...rest}>
    <DialogTitle>Are you sure you'd like to remove the path from your
      favorites?</DialogTitle>
    <DialogContent>
      Removing will remove this path from the your favorites list.
    </DialogContent>
    <DialogActions>
      <Grid container justifyContent='space-around' gap='8px'>
        <Button variant='outlined' onClick={(ev) => onClose && onClose(
          ev,
          'escapeKeyDown',
        )}>Cancel</Button>
        <Button variant='contained' onClick={(ev) => handleRemove(ev)}>Remove
          Path</Button>
      </Grid>
    </DialogActions>
  </SharedDialog>;
}