import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, SxProps } from '@mui/material';
import * as React from 'react';

export const Modal = ({
  title,
  description,
  children,
  open,
  ...rest
}: {
  title: string;
  description?: string;
  children: React.ReactNode;
  open: boolean;
  sx?: SxProps;
}) => {
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiPaper-root': { ...rest.sx } } as any}
      >
        <Box>
          <DialogTitle variant="h2" textAlign={'center'} id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            {description && (
              <DialogContentText variant="body1" textAlign={'center'} id="alert-dialog-description">
                {description}
              </DialogContentText>
            )}
          </DialogContent>
          {children}
        </Box>
      </Dialog>
    </>
  );
};

