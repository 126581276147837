import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import MainRouter from './components/MainRouter';
import { store } from './redux/store';
import { PaletteMode, ThemeOptions, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeContext } from './shared/ThemeContext';
import { getDesignTokens } from './theme';
import ScrollToTop from './shared/ScrollToTop';

function App() {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const toggleMode = React.useCallback(
    () => setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light')),
    [],
  );
  const selectMode = React.useCallback(
    (mode: PaletteMode) => setMode(mode),
    [],
  );
  const theme = React.useMemo(
    () => createTheme(getDesignTokens(mode) as ThemeOptions),
    [mode],
  );

  return (
    <Provider store={store}>
      <ThemeContext.Provider value={{ toggleMode, selectMode }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
            <BrowserRouter>
              <MainRouter />
              <ScrollToTop />
            </BrowserRouter>
          </GoogleOAuthProvider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </Provider>
  );
}

export default App;

