import {
  Button,
  Grid, Theme,
  Typography, useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Container, ContentWrapper, Title } from '../../components';
import AdaptiveSelect from '../../components/AdaptiveSelect';
import Loading from '../../components/Loading';
import LockedFeatureDialog from '../../components/LockedFeatureDialog';
import NavBar from '../../components/NavBar';
import StepperBody from '../../components/StepperBody';
import {
  Component,
  ComponentType,
  useLoadComponentsQuery,
} from '../../redux/api/componentsApiSlice';
import { Goal, useGetAllGoalsQuery } from '../../redux/api/goalsApiSlice';
import { IGeneratePathResponse, useMakeOwnPathMutation } from '../../redux/api/pathAlpiSlice';
import { convertSecondsToHMS } from '../../shared/convertor';
import { createPathUri } from '../../shared/factories';
import { findComponent } from '../../shared/finders';
import ChosenPlaylist from './ChosenPlaylist';
import OwnComponentsList from './OwnComponentsList';
import SaveToFavoritesDialog from './SaveToFavoritesDialog';
import { useCreateFavoriteMutation, useLoadFavoritesQuery } from '../../redux/api/favoritesApiSlice';

export type SelectedComponents = Partial<Record<ComponentType, string>>;

const selectionOrder: ComponentType[] = [
  'Cue',
  'Opening',
  'Experience',
  'Reminder',
];

const findComponentGoalId = (components: Component[], selectedComponent: string, goals: Goal[], selectedGoal: string) => {
  const component = components.find(c => c._id === selectedComponent);

  const getDeepestChildGoal = (arr: Goal[], id: string): Goal[] => {
      const children = arr.filter(el => el.parent === id);
      if (children.length === 0) {
          return arr.filter(el => el._id === id);
      }
      let deepestChilds:Goal[] = [];
      for (const child of children) {
          const grandchild = getDeepestChildGoal(arr, child._id);
          deepestChilds = deepestChilds.concat(grandchild)
      }
      return deepestChilds;
  };

  const childGoals = getDeepestChildGoal(goals, selectedGoal);
  const goalId = component?.goals.find(g => childGoals.some(cg => g._id === cg?._id))?._id || selectedGoal;

  return goalId;
};

export const BuildYourOwn = () => {
  const { data: components = [], isLoading: isComponentsLoading } = useLoadComponentsQuery();
  const { data: goals = [], isLoading: isGoalsLoading } = useGetAllGoalsQuery();
  const { data: favorites } = useLoadFavoritesQuery();
  const [makePath] = useMakeOwnPathMutation();
  const [createFavorite] = useCreateFavoriteMutation();
  const navigate = useNavigate();

  const [step, setStep] = useState<ComponentType>('Cue');
  const [selectedComponents, setSelectedComponents] = useState<SelectedComponents>({});
  const [selectedGoal, setSelectedGoal] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState<IGeneratePathResponse | null>(null);

  const curStepIdx = selectionOrder.indexOf(step);
  const isLastStep = curStepIdx === selectionOrder.length - 1;

  const isDesktop = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('sm');
  });
  const isColumnNeeded = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('s');
  });

  const onPrevStep = () => {
    const prevStep = selectionOrder[curStepIdx - 1];
    setStep(prevStep);
  };

  const onNextStep = () => {
    if (!selectedComponents[step]) {
      return;
    }
    const nextStep = selectionOrder[curStepIdx + 1];
    setStep(nextStep);
  };   

  const isInFavorites = (pathId: string) => {
    return favorites?.find(fav => fav.path === pathId);
  };

  useEffect(() => {
    if (
      selectedComponents['Cue'] &&
      selectedComponents['Opening'] &&
      selectedComponents['Experience'] &&
      selectedComponents['Reminder']
    ) {
    const goalId = findComponentGoalId(components, selectedComponents['Experience'], goals, selectedGoal);

       makePath({
        cueId: selectedComponents['Cue'],
        openingId: selectedComponents['Opening'],
        experienceId: selectedComponents['Experience'],
        reminderId: selectedComponents['Reminder'],
        goalId: goalId,
      }).unwrap().then(data => {
        if(!isInFavorites(data.path)) {
          setPath(data)
        } else { 
          setPath(null) 
        }
      })
        }
  },[selectedComponents['Cue'],
  selectedComponents['Opening'],
  selectedComponents['Experience'],
  selectedComponents['Reminder']])

  const onStartPath = async (favouriteName?: string, redirectToHome = false) => {
    if (
      !selectedComponents['Cue'] ||
      !selectedComponents['Opening'] ||
      !selectedComponents['Experience'] ||
      !selectedComponents['Reminder']
    ) {
      return;
    }
    try {
      const goalId = findComponentGoalId(components, selectedComponents['Experience'], goals, selectedGoal);

      const data = path || (await makePath({
        cueId: selectedComponents['Cue'],
        openingId: selectedComponents['Opening'],
        experienceId: selectedComponents['Experience'],
        reminderId: selectedComponents['Reminder'],
        goalId: goalId,
      }).unwrap());

      if (favouriteName) {
        await createFavorite({
          path: data.path,
          skipHistoryUpdate: true,
          name: favouriteName,
        });
      }
      if (redirectToHome) {
        navigate(`/`);
      } else {
        navigate(createPathUri(goalId, 'make_your_own', data.path));
      }

    } catch (e) {
      navigate(`/`);
    }
  };
  const selectComponent = (id: string) => {
    setSelectedComponents(prev => ({
      ...prev,
      [step]: id,
    }));
  };

  const componentsForStep = (components || [])
  .filter(com => com.type === step)
  .filter(com => {
    if (step !== 'Experience' || com.goals.some(g => g._id === selectedGoal)) {
      return true;
    }

    const findChildren = (parentId: string): boolean => {
      const childrens = goals.filter(g => g.parent === parentId);

      if (childrens.length === 0) {
        return false;
      }

      return childrens.some(child => {
        return com.goals.some(g => g._id === child._id) || findChildren(child._id);
      });
    };

    return findChildren(selectedGoal);
  });

  const overallTime = Object.values(selectedComponents)
    .map(selected => findComponent(selected, components))
    .reduce((all, com) => all + (com?.audioLength || 0), 0);

  const isRenderSaveToFavorites = isLastStep && selectedComponents[step] && path;

  const isLoading = isComponentsLoading || isGoalsLoading;

  return (
    <Container>
      <LockedFeatureDialog />
      <NavBar />
      <Title title='Build Your Own Path' backButton />
      <Breadcrumbs breadcrumbs={[
        {
          route: '/build-your-own',
          label: 'Build Your Own Path',
        },
      ]} />
      <Grid container alignItems='flex-end'>
        <Grid item sx={{ width: '70%', minWidth: '300px' }}>
          <Typography variant='body1' sx={{ padding: '0 0 32px 16px', color: '#FFF' }}>
            Playback length will vary based on your current selected voice which
            is <b>{components[0]?.voice.name}</b>.
          </Typography>
          <Typography variant='body1' sx={{ padding: '0 0 0 16px', color: '#FFF' }}>
            Path options open after you have used them once. Each cue is paired with a
            specific reminder.
          </Typography>
        </Grid>
        {!isDesktop && <Grid item container sx={{
          width: '30%',
          minWidth: '200px',
          padding: '0 0 0 16px',
          gap: '16px',
          marginTop: '24px',
          flexWrap: 'nowrap'
        }}>
          <Grid item sx={{ color: '#FFF', whiteSpace: 'nowrap' }}>
            Approximate length
          </Grid>
          <Grid item sx={{ color: '#FFF', whiteSpace: 'nowrap' }}>
            {convertSecondsToHMS(overallTime || 0)}
          </Grid>
        </Grid>}
      </Grid>
      <ContentWrapper sx={{
        display: 'flex',
        marginTop: '32px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
      }}>
        <Grid item container justifyContent='space-between' gap='24px' flexWrap='nowrap'>
          <Grid item flexGrow={1}>
            <Typography variant='h6' sx={{ marginBottom: '24px' }}>{step}</Typography>
            {step === 'Experience' &&
              <AdaptiveSelect
                data={goals}
                value={selectedGoal}
                onChange={setSelectedGoal}
                placeholder='Choose your goal'
                sx={{ marginBottom: '24px' }}
              />
            }
            {isLoading ? <Loading /> : <OwnComponentsList
              components={componentsForStep}
              selectedComponent={selectedComponents[step] || ''}
              onChange={selectComponent}
            />}
          </Grid>
          <ChosenPlaylist
            allComponents={components}
            selectedComponents={selectedComponents}
            overallTime={overallTime || 0}
          />
        </Grid>
        <Grid item container justifyContent='space-between' alignItems='center'
              direction='row-reverse' gap='16px 24px'>
          <Grid item width='calc(50% - 24px)' {...((isRenderSaveToFavorites && isColumnNeeded) && {minWidth:'400px'})}  {...(isRenderSaveToFavorites && !isColumnNeeded && {direction:'column'})} container flexWrap='nowrap'
                justifyContent='space-between' flexGrow={1} gap='6px'>
                  <Grid item />
            {curStepIdx != 0 &&
              <Button sx={{ whiteSpace: 'nowrap' }} variant='outlined'
                      onClick={onPrevStep}>Previous Step</Button>}
            {isRenderSaveToFavorites && <Button
              variant='contained'
              onClick={() => setOpen(true)}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {'Save to Favorites'}
            </Button>}
            <Button
              disabled={!selectedComponents[step]}
              variant='contained'
              onClick={isLastStep ? () => onStartPath() : onNextStep}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {isLastStep ? 'Start Your Path' : 'Next Step'}
            </Button>
          </Grid>
          <Grid item {...(!isRenderSaveToFavorites && {width:'calc(50% - 24px)'})} minWidth='250px' flexGrow={1}>
            <StepperBody stepsCount={selectionOrder.length} activeStep={curStepIdx + 1} />
          </Grid>
        </Grid>
        {favorites&& <SaveToFavoritesDialog
        open={open}
        onSave={onStartPath}
        onClose={() => setOpen(false)}
        defaultName={`Favorite path ${(favorites).length + 1}`}
      />}
      </ContentWrapper>
    </Container>
  );
};

