import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SurveysChain from '../features/Surveys/SurveysChain';
import ConfirmForgotPassword from '../login/ConfirmForgotPassword';
import EmailConfirmed from '../login/EmailConfirmed';
import Invite from '../login/Invite';
import LoginCode from '../login/LoginCode';
import LoginEmail from '../login/LoginEmail';
import LoginMiddleware from '../login/LoginMiddleware';
import RegisterEmail from '../login/RegisterEmail';
import RegisterGoogle from '../login/RegisterGoogle';
import RequestForgotPassword from '../login/RequestForgotPassword';
import SignIn from '../login/SignIn';
import { BuildYourOwn } from '../pages/BuildYourOwn';
import { DashboardPage } from '../pages/DashboardPage';
import { Favorites } from '../pages/FavoritesPage';
import { GoalsDetails } from '../pages/GoalDetails';
import { HomePage } from '../pages/HomePage';
import { NotFound } from '../pages/NotFoundPage';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import { StaticPage } from '../pages/StaticPage';
import StripeConfirmPage from '../pages/Stripe/StripeConfirmPage';
import StripeRejectPage from '../pages/Stripe/StripeRejectPage';
import { AuthModes, useGetAuthConfigQuery } from '../redux/api/configurationSlice';
import ProtectedRoute from '../shared/ProtectRoute';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';

export default function MainRouter() {
  const { data: { 'auth-mode': authMode } = { 'auth-mode': AuthModes.OPEN } } = useGetAuthConfigQuery();

  return (
    <Routes>
      <Route path='/' element={<SignIn />} />
      {authMode === AuthModes.OPEN &&
        <>
          <Route path='/forgot-password/request-forgot-password'
                 element={<RequestForgotPassword />} />
          <Route path='/forgot-password/confirm-forgot-password/:code'
                 element={<ConfirmForgotPassword />} />
          <Route path='/invite/:code' element={<Invite />} />
          <Route path='/login/email' element={<LoginEmail />} />
          <Route path='/register/email' element={<RegisterEmail />} />
          <Route path='/register/new-user-by-google' element={<RegisterGoogle />} />
          <Route path='/email-confirmed' element={<EmailConfirmed />} />
        </>
      }
      <Route path='/login/middleware/:helper/:token' element={<LoginMiddleware />} />
      <Route path='/login/middleware' element={<LoginMiddleware />} />
      <Route path='/login/code' element={<LoginCode />} />
      <Route
        path='/home'
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/goal/:id'
        element={
          <ProtectedRoute>
            <GoalsDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path='/session/:goalId/:srouce'
        element={
          <ProtectedRoute>
            <SurveysChain startState='Start player' />
          </ProtectedRoute>
        }
      />
      <Route
        path='/session/:goalId/:source/:pathId'
        element={
          <ProtectedRoute>
            <SurveysChain startState='Start player' />
          </ProtectedRoute>
        }
      />
      <Route
        path='/favorites'
        element={
          <ProtectedRoute>
            <Favorites />
          </ProtectedRoute>
        }
      />
      <Route
        path='/page/:id'
        element={
          <ProtectedRoute>
            <StaticPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/:section"
        element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/settings'
        element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard'
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/build-your-own'
        element={
          <ProtectedRoute>
            <BuildYourOwn />
          </ProtectedRoute>
        }
      />
      <Route
        path='/subscription/success'
        element={
          <ProtectedRoute>
            <StripeConfirmPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/subscription/cancel'
        element={
          <ProtectedRoute>
            <StripeRejectPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/privacy-policy'
        element={
            <PrivacyPolicy />
        }
      />
      <Route
        path='*'
        element={
          <NotFound />
        }
      />
    </Routes>
  );
}

