import {Alert, Button, Grid, Typography} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {useLoadSettingsQuery} from '../../redux/api/settingsApiSlice';
import {
  TFinancialDiscount,
  useCreateCheckoutSessionMutation,
  useGeneratePortalUrlMutation,
  useGetDiscountQuery,
  useGetSubscriptionPlanListQuery,
} from '../../redux/api/stripeApiSlice';
import {extractError} from '../../shared/extractors';
import SubscriptionTile from './SubscriptionTile';
import {isPaidTier} from '../../shared/subscriptions';

export function formatDiscount(discount: TFinancialDiscount) {
  return discount.type === 'fixed'
    ? `-${(discount.amount / 100).toFixed()} ${discount.currency}`
    : `-${discount.amount}%`;
}

export default function SubscriptionPart() {
  const {data: settings} = useLoadSettingsQuery();

  const {data: subscriptionList} = useGetSubscriptionPlanListQuery();
  const {data: discount} = useGetDiscountQuery();
  const [checkoutSession] = useCreateCheckoutSessionMutation();
  const [generatePortalUrl] = useGeneratePortalUrlMutation();
  const [serverError, setServerError] = useState('');

  const endSubscriptionDate = settings?.paidDueDate
    ? new Date(settings.paidDueDate)
    : null;

  const openUrl = (url: string) => {
    window.location.href = url;
  };

  const openCabinetUrl = async () => {
    try {
      const portal = await generatePortalUrl().unwrap();
      if (portal) {
        openUrl(portal.url);
      }
      setServerError('');
    } catch (e) {
      setServerError(extractError(e));
    }
  };

  const createCheckoutSession = async (subscriptionPlanId: string) => {
    const session = await checkoutSession({subscriptionPlanId}).unwrap();
    if (session) {
      openUrl(session.url);
    }
  };

  const subscribePlanListItems = subscriptionList ?
    subscriptionList.map(subscription => {
      return <SubscriptionTile
        key={subscription._id}
        price={subscription.amount}
        discount={discount?.discount}
        label={subscription.interval_count + ' ' + subscription.interval}
        onSubscribe={() => {
          createCheckoutSession(subscription._id);
        }}
      />;
    })
    : [];
  if (settings?.type === 'beta') {
    return (
      <>
        <Grid container direction='column' gap='12px' alignItems='center'>
          <Grid item>
            <Typography>
              Beta users are not able to pay for a subscription
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      {isPaidTier(settings) && endSubscriptionDate && (
        <>
          <Grid container direction='column' gap='12px' alignItems='center'>

            <Grid item>
              {settings?.activeSubscription &&
                <Typography>Your subscription is active
                  until {endSubscriptionDate.toLocaleDateString('en-US')} </Typography>
              }
              {!settings?.activeSubscription &&
                <Typography>Your subscription is <strong>canceled</strong> but you still
                  have full access until {endSubscriptionDate.toLocaleDateString('en-US')}
                </Typography>
              }
            </Grid>
            <Grid item>
              <Button variant='outlined' onClick={openCabinetUrl}>
                Manage Subscription
              </Button>
            </Grid>
            {serverError && <Alert severity='error'>{serverError}</Alert>}
          </Grid>
        </>
      )}
      {!isPaidTier(settings) && (
        <Grid container direction='column' gap='12px' alignItems="center">
          <Grid item>
            <Typography>Choose the subscription plan that suits you best.</Typography>
          </Grid>
          {discount?.discount &&
            <Alert severity='info'>You have a
              discount {formatDiscount(discount.discount)}</Alert>}
          {subscribePlanListItems}
        </Grid>
      )}
    </>
  );
}
