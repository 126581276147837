import { Alert, Stack, TextField, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Container, ContentWrapper, Title } from '../components';
import { clearToken, getHelperToken, getHelperType } from '../redux/actions/userActions';
import { useGoogleRegisterMutation } from '../redux/api/authApiSlice';
import { useAppSelector } from '../redux/hooks';
import { extractError } from '../shared/extractors';
import { useValidate } from '../shared/useValidate';
import { registerGoogleSchema, TRegisterGoogleData } from './schemas';

export default function RegisterGoogle() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [data, setData] = useState<TRegisterGoogleData>({
    email: '',
    password: '',
    retypePassword: '',
  });
  const [serverError, setServerError] = useState<string | undefined>();
  const [serverMessage, setServerMessage] = useState<string | undefined>();
  const {
    getErrorProps,
    validate,
    clearOneError,
  } = useValidate({ scheme: registerGoogleSchema });

  const [helperRegister] = useGoogleRegisterMutation();
  const token = useAppSelector(getHelperToken);
  const helperType = useAppSelector(getHelperType);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function updateData<Key extends keyof TRegisterGoogleData>(
    field: Key,
    value: TRegisterGoogleData[Key],
  ) {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    clearOneError(field);
    setServerError(undefined);
  }

  const handleSubmit = async () => {
    if (!token) {
      navigate('/');
    }
    if (!validate(data)) {
      return;
    }
    try {
      await helperRegister(
        {
          accessToken: token,
          helperType: helperType!,
          email: data.email,
          password: data.password,
          type: 'register',
        }).unwrap();
      setServerMessage(
        'The user has been successfully created, to confirm your email, follow the link that we sent to you by email.');
      dispatch(
        clearToken(),
      );
    } catch (e) {
      setServerError(extractError(e));
    }
  };

  const canSubmit = data.password !== '' && data.retypePassword !== '';

  return (
    <Container>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src='/logo.png' alt='Logo' height={isDesktop ? '64px' : '64px'}
             width={isDesktop ? '64px' : '64px'} />
      </Box>
      <Title title='Social Sign Up' backButton />
      <Breadcrumbs firstTitle={'Login'}
                   breadcrumbs={[{ label: 'Social Sign Up', route: '' }]} />
      <ContentWrapper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
        }}>
        {serverMessage
          ? <>
              <Alert severity='info'>{serverMessage}</Alert>
              <Button
                href={`https://${data.email.split('@')[1]}`}
                variant='contained'
              >
                Check email
              </Button>
            </>
          : <>
            <Typography component='p' variant='body1' align='center'>
            Please, provide a valid Email Address and Password to Create an Account for Rose.
            </Typography>
            <Stack
              component='div'
              sx={{
                alignItems: 'stretch',
                flexDirection: 'column',
                width: isDesktop ? '351px' : '100%',
                gap: '16px',
              }}
            >
              <TextField
                label='E-mail'
                type='email'
                required
                autoFocus
                value={data.email}
                onChange={ev => updateData('email', ev.target.value)}
                name='password'
                {...getErrorProps('email')}
              />
              <TextField
                label='Password'
                type='password'
                required
                autoFocus
                value={data.password}
                onChange={ev => updateData('password', ev.target.value)}
                name='password'
                {...getErrorProps('password')}
              />
              <TextField
                required
                type='password'
                label='Retype password'
                value={data.retypePassword}
                onChange={ev => updateData('retypePassword', ev.target.value)}
                name='retypePassword'
                {...getErrorProps('retypePassword')}
              />
              {serverError && <Alert severity='error'>{serverError}</Alert>}
              <Button
                disabled={!canSubmit}
                type='button'
                variant='contained'
                onClick={handleSubmit}
              >
                Create an Account
              </Button>
            </Stack>
          </>}
      </ContentWrapper>

    </Container>
  );
}

