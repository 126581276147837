import { useState } from 'react';
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IQuestion } from '../../../redux/api/surveysApiSlice';
import { InputLabel, Theme, useMediaQuery } from '@mui/material';
import { TSurveyAnswer } from '../types';

type TSelectedFieldProps = {
  onChange: (result: string) => void;
  item: IQuestion;
  data?: TSurveyAnswer;
}

export default function SelectField({
  data: { result: defaultValue } = { result: '', id: '' },
  item,
  onChange,
}: TSelectedFieldProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [value, setValue] = useState(defaultValue);
  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setValue(event.target.value as string);
    const stringValue = typeof event.target.value === 'number'
      ? event.target.value.toString()
      : event.target.value;
    onChange(stringValue);
  };
  return (
    <FormControl sx={{ minWidth: isDesktop ? '50%' : '100%', display: 'flex', flexDirection: 'column' }}>
      {value === '' ? (
        <InputLabel disableAnimation shrink={false} focused={false} id='item_type_label'>
          {item.placeholder}
        </InputLabel>
      ) : null}

      <Select labelId='simple-select-helper-label' id='simple-select-helper' value={value}
              onChange={handleChange} sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}>
        <MenuItem disabled value='' color='red'>
          <em>{item.placeholder}</em>
        </MenuItem>
        {item.questionOptions?.map((option: any) => (
          <MenuItem key={option.value} value={option.value} sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

