import { Button } from '@mui/material';
import { Modal } from '../../components';

export default function PlayerCloseModal({ setShowCloseModal, onConfirmed }: any) {
  return (
    <Modal
      open={true}
      title="Are you sure you'd like to stop?"
      description='Stopping will close the player and send you to the Home page.'
    >
      <Button
        sx={{ width: '100%' }}
        variant='contained'
        onClick={() => {
          onConfirmed();
        }}
      >
        Go to Home
      </Button>
      <Button
        sx={{ width: '100%', marginTop: '24px' }}
        variant='outlined'
        onClick={() => {
          setShowCloseModal();
        }}
      >
        Keep Going
      </Button>
    </Modal>
  );
}