import { apiSlice } from './apiSlice';

export type AvailableConfigItems =
  | 'auth-mode'
  | 'social-google'
  | 'social-twitter'
  | 'social-facebook'
  | 'social-instagram';

export type Config = Record<AvailableConfigItems, number>;

export enum AuthModes {
  CLOSE = 1,
  OPEN = 2,
}

export const configurationUserApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAuthConfig: builder.query<Config, void>({
      query: () => ({
        url: 'client/configuration/auth',
        method: 'GET',
      }),
      keepUnusedDataFor: 60 * 60 * 24,
    }),
  }),
});

export const { useGetAuthConfigQuery } = configurationUserApi;

