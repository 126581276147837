import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Link as MuiLink,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Container, ContentWrapper } from '../components';
import {
  CONST_TOKEN_COOKIE,
  getError,
  login,
  loginError,
  saveHelperToken,
  TAuthHelperType,
} from '../redux/actions/userActions';
import { isUser, useGoogleLoginMutation } from '../redux/api/authApiSlice';
import { AuthModes, useGetAuthConfigQuery } from '../redux/api/configurationSlice';
import { useAppSelector } from '../redux/hooks';
import { extractError } from '../shared/extractors';

export default function SignIn() {
  const token = Cookies.get(CONST_TOKEN_COOKIE);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [googleLogin] = useGoogleLoginMutation();
  const {
    data: {
      'auth-mode': authMode,
      'social-google': googleEnabled,
      'social-twitter': twitterEnabled,
      'social-facebook': facebookEnabled,
      'social-instagram': instagramEnabled,
    } = {
      'auth-mode': AuthModes.CLOSE,
      'social-google': 0,
      'social-twitter': 0,
      'social-facebook': 0,
      'social-instagram': 0,
    },
  } = useGetAuthConfigQuery();
  const error = useAppSelector(getError);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const googleLoginHandler = useGoogleLogin({
    flow: 'implicit',
    onSuccess: async (tokenResponse) => {
      try {
        const user = await googleLogin(tokenResponse.access_token).unwrap();
        if (isUser(user)) {
          dispatch(login(user));
          navigate('/home');
        }
        else {
          dispatch(
            saveHelperToken({
              helperToken: tokenResponse.access_token,
              helperType: 'google',
            }),
          );
          navigate('/login/middleware');
        }
      } catch (e) {
        dispatch(loginError({ error: extractError(e) }));
      }
    },
  });

  const socialLoginHandle = (helper: TAuthHelperType) => {
    try {
      switch (helper) {
        case 'twitter':
          window.open(
            `${process.env.REACT_APP_BACKEND_URL!}/client-auth/twitter`,
            '_self',
          );
          break;
        case 'instagram':
        case 'facebook':
          window.open(
            `${process.env.REACT_APP_BACKEND_URL!}/client-auth/social-redirect/${helper}`,
            '_self',
          );
          break;
      }
    } catch (error) {
      console.error('Twitter login error:', error);
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(login({ accessToken: token }));
      navigate('/home');
    }
  }, [token, dispatch, navigate]);

  return (
    <Container>
      <ContentWrapper>
        <Box
          sx={{
            marginBottom: '32px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img src='/logo.png' alt='Logo' height={isDesktop ? '96px' : '66px'}
               width={isDesktop ? '96px' : '66px'} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '30px'
          }}
        >
          <Typography component='h1' variant='h1'>
            Welcome To Rose
          </Typography>
          <Typography component='p' variant='body1' textAlign={'center'}>
            To make login easier in the future, or if you are already connected with your
            Google account, select
            "Continue with Google."
          </Typography>
          <Typography component='p' variant='body1' textAlign={'center'}>
            We only store a unique identifier associated with your Google Account for
            verification. All of the personal information that you have provided to Google
            stays only with Google, and Google does not have access to your use of this
            application.
          </Typography>
          {googleEnabled === 1 && <Button
            type='button'
            sx={{ width: isDesktop ? '386px' : '100%' }}
            variant='contained'
            color='primary'
            startIcon={<img style={{ height: '1.5rem', margin: '-.75rem 0' }}
                            src='/google.png' alt='Google' />}
            onClick={() => googleLoginHandler()}
          >
            Continue with Google
          </Button>}
          {twitterEnabled === 1 && <Button
            sx={{ width: isDesktop ? '386px' : '100%' }}
            type='button'
            variant='contained'
            color='primary'
            startIcon={<img style={{ height: '1.5rem', margin: '-.75rem 0' }}
                            src='/twitter_icon.svg' alt='Twitter' />}
            onClick={() => socialLoginHandle('twitter')}
          >
            Continue with Twitter
          </Button>}
          {facebookEnabled === 1 && <Button
            sx={{ width: isDesktop ? '386px' : '100%' }}
            type='button'
            variant='contained'
            color='primary'
            startIcon={
              <img style={{ height: '1.5rem', margin: '-.75rem 0' }}
                   src='/facebook_logo.svg' alt='Facebook' />
            }
            onClick={() => socialLoginHandle('facebook')}
          >
            Continue with Facebook
          </Button>}
          {instagramEnabled === 1 && <Button
            sx={{ width: isDesktop ? '386px' : '100%' }}
            type='button'
            variant='contained'
            color='primary'
            startIcon={
              <img style={{ height: '1.5rem', margin: '-.75rem 0' }}
                   src='/instagram_icon.svg' alt='Instagram' />
            }
            onClick={() => socialLoginHandle('instagram')}
          >
            Continue with Instagram
          </Button>}
          {error && <FormHelperText>{error}</FormHelperText>}
          <Divider
            flexItem
            role='presentation'
            sx={{
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: '16px',
              color: '#6A6C7E',
            }}
          >
            or
          </Divider>
          <Typography component='span' variant='body1' textAlign={'center'}>
            If you choose not to authenticate with Google, you can use your access code to
            log in each time by clicking
            "Access Code."
          </Typography>
          <Button
            sx={{
              width: isDesktop ? '386px' : '100%',
              background: '#fff',
            }}
            variant='outlined'
            component={Link}
            to='/login/code'
          >
            Access Code
          </Button>
          {authMode === AuthModes.OPEN &&
            <>
              <Divider
                flexItem
                role='presentation'
                sx={{
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: '16px',
                  color: '#6A6C7E',
                }}
              >
                or
              </Divider>
              <Typography component='span' variant='body1' textAlign={'center'}>
                If you wish to use your email for authorization, click "Sign In by
                Email" or "Create an Account" button for create new account.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '30px',
                }}>
                <Button
                  sx={{
                    width: isDesktop ? '386px' : '100%',
                    background: '#fff',
                  }}
                  variant='outlined'
                  component={Link}
                  to='/login/email'
                >
                  Sign In by Email
                </Button>
                <Button
                  sx={{
                    width: isDesktop ? '386px' : '100%',
                    background: '#fff',
                  }}
                  variant='outlined'
                  component={Link}
                  to='/register/email'
                >
                  Create an Account
                </Button>
              </Box>
            </>
          }
          <Typography component='span' variant='body2' textAlign={'center'}>
            By using any Sign In method here you agree to our <MuiLink
            onClick={() => {
              navigate(`/privacy-policy`);
            }}
            sx={{cursor:'pointer'}}
            >Privacy Policy</MuiLink>.
          </Typography>
        </Box>
      </ContentWrapper>
    </Container>
  );
}

