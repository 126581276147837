import {
  Button,
  Grid,
  styled, SvgIcon,
  Theme,
  Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ReactComponent as TwitterIcon } from '../../svg/twitter_icon.svg';
import { ReactNode } from 'react';
import { TCustomTheme } from '../../theme';

export type TAuthMethod = 'facebook' | 'google' | 'twitter' | 'instagram';

export type TAuthMethodTileProps = {
  icon: TAuthMethod;
  label: string;
  onDisconnect: () => void;
}

const isonsLibrary: Record<TAuthMethod, ReactNode> = {
  twitter: <SvgIcon
    component={TwitterIcon}
    inheritViewBox
    color='primary'
    sx={{
      height: '20px',
      width: '20px',
    }}
  />,
  google: <GoogleIcon color='primary' />,
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
};

const SettingTileRoot = styled(Grid, {
  name: 'AuthMethodTile',
  slot: 'root',
})(({ theme }: { theme: TCustomTheme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  padding: '16px',
  border: `1px solid ${theme.palette.settingsTile.main}`,
  background: 'transparent',
  borderRadius: theme.shape.borderRadius,//'6px'
}));

export default function AuthMethodTile({
  label,
  onDisconnect,
  icon,
}: TAuthMethodTileProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const theme = useTheme() as TCustomTheme;

  return <SettingTileRoot
    theme={theme}
    item
    sx={{
      maxWidth: isDesktop ? '328px' : '100%',
      gap: '8px'
    }}>
    <Grid item>
      {isonsLibrary[icon]}
    </Grid>
    <Grid item container alignItems='center'>
      <Typography>
        {label}
      </Typography>
    </Grid>
    <Grid item>
      <Button variant='outlined' onClick={onDisconnect}>
        Disconnect
      </Button>
    </Grid>
  </SettingTileRoot>;
}