import * as React from 'react';
import { useState } from 'react';
import { useSelectFavoriteMutation } from '../../redux/api/favoritesApiSlice';
import SelectDialog from './SelectDialog';
import PulsatingButton from "../../components/PulsatingButton";

export type TSelectForFreeTierButtonProps = {
  favoriteId: string;
  isPulsating?: boolean;
}

export default function SelectForFreeTierButton({
  favoriteId,isPulsating
}: TSelectForFreeTierButtonProps) {
  const [tryToSelect, setTryToSelect] = useState(false);
  const [selectFavorite] = useSelectFavoriteMutation();

  return <>
    <PulsatingButton
      onClick={(e) => {
        e.stopPropagation();
        setTryToSelect(true)
      }}
      size='small'
      isPulsating={isPulsating || false}
    >
      Select
    </PulsatingButton>
    <SelectDialog
      open={tryToSelect}
      onConfirm={(e) => {
        e.stopPropagation();
        selectFavorite(favoriteId);
        setTryToSelect(false);
      }}
      onClose={(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {e.stopPropagation(); setTryToSelect(false)}}
    />
  </>;
}
