import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, ContentWrapper, Title } from '../../components';
import NavBar from '../../components/NavBar';


export default function StripeRejectPage() {
  const navigate = useNavigate();

  return <Container>
    <NavBar />
    <Title
      title='Subscription'
      onBack={() => navigate('/settings')}
      backButton
    />
    <ContentWrapper>
      <Grid container direction="column" gap="12px" alignItems="center" textAlign="center">
        <Grid item>
          <Typography>
            Unfortunately, we were unable to process the payment, please try again.
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={ () => navigate('/home') }>
            Home
          </Button>
        </Grid>
      </Grid>
    </ContentWrapper>
  </Container>;
}
