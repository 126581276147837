import React, { useState } from 'react';
import {TextField as MUITextField, Theme, useMediaQuery } from '@mui/material';
import { IQuestion } from '../../../redux/api/surveysApiSlice';
import { TSurveyAnswer } from '../types';

type TNumberInputFieldProps = {
  onChange: (result: string) => void;
  item: IQuestion;
  data?: TSurveyAnswer;
};

export default function NumberInputField({
  data: { result: defaultValue } = { result: '', id: '' },
  item,
  onChange,
}: TNumberInputFieldProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const res = isNaN(+event.target.value) ? '' : event.target.value;
    setValue(res);
    onChange(res);
  };

  return (
      <MUITextField
        value={value}
        placeholder={item.placeholder || ''}
        multiline
        onChange={handleChange}
        sx={{ width: isDesktop ? '50%' : '100%' }}
        type="number"
      />
  );
}

