import { getToken } from '../redux/actions/userActions';
import { store } from '../redux/store';

export type TUseGetFilePathOptions = {
  skip?: boolean;
}

export default function useGetFilePath(
  url: string,
  options?: TUseGetFilePathOptions
) {
  if (options?.skip) {
    return undefined;
  }
  const token = getToken(store.getState());
  return `${process.env.REACT_APP_BACKEND_URL}/${url}?token=${encodeURIComponent(`Bearer ${token}`)}`;
}