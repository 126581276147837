import styled from '@emotion/styled';
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  Theme,
  SxProps,
  Grid,
} from '@mui/material';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getFontSize } from '../theme';

const TypographyStyle = styled(Typography)`
  color: #fff;
  padding: 11.5px 0;
  margin-bottom: 0;
`;

const RightAction = styled(Button)`
  font-size: ${getFontSize(-2)};
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`;

interface IProps {
  title: string;
  backButton?: boolean;
  onBack?: () => void;
  rightAction?: {
    label: string;
    onClick: (...params: any) => any;
    disabled?: boolean;
  };
  sx?: SxProps<Theme>;
}

export const Title = ({ title, backButton, onBack, rightAction, ...rest }: IProps) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        ...rest.sx,
        ...(isDesktop && { marginTop: '32px' }),
        background: 'rgba(106, 108, 126,0.45)',
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        margin: '24px 0',
        justifyContent: 'space-between',
      }}
    >
      {backButton ? (
        <ArrowBackIosNew
          sx={{ cursor: 'pointer', color: '#fff' }}
          onClick={() => {
            if (onBack) {
              onBack();
            }
            else {
              navigate(-1);
            }
          }}
        />
      ) : null}
      <TypographyStyle variant='h2' gutterBottom>
        {title}
      </TypographyStyle>
      {rightAction
        ? <RightAction variant='text' disabled={rightAction.disabled || false}
                       onClick={rightAction.onClick}>{rightAction.label}</RightAction>
        : <Grid item />}
    </Box>
  );
};

