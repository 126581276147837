import { PaletteMode, Theme as MuiTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

export const DEFAULT_FONT_SIZE = 18;
export const calculateFontSize = (shift = 0) => DEFAULT_FONT_SIZE + shift;
export const getFontSize = (shift = 0) => {
  return `${calculateFontSize(shift)}px`;
};
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    s: true;
  }
}

export type TCustomTheme = MuiTheme & {
  palette: {
    contentBackground: {
      main: string;
    };
    navLink: {
      main: string;
      dark: string;
    };
    playerList: {
      main: string;
      dark: string;
    };
    settingsTile: {
      main: string;
      light: string;
    };
  },
  components: {
    textField: {
      input: object,
      label: object
    }
  };
};

const baseTheme = {
  breakpoints: {
    values: {
      xs: 0,
      s: 500,
      sm: 770,
      md: 1032,
      lg: 1200,
      xl: 5000,
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: 'Museo Sans',
    fontSize: calculateFontSize(),
    h1: {
      fontWeight: 700,
      fontSize: getFontSize(8),
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
    h2: {
      fontWeight: 700,
      fontSize: getFontSize(4),
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
    h3: {
      fontWeight: 700,
      fontSize: getFontSize(),
      fontStyle: 'normal',
      lineHeight: '20px',
    },
    h4: {
      fontWeight: 700,
      fontSize: getFontSize(-4),
      fontStyle: 'normal',
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    h6: {
      fontWeight: 700,
      fontSize: getFontSize(2),
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
    body1: {
      fontWeight: 500,
      fontSize: getFontSize(),
      fontStyle: 'normal',
      lineHeight: '22px',
    },
    body2: {
      fontWeight: 500,
      fontSize: getFontSize(-2),
      fontStyle: 'normal',
      lineHeight: '18px',
    },
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: '20px'
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: getFontSize(-4),
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          marginTop: '12px',
          '& .MuiSvgIcon-root': { width: '12px', height: '12px', color: '#FFFFFF' },
          '& .breadcrumb': {
            color: '#FFF',
          },
          '& .inActiveBreadcrumb': {
            cursor: 'default',
            fontSize: getFontSize(-4),
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        sx: {
          mt: 3,
          mb: 2,
          pt: 1,
          pb: 1,
          margin: 0,
        },
      },
      styleOverrides: {
        root: {
          fontSize: getFontSize(-2),
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '16px',
          margin: 0,
          paddingTop: '16px',
          paddingBottom: '16px',
          '&.Mui-disabled': {
            color: '#AAABBA',
            backgroundColor: '#DFDFE5',
            '& .MuiSvgIcon-root': {
              color: '#DFDFE5',
            },
            '&:hover': {
              color: '#AAABBA',
              backgroundColor: '#DFDFE5',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: 'transparent',
        },
        popper: {
          marginTop: '15px !important',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&.Timer': {
            borderRadius: '22px',
            padding: '8px 10px',
            justifyContent: 'space-between',
          },
          '&.PlayerTimer': {
            width: 'fit-content',
            borderRadius: '22px',
            padding: '10px 16px',
            justifyContent: 'space-between',
          },
          '& .infoIcon': { width: '20px', height: '20px' },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          alignItems: 'flex-start',
        },
        paper: {
          borderRadius: '6px',
          padding: '30px 24px',
          marginTop: '72px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5',
          border: '1px solid #ccc',
          padding: '8px',
          borderRadius: '4px',
          fontSize: '14px',
          color: '#333',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
          display: 'inline-block',
          position: 'relative',
          '&:before': {
            position: 'absolute',
            content: '""',
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: '8px solid #f5f5f5',
            top: '-8px',
            left: '5%',
            transform: 'translateX(-5%)',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        filledSuccess: {
          color: '#46485B',
          background: '#CBF2E9',
        },
        filledError: {
          color: '#46485B',
          background: '#F0D8E7',
        },
        filledInfo: {
          color: '#46485B',
          background: '#D4F1F9',
        },
        filledWarning: {
          color: '#46485B',
          background: '#F6E9B9',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPagination-ul': {
            '& li:first-of-type, li:last-of-type': {
              border: '1px solid ',
              borderRadius: '5px',
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              '& .MuiSvgIcon-root': {
                color: '#46485B',
              },
              '&.Mui-disabled': {
                color: '#AAABBA',
                backgroundColor: '#DFDFE5',
                '& .MuiSvgIcon-root': {
                  color: '#DFDFE5',
                },
              },
            },
          },
        },
      },
    },
  },
};
const lightTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#416873',
      light: '#416873',
      dark: '#3c5f69',
    },
    text: {
      primary: '#46485B',
    },
    contentBackground: { main: 'rgba(244, 245, 248, 0.9)' },
    navLink: { main: '#6A6C7E', dark: '#416873' },
    playerList: { main: '#AAABBA', dark: '#6A6C7E' },
    settingsTile: { main: '#AAABBA', light: '#DFDFE5' },
    error: {
      main: '#9e768f',
      dark: '#513547',
    },
  },
  typography: {
    body1: { color: '#6A6C7E' },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid white',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: 'none',
          '&.Mui-selected': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.drawerOpener': {
            color: '#46485B',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& .inActiveBreadcrumb': {
            color: '#DFDFE5',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        outlinedPrimary: {
          background: '#FFF',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          background: '#FFF',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          border: `1px solid #416873`,
          background: '#FFF',
          '&:hover': {
            background: '#D6F0F8',
          },
        },
        containedPrimary: {
          background: '#416873',
          borderRadius: '4px',
          border: '1px solid #416873',
          color: '#FFFFFF',
          '&:hover': {
            border: '1px solid #416873',
            background: '#FFF',
            color: '#416873',
          },
        },
        textPrimary: {
          background: 'transparent',
          color: '#416873',
          '&.Mui-disabled': {
            background: 'transparent',
            color: '#AAABBA',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          boxShadow: '0px 4px 4px 0px rgba(66, 65, 75, 0.08)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.gradeDescription': {
            color: '#6A6C7E',
            overflowWrap: 'anywhere',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPagination-ul': {
            '& li:first-of-type, li:last-of-type': {
              border: '1px solid #DFDFE5',
              background: '#F4F5F8',
            },
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&.Timer': {
            background: '#F4F5F8',
          },
          '&.PlayerTimer': {
            background: '#F4F5F8',
          },
          '& .infoIcon': {
            color: '#6A6C7E',
          },
        },
      },
    },
    textField: {
      input: {},
      label: {},
    },
  },
};

const darkTheme = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#9E768F',
      light: '#9E768F',
      dark: '#8a677d',
    },
    text: {
      primary: '#FFFFFF',
    },
    contentBackground: { main: 'rgba(70, 72, 91,0.9)' },
    navLink: { main: '#AAABBA', dark: '#FFF' },
    playerList: { main: '#6A6C7E', dark: '#AAABBA' },
    settingsTile: { main: '#AAABBA', light: '#6A6C7E' },
    error: {
      main: '#9e768f',
      dark: '#513547',
    },
  },

  typography: {
    body1: { color: '#DFDFE5' },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #FFF',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: 'none',
          '&.Mui-selected': {
            backgroundColor: '#FFF',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& .inActiveBreadcrumb': {
            color: '#DFDFE5',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#FFF',
          color: '#9E768F',
        },
        input: {
          color: '#9E768F',
        },
        '.label': {
          color: '#6A6C7E',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          border: `1px solid #9E768F`,
          color: '#9E768F',
          background: '#FFF',
          '&:hover': {
            background: '#E5D3DF',
          },
        },
        containedPrimary: {
          background: '#9E768F',
          borderRadius: '4px',
          color: '#FFFFFF',
          '&:hover': {
            background: '#E5D3DF',
          },
        },
        textPrimary: {
          background: 'transparent',
          color: '#9E768F',
          '&.Mui-disabled': {
            background: 'transparent',
            color: '#6A6C7E',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '&.MuiPagination-ul': {
            '&li:first-child': {
              border: '1px solid #DFDFE5',
              background: '#9E768F',
              borderRadius: '5px',
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'rgba(70, 72, 91, 0.70)',
          boxShadow: '0px 4px 4px 0px rgba(66, 65, 75, 0.08)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.gradeDescription': {
            color: '#AAABBA',
            overflowWrap: 'anywhere',
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&.Timer': {
            background: '#46485B',
          },
          '&.PlayerTimer': {
            background: '#46485B',
          },
          '& .infoIcon': {
            color: '#DFDFE5',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': { color: '#9E768F' },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#9E768F',
          backgroundColor: 'white',
          padding: '0 10px',
          borderRadius: '5px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            color: '#9E768F',
          },
          label: {
            color: '#9E768F',
            backgroundColor: 'white',
            padding: '0 10px',
            borderRadius: '5px',
          },
        },
      },
    },
  },
};

export const getDesignTokens = (mode: PaletteMode) =>
  mode === 'light' ? deepmerge(baseTheme, lightTheme) : deepmerge(baseTheme, darkTheme);
