import * as React from 'react';
import { Button, Grid } from '@mui/material';
import StepperBody from './StepperBody';

interface IProps {
  activeStep: number;
  stepsCount: number;
  onNextStep: () => void;
  onPrevStep: () => void;
  nextButtonTitle: string;
  prevButtonTitle: string;
  showPrev?: boolean;
  showSteps?: boolean;
  blockNext?: boolean;
  hideStepperButtons?: boolean;
}

export const Stepper = ({
  activeStep,
  stepsCount,
  onNextStep,
  onPrevStep,
  nextButtonTitle,
  prevButtonTitle,
  showPrev = true,
  showSteps = true,
  blockNext,
  hideStepperButtons,
}: IProps) => {
  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      flexDirection='row-reverse'
      gap='24px 16px'
      marginTop='8px'
    >
      <Grid item width='50%' container flexWrap='nowrap'
            justifyContent='space-between' flexGrow={1} gap='6px'>
        {!hideStepperButtons && (
          <Grid item width='calc(50% - 24px)' container flexWrap='nowrap'
                justifyContent='space-between' flexGrow={1} gap='6px'>
            <Grid item />
            {showPrev && <Button variant='outlined' onClick={onPrevStep}
                                 sx={{ whiteSpace: 'nowrap' }}>
              {prevButtonTitle}
            </Button>}
            <Button variant='contained' disabled={blockNext} onClick={onNextStep}
                    sx={{ whiteSpace: 'nowrap' }}>
              {nextButtonTitle}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item width='calc(50% - 24px)' minWidth='250px' flexGrow={1}>
        {showSteps && <StepperBody stepsCount={stepsCount} activeStep={activeStep} />}
      </Grid>
    </Grid>
  );
};

