import {
  Container as MuiContainer,
  Theme,
  SxProps,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';

type TContainerProps = {
  children: React.ReactNode;
  sx?: SxProps;
  logo?: boolean;
  shadow?: boolean;
}

const getBackground = (theme: string, isDesktop: boolean) =>
  `url("/background-${isDesktop ? 'desktop' : 'mobile'}-${theme}.png")`;

export const Container = ({ children, shadow = true, ...rest }: TContainerProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('sm');
  });
  const themeMode = useTheme().palette.mode;

  return (
    <MuiContainer
      component='main'
      maxWidth={isDesktop ? 'xl' : 'sm'}
      disableGutters
      sx={{
        backgroundImage: getBackground(themeMode, isDesktop),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        ...rest.sx,
      }}
    >
      <Box
        className='contentWrapper'
        sx={{
          paddingTop: '72px !important',
          minHeight: '100dvh',
        paddingLeft: isDesktop ? '120px' : '16px',
        paddingRight: isDesktop ? '120px' : '16px',
        background: shadow ? 'linear-gradient(180deg, rgba(70, 72, 91, 0.4) 0%,' +
          ' rgba(70, 72, 91, 0) 100%)' : 'none',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {children}
      </Box>
    </MuiContainer>
  );
};

