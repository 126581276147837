import { z } from 'zod';
import { apiSlice } from './apiSlice';

export interface IGeneratePathResponse {
  path: string;
}

export interface IGetPathResponse {
  hash: string;
  cue: {
    _id: string;
    name: string;
    voice: string;
    duration: string;
    file: string;
    audioLength: number;
    type: string;
  };
  opening: {
    _id: string;
    name: string;
    voice: string;
    duration: string;
    file: string;
    audioLength: number;
    type: string;
  };
  experience: {
    _id: string;
    name: string;
    voice: string;
    duration: string;
    file: string;
    audioLength: number;
    type: string;
  };
  reminder: {
    _id: string;
    name: string;
    voice: string;
    duration: string;
    file: string;
    audioLength: number;
    type: string;
  };
  voice: {
    _id: string;
    name: string;
  };
  duration: {
    _id: string;
    name: string;
  };
  audioLength: number;
  goal: {
    _id: string;
    name: string;
    description: string;
    parent?: string;
    createdAt: string;
    updatedAt: string;
  };
}

const pathSchema = z.object({
  path: z.string(),
});

export const pathApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPath: builder.query<IGetPathResponse, string>({
      query: (hash) => ({
        url: `path/${hash}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
    generatePath: builder.mutation<
      IGeneratePathResponse,
      {
        goalId: string;
        duration: string;
      }
    >({
      query: (data) => ({
        url: `path`,
        method: 'POST',
        body: data,
      }),
      transformResponse(response) {
        return pathSchema.parse(response);
      },
    }),
    makeOwnPath: builder.mutation<
      IGeneratePathResponse,
      {
        goalId: string;
        cueId: string;
        openingId: string;
        experienceId: string;
        reminderId: string;
      }
    >({
      query: (data) => ({
        url: `path/build`,
        method: 'POST',
        body: data,
      }),
      transformResponse(response) {
        return pathSchema.parse(response);
      },
    }),
  }),
});

export const {
  useGeneratePathMutation,
  useGetPathQuery,
  useMakeOwnPathMutation,
} = pathApi;

