import HTMLReactParser from 'html-react-parser';
import React from 'react';
import { Link, Typography } from '@mui/material';

export default function QuillHtmlRenderer({ html }: { html: string }) {
  return (
    <>
      {HTMLReactParser(html, {
        trim: true,
        library: {
          cloneElement: React.cloneElement,
          createElement: (type: any, props?: object, ...children: any): JSX.Element => {
            switch (type) {
              case 'br':
                return <></>;
              case 'h1':
              case 'h2':
              case 'h3':
              case 'h4':
              case 'h5':
                return (
                  <Typography
                    component='div'
                    marginBottom='16px'
                    variant={type} {...props}
                  >
                    {children}
                  </Typography>
                );
              case 'p':
                return (
                  <Typography
                    variant='body1'
                    component='div'
                    marginBottom='16px' {...props}
                  >
                    {children}
                  </Typography>
                );
              case 'a':
                return <Link {...props}>{children}</Link>;
              case 'em':
              case 'strong':
              case 'li':
              case 'ol':
              case 'ul':
              default:
                return React.createElement(type, props, children);
            }
          },
          isValidElement: React.isValidElement,
        },
      })}
    </>
  );
}

