import {
  Button,
  Grid,
  Radio,
  styled,
  Theme,
  Typography,
  useMediaQuery, useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { TCustomTheme } from '../../theme';

export type TSettingTileProps = {
  label: string;
  tryButtonLabel: string | ReactNode;
  selected: boolean;
  onSelect: () => void;
  onTry: () => void;
}

const SettingTileRoot = styled(Grid, {
  name: 'SettingsTile',
  slot: 'root'
})(({theme, selected}: {theme: TCustomTheme, selected: boolean}) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  padding: '16px',
  border: `1px solid ${theme.palette.settingsTile.main}`,
  background: selected ? theme.palette.settingsTile.light : 'transparent',
  borderRadius: theme.shape.borderRadius//'6px'
}));

export default function SettingTile({
  label,
  tryButtonLabel,
  selected,
  onSelect,
  onTry,
}: TSettingTileProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const theme = useTheme() as TCustomTheme;

  return <SettingTileRoot
    selected={selected}
    theme={theme}
    item
    sx={{
      maxWidth: isDesktop ? '328px' : '100%',
    }}>
    <Radio checked={selected} onChange={onSelect} />
    <Typography>
      {label}
    </Typography>
    <Grid item flexGrow={1} />
    <Button variant='outlined' onClick={onTry}>
      {tryButtonLabel}
    </Button>
  </SettingTileRoot>;
}