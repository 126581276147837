import * as React from 'react';
import {useState} from 'react';
import {Breadcrumbs, Container, ContentWrapper, Title} from '../components';
import {Alert, Stack, TextField, Theme, useMediaQuery,} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useRequestForgotPasswordMutation} from '../redux/api/userSlice';
import {extractError} from '../shared/extractors';
import {useValidate} from '../shared/useValidate';
import {forgotPasswordSchema, TForgotPasswordData,} from './schemas';

export default function RequestForgotPassword() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [data, setData] = useState<TForgotPasswordData>({
    email: '',
  });
  const [serverError, setServerError] = useState<string | undefined>();
  const [serverMessage, setServerMessage] = useState<string | undefined>();

  const {
    validate,
    clearOneError,
    getErrorProps,
  } = useValidate({scheme: forgotPasswordSchema});

  function updateData<Key extends keyof TForgotPasswordData>(
    field: Key,
    value: TForgotPasswordData[Key],
  ) {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    clearOneError(field);
    setServerError('');
  }

  const [requestForgotPassword] = useRequestForgotPasswordMutation();

  const handleSubmit = async () => {
    if (!validate(data)) {
      return;
    }
    try {
      const message = await requestForgotPassword(data).unwrap();
      setServerMessage(message.message);
      setServerError('');
    } catch (e) {
      setServerError(extractError(e));
    }
  };

  const getContent = () => {
    if (serverMessage) {
      return <>
        <Alert severity='info'>{serverMessage}</Alert>
        <Button
          href={`https://${data.email.split('@')[1]}`}
          variant='contained'
        >
          Check email
        </Button>
      </>;
    }
    return <>
      <Typography component='p' variant='body1' align='center'>
        If you can't remember your password, we can send you an email with password
        recovery instructions.
      </Typography>
      <Stack
        component='div'
        sx={{
          alignItems: 'stretch',
          flexDirection: 'column',
          width: isDesktop ? '351px' : '100%',
        }}
        gap='16px'
      >
        <TextField
          required
          autoFocus
          value={data.email}
          onChange={ev => updateData('email', ev.target.value)}
          name='code'
          label='Email'
          {...getErrorProps('email')}
        />

        {serverError && <Alert severity='error'>{serverError}</Alert>}
        <Button type='button' sx={{}} disabled={data.email === ''} variant='contained'
                onClick={handleSubmit}>
          Request reset password
        </Button>
      </Stack>
    </>;
  };

  return (
    <Container>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src='/logo.png' alt='Logo' height='64px'
             width='64px'/>
      </Box>
      <Title title='Forgot password?' backButton/>
      <Breadcrumbs firstTitle={'Login'}
                   breadcrumbs={[{label: 'Forgot password', route: ''}]}/>
      <ContentWrapper sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
      }}>
        {getContent()}
      </ContentWrapper>
    </Container>
  );
}

