import { apiSlice } from './apiSlice';

export interface Duration {
  _id: string;
  name: string;
}

export const durationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDurations: builder.query<Duration[], void>({
      query: () => ({
        url: 'client/durations',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetDurationsQuery } = durationsApi;

