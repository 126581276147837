import * as React from 'react';
import { Theme, Typography, Box, Divider, useMediaQuery, styled, SvgIcon } from '@mui/material';
import { ReactComponent as VolumeIcon } from '../../svg/volume-high.svg';

const List = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '10px',
});

interface IList {
  data: { _id: string; name: string; isActive?: boolean }[];
  noMark: boolean;
}

export const BasicList = ({ data, noMark = false }: IList) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const activeElementIndex = data.findIndex((item) => item.isActive);
  return (
    <>
      <Box sx={{ width: '100%', ...(isDesktop && { display: 'flex', gap: '40px' }) }}>
        {data.map((item, i) => (
          <List key={item._id}>
            {item.isActive && !noMark && (
              <SvgIcon
                component={VolumeIcon}
                inheritViewBox
                sx={(props: any) => {
                  return {
                    stroke: props.palette.primary.main,
                    color: props.palette.contentBackground.main,
                    width: '16px',
                    height: '16px',
                    marginRight: '8px',
                  };
                }}
              />
            )}
            <Typography
              align='center'
              variant={item.isActive ? 'h3' : 'body1'}
              sx={(props: any) => {
                return {
                  ...(!item.isActive && {
                    color: i < activeElementIndex ? props.palette.playerList.main : props.palette.playerList.dark,
                  }),
                };
              }}
            >
              {item.name}
            </Typography>
          </List>
        ))}
      </Box>
      <Divider sx={{ marginTop: '20px' }} />
    </>
  );
};

