import { apiSlice } from './apiSlice';

export interface Page {
  _id: string;
  title: string;
  text: string;
}

export const pagesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPages: builder.query<Page[], void>({
      query: () => ({
        url: 'client/pages',
        method: 'GET',
      }),
    }),
    getPageById: builder.query<Page, string>({
      query: (id) => ({
        url: `client/pages/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetPagesQuery,useGetPageByIdQuery } = pagesApi;

