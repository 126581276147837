import { Grid } from '@mui/material';
import { GridProps } from '@mui/material/Grid/Grid';

export default function CardsContainer (props: GridProps) {
  const {
    container = true,
    sx,
  } = props;
  return <Grid
    container={container}
    sx={{
      justifyContent: 'stretch',
      justifyItems: 'stretch',
      alignItems: 'stretch',
      gap: '24px 16px',
      ...sx
    }}
    {...props}
  />;
}