import { useParams } from 'react-router-dom';
import QuillHtmlRenderer from '../../components/QuillHtmlRenderer';
import { Container, ContentWrapper, Title } from '../../components';
import NavBar from '../../components/NavBar';
import { useGetPagesQuery } from '../../redux/api/pagesApiSlice';
import { NotFound } from '../NotFoundPage';

export const StaticPage = () => {
  const { id } = useParams();
  const { data: pages } = useGetPagesQuery();
  const page = (pages || []).find(p => p._id === id);
  if (!page) {
    return <NotFound />;
  }

  return (
    <Container>
      <NavBar />
      <Title title={page.title} />
      <ContentWrapper>
        <QuillHtmlRenderer html={page.text} />
      </ContentWrapper>
    </Container>
  );
};

