import { z } from 'zod';
import { apiSlice } from './apiSlice';

const userSchema = z.object({
  accessToken: z.string(),
});
const newExternalUserSchema = z.object({
  isNewUser: z.boolean(),
});

export type User = z.infer<typeof userSchema>;
export type NewExternalUser = z.infer<typeof newExternalUserSchema>;
export type DtoExternalRegister = {
  accessToken: string;
  helperType: string;
  accessCode?: string;
  email?: string;
  password?: string;
  type?: string
};

export const isUser = (data: unknown): data is User => userSchema.safeParse(data).success;

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    codeLogin: builder.mutation<User, string>({
      query: (accessCode) => ({
        url: 'client-auth/login',
        method: 'POST',
        body: { accessCode },
      }),
      transformResponse(response) {
        return userSchema.parse(response);
      },
    }),
    emailLogin: builder.mutation<User, { email: string, password: string }>({
      query: (loginData) => ({
        url: 'client-auth/email',
        method: 'POST',
        body: loginData,
      }),
      transformResponse(response) {
        return userSchema.parse(response);
      },
    }),
    googleLogin: builder.mutation<User | NewExternalUser, string>({
      query: (accessToken) => ({
        url: 'client-auth/google-login',
        method: 'POST',
        body: { accessToken },
      }),
      transformResponse(response) {
        if (isUser(response)) {
          return userSchema.parse(response);
        } else {
          return newExternalUserSchema.parse(response);
        }
      },
    }),
    googleRegister: builder.mutation<User, DtoExternalRegister>({
      query: (data) => ({
        url: 'client-auth/google-register',
        method: 'POST',
        body: data,
      }),
      transformResponse(response) {
        return userSchema.parse(response);
      },
    }),
    facebookLogin: builder.mutation<User | NewExternalUser, string>({
      query: (accessToken) => ({
        url: 'client-auth/facebook-login',
        method: 'POST',
        body: { accessToken },
      }),
      transformResponse(response) {
        if (isUser(response)) {
          return userSchema.parse(response);
        } else {
          return newExternalUserSchema.parse(response);
        }
      },
    }),
    facebookRegister: builder.mutation<User, DtoExternalRegister>({
      query: (data) => ({
        url: 'client-auth/facebook-register',
        method: 'POST',
        body: data,
      }),
      transformResponse(response) {
        return userSchema.parse(response);
      },
    }),
    instagramLogin: builder.mutation<User | NewExternalUser, string>({
      query: (accessToken) => ({
        url: 'client-auth/instagram-login',
        method: 'POST',
        body: { accessToken },
      }),
      transformResponse(response) {
        if (isUser(response)) {
          return userSchema.parse(response);
        } else {
          return newExternalUserSchema.parse(response);
        }
      },
    }),
    instagramRegister: builder.mutation<User, DtoExternalRegister>({
      query: (data) => ({
        url: 'client-auth/instagram-register',
        method: 'POST',
        body: data,
      }),
      transformResponse(response) {
        return userSchema.parse(response);
      },
    }),
    twitterLogin: builder.mutation<User | NewExternalUser, string>({
      query: (accessToken) => ({
        url: 'client-auth/twitter-login',
        method: 'POST',
        body: { accessToken },
      }),
      transformResponse(response) {
        if (isUser(response)) {
          return userSchema.parse(response);
        } else {
          return newExternalUserSchema.parse(response);
        }
      },
    }),
    twitterRegister: builder.mutation<User, DtoExternalRegister>({
      query: (data) => ({
        url: 'client-auth/twitter-register',
        method: 'POST',
        body: data,
      }),
      transformResponse(response) {
        return userSchema.parse(response);
      },
    }),
    instagramToken: builder.mutation<{ accessToken: string }, { code: string }>({
      query: (data) => ({
        url: 'client-auth/instagram-token',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useFacebookLoginMutation,
  useFacebookRegisterMutation,
  useInstagramLoginMutation,
  useInstagramRegisterMutation,
  useTwitterLoginMutation,
  useTwitterRegisterMutation,
  useInstagramTokenMutation,
  useCodeLoginMutation,
  useGoogleLoginMutation,
  useGoogleRegisterMutation,
  useEmailLoginMutation
} = authApi;
