import * as React from 'react';
import { Breadcrumbs, Container, ContentWrapper, Title } from '../components';
import {
  FormControl,
  FormHelperText,
  LinearProgress,
  OutlinedInput,
  Stack,
  Theme,
  useMediaQuery,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { IMaskInput } from 'react-imask';
import { useDispatch } from 'react-redux';
import {
  login, getHelperToken, getHelperType, clearToken,
} from '../redux/actions/userActions';
import { useAppSelector } from '../redux/hooks';
import { extractError } from '../shared/extractors';
import { convertCode } from '../shared/convertor';
import useHelperLogin from '../shared/useHelperLogin';
import { useGetSettingsMutation } from '../redux/api/settingsApiSlice';
import useLocalStorageState from 'use-local-storage-state';
import { useGetSurveysMutation, useLoadHooksMutation } from '../redux/api/surveysApiSlice';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='####-####'
      definitions={{
        '#': /[0-9a-z]/i,
      }}
      placeholder='____-____'
      // @ts-ignore
      inputRef={ref}
      onAccept={(value) => onChange({
        target: {
          name: props.name,
          value: convertCode(value),
        },
      })}
      overwrite
    />
  );
});

export default function LoginCode() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [getSettings, {isLoading: areSettingsLoading}] = useGetSettingsMutation();
  const [loadHooks, {isLoading: areHooksLoading}] = useLoadHooksMutation();
  const [getSurvey, {isLoading: areSurveysLoading}] = useGetSurveysMutation();

  const [code, setCode] = useState('');
  const [serverError, setServerError] = useState<string | null>();
  const [, setLocalSettings] = useLocalStorageState('settings')
  const [, setLocalHooks] = useLocalStorageState('hooks')
  const [, setLocalSurveys] = useLocalStorageState('surveys')

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const helperToken = useAppSelector(getHelperToken);
  const helperType = useAppSelector(getHelperType);

  const { register, isLoading: isLoginLoading } = useHelperLogin();

  const isLoading = areSettingsLoading || areHooksLoading || areSurveysLoading || isLoginLoading;

  const handleSubmit = async () => {
    try {
      const user = await register(helperToken, code, helperType || 'code');
      dispatch(login(user));
      dispatch(clearToken());

      const settings = await getSettings().unwrap();
      const hooks = await loadHooks().unwrap();

      if (hooks?.length) {
        const idsSurveys = hooks?.reduce((acc, hook) => {
        if (hook.endSurveyId) {
          acc.push(hook.startSurveyId, hook.endSurveyId);
        }
        else {
          acc.push(hook.startSurveyId);
        }
        return acc;
        }, [] as string[]);

        if (idsSurveys) {
          const surveys = await getSurvey({
            ids: idsSurveys,
          })
            .unwrap();
            setLocalSurveys(surveys);
        }
      }

      setLocalSettings(settings);
      setLocalHooks(hooks);

      navigate('/home');
    } catch (e) {
      setServerError(extractError(e));
    }
  };

  const changeCode = (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCode(ev.target.value);
    setServerError(undefined);
  };

  return (
    <Container sx={{ '.contentWrapper': { paddingTop: '16px !important' } }}>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src='/logo.png' alt='Logo' height={isDesktop ? '64px' : '64px'}
             width={isDesktop ? '64px' : '64px'} />
      </Box>
      <Title title={'Access Code'} backButton />
      <Breadcrumbs firstTitle={'Login'}
                   breadcrumbs={[{ label: 'Access code', route: '' }]} />
      <ContentWrapper
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        <Typography component='p' variant='body1' sx={{ marginBottom: '24px' }}>
          If you choose not to authenticate using one of your social media accounts, you can use your Rose access
          code to log in each time by clicking "Access Code."
        </Typography>
        <Stack
          component='div'
          sx={{
            alignItems: 'stretch',
            flexDirection: 'column',
            width: isDesktop ? '351px' : '100%',
            gap: '24px',
          }}
        >
          <FormControl variant='outlined' error={!!serverError}>
            <Typography variant='body1'>Access Code</Typography>
            <OutlinedInput
              required
              autoFocus
              value={code}
              onChange={changeCode}
              name='code'
              id='formatted-text-mask-input'
              inputComponent={TextMaskCustom as any}
              error={!!serverError}
            />
            {serverError && <FormHelperText>{serverError}</FormHelperText>}
          </FormControl>
          <Button type='button' sx={{}} disabled={!code || isLoading} variant='contained'
                  onClick={handleSubmit}>
            Sign In
            {isLoading && <Box sx={{ width: '100%', position: 'absolute', bottom: '0', }}>
                      <LinearProgress />
            </Box>}
          </Button>
        </Stack>
      </ContentWrapper>
    </Container>
  );
}

