import { StopCircle as StopIcon } from '@mui/icons-material';
import {
  Button,
  Grid,
  Slider,
  styled,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Notifier from '../../components/Notifier';
import { formatDuration } from '../../components/Player/PlayerControls';
import { TChainPartHandle } from '../../features/Surveys/types';
import { WelcomeTrip } from '../../features/WelcomeTrip/WelcomeTrip';
import {
  settingsApi,
  TTheme,
  useLoadSettingsQuery,
  useSaveSettingsMutation,
  useSaveSettingsSilentMutation,
  useUpdateOnboardingSilentMutation,
} from '../../redux/api/settingsApiSlice';
import { useGetVoicesQuery, Voice } from '../../redux/api/voicesApiSlice';
import { useAppDispatch } from '../../redux/hooks';
import { ThemeContext } from '../../shared/ThemeContext';
import useGetFilePath from '../../shared/useGetFilePath';
import { ReactComponent as VoiceIcon } from '../../svg/voice.svg';
import { TCustomTheme } from '../../theme';
import SettingTile from './SettingTile';

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
  textAlign: 'right',
});

export type TSettingsData = {
  voice: string;
  theme: TTheme;
};
type TPlayerState = 'pre' | 'full' | 'stopped';

type TSettingsProps = {
  onlyBody?: boolean;
};

export default React.forwardRef<TChainPartHandle, TSettingsProps>(function SettingsPart(
  { onlyBody = false },
  ref,
) {
  const { data: voices } = useGetVoicesQuery();
  const { data: settings, refetch } = useLoadSettingsQuery();
  const [updateOnboarding] = useUpdateOnboardingSilentMutation();
  const [saveSettings] = useSaveSettingsMutation();
  const [saveSettingsSilent] = useSaveSettingsSilentMutation();
  const theme = useTheme() as TCustomTheme;
  const themeCtx = React.useContext(ThemeContext);
  const [playerState, setPlayerState] = useState<TPlayerState>('stopped');
  const [openNotifier, setOpenNotifier] = useState<boolean>(false);
  const [time, setTime] = useState(0);
  const [currentVoice, setCurrentVoice] = useState<string | null>(null);
  const [isShowWelcomeTrip, setIsShowWelcomeTrip] = useState<boolean>(false);

  const audioRef = useRef<HTMLAudioElement>(null);
  const file = useGetFilePath(
    `client/voices/audio/${currentVoice}/intro`,
    { skip: !currentVoice },
  );
  const dispatch = useAppDispatch();

  const isDesktop = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up('sm');
  });

  useImperativeHandle(ref, () => ({
    nextStep: (step: number) => {
      refetch();
      return step;
    },
    getMessage: () => null
  }));

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    switch (playerState) {
      case 'pre':
        let timeoutId: ReturnType<typeof setTimeout>;
        audioRef.current.currentTime = 0;
        audioRef.current.play().then(() => {
          timeoutId = setTimeout(() => {
            setPlayerState('stopped');
            setCurrentVoice(null);
          }, 5000);
        });
        return () => clearTimeout(timeoutId);
      case 'full':
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        break;
      case 'stopped':
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        break;
    }
  }, [playerState, currentVoice]);

  useEffect(() => {
    return () => {
      themeCtx.selectMode(settings?.theme || 'light');
    };
  }, [settings?.theme]);

  async function updateData<Key extends keyof TSettingsData>(
    field: Key,
    value: TSettingsData[Key],
  ) {
    if (settings && !onlyBody) {
      await saveSettings({
        theme: settings.theme,
        voice: settings.voice,
        [field]: value,
      });
    }else if (settings) {
      await saveSettingsSilent({
      theme: settings.theme,
      voice: settings.voice,
      [field]: value,
    });

    }
    if (!onlyBody) {
      refetch();
    }
    else {
      dispatch(
        settingsApi.util?.updateQueryData('loadSettings', undefined, (prev) => {
          return {
            ...prev,
            [field]: value,
          };
        }),
      );
    }
    setOpenNotifier(true);
  }

  const handlePreListen = async (voice: string) => {
    if (playerState === 'pre' && currentVoice === voice) {
      setPlayerState('stopped');
      setCurrentVoice(null);
    }
    else {
      setCurrentVoice(voice);
      setPlayerState('pre');
    }
  };

  const handleListenIntro = () => {
    if (!settings) {
      return;
    }
    if (playerState === 'full') {
      setPlayerState('stopped');
      setCurrentVoice(null);
    }
    else {
      setCurrentVoice(settings.voice);
      setPlayerState('full');
    }
  };

  const onEnded = async () => {
    setPlayerState('stopped');
    setCurrentVoice(null);
    if (!settings?.onboarding?.introductionCompleted) {
      await updateOnboarding({
        introductionCompleted: true,
      });
      if (!onlyBody) {
        refetch();
      }
    }
  };

  const handleWelcomeTrip = () => {
    setIsShowWelcomeTrip(true);
  };

  const voicesMap = useMemo(() => {
    if (!voices?.length) return {};
    return voices.reduce<Record<string, Voice>>(
      (acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      },
      {},
    );
  }, [voices]);

  const duration = voicesMap[`${currentVoice}`]?.audioLengthIntro ?? 0;

  return (
    <>
      <Grid item container flexDirection='row' gap='24px'>
        <Grid item container flexDirection='column' justifyContent='space-between'
              gap='24px' flexBasis={isDesktop ? '45%' : '100%'} minWidth='257px'>
          <Grid item>
            <Typography>You must listen to Using Rose at least once in its entirety before
              you can have access to paths.
            </Typography>
          </Grid>
          <Grid container flexDirection='row' gap='24px' flexWrap='nowrap'>
            <Grid item>
              <Button variant='outlined' onClick={handleListenIntro}>
                {playerState === 'full' ? (
                  <>
                    <StopIcon />
                    Stop
                  </>
                ) : (
                  <>
                    <SvgIcon
                      component={VoiceIcon}
                      inheritViewBox
                      sx={{
                        stroke: theme.palette.primary.main,
                        fill: 'none',
                      }}
                    />
                    Listen to "Using Rose"
                  </>
                )}
              </Button>
            </Grid>
            <audio
              onEnded={onEnded}
              onTimeUpdate={(e) => {
                setTime((e.target as HTMLAudioElement).currentTime);
              }}
              style={{ display: 'none' }}
              ref={audioRef}
              controls
              src={file}
            />
            {playerState === 'full' && (
              <Grid item flexGrow={1}>
                <Slider
                  aria-label='time-indicator'
                  size='small'
                  value={time}
                  disableSwap
                  min={0}
                  max={duration}
                  sx={{
                    padding: 0,
                    cursor: 'default',
                    height: 4,
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                  }}
                />
                <TinyText variant='body1'>{formatDuration(Math.max(
                  duration - time,
                  0,
                ))}</TinyText>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container flexDirection='column' justifyContent='space-between'
              gap='24px' flexBasis={isDesktop ? '45%' : '100%'} minWidth='257px'>
          <Grid item>
            <Typography>Show Welcome trip</Typography>
          </Grid>
          <Grid item>
            <Button variant='outlined' onClick={handleWelcomeTrip}>
              Welcome Trip
            </Button>
            <WelcomeTrip open={isShowWelcomeTrip} setIsOpen={setIsShowWelcomeTrip} />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Typography>Theme</Typography>
          </Grid>
          <Grid item container gap='16px'>
            <SettingTile
              label='Day'
              tryButtonLabel='Try Theme'
              selected={'light' === settings?.theme}
              onSelect={async () => {
                await updateData('theme', 'light');
              }}
              onTry={() => themeCtx.selectMode('light')}
            />
            <SettingTile
              label='Night'
              tryButtonLabel='Try Theme'
              selected={'dark' === settings?.theme}
              onSelect={async () => {
                await updateData('theme', 'dark');
              }}
              onTry={() => themeCtx.selectMode('dark')}
            />
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item>
            <Typography>Voice</Typography>
          </Grid>
          <Grid item container gap='16px'>
            {(voices || []).map((voice) => (
              <SettingTile
                key={voice._id}
                label={voice.name}
                tryButtonLabel={playerState === 'pre' && currentVoice === voice._id ? (
                  <>
                    <StopIcon />
                    Stop
                  </>
                ) : (
                  <>
                    <SvgIcon
                      component={VoiceIcon}
                      inheritViewBox
                      sx={{
                        stroke: theme.palette.primary.main,
                        fill: 'none',
                      }}
                    />
                    Listen
                  </>
                )}

                selected={voice._id === settings?.voice}
                onSelect={async () => {
                  await updateData('voice', voice._id);
                }}
                onTry={() => handlePreListen(voice._id)}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Notifier
        setOpen={setOpenNotifier}
        open={openNotifier}
        text={'Your changes have been saved!'}
        severity={'success'}
      />
    </>
  );
});