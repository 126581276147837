import {
  DialogActions,
  DialogContent, DialogTitle,
  Grid,
} from '@mui/material';
import Button from '@mui/material/Button';
import SharedDialog, { TSharedDialogProps } from '../../components/SharedDialog';

type TSortDialogProps = TSharedDialogProps & {
  onConfirm: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function SelectDialog(props: TSortDialogProps) {
  const { onConfirm, ...rest } = props;
  const { onClose } = rest;
  return <SharedDialog {...rest}>
    <DialogTitle>Are you sure you'd like to select this path?</DialogTitle>
    <DialogContent>
      You will not be able to revert this action.
    </DialogContent>
    <DialogActions>
      <Grid container justifyContent='space-around'>
        <Button variant='outlined' onClick={(ev) => onClose && onClose(
          ev,
          'escapeKeyDown',
        )}>Cancel</Button>
        <Button variant='contained' onClick={(e) => onConfirm(e)}>
          Select path</Button>
      </Grid>
    </DialogActions>
  </SharedDialog>;
}