import { useNavigate } from 'react-router-dom';
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';

export default function GoodJob() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  return (<>
    <Grid container flexDirection='column' 
          gap='24px'>
    <Typography variant='h2'>Good job!</Typography>
      <Grid item>
        <Typography>We hope you feel better now. You still have time for another path
          today.</Typography>
      </Grid>
      <Grid item>
        <Typography>If you would like another path, click on the "Go to Goal page" button
          below.</Typography>
      </Grid>
      <Grid item>
        <Button sx={{ width: isDesktop ? '239px' : '100%' }} variant='contained'
                onClick={() => navigate('/')}>
          Go to Goals page
        </Button>
      </Grid>
    </Grid>
  </>);
}