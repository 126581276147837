import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Card, Breadcrumbs, Title, IBreadcrumb } from '../../components';
import CardsContainer from '../../components/CardsContainer';
import { useGetAllParentGoalsQuery, useGetChildrenGoalsQuery } from '../../redux/api/goalsApiSlice';
import NavBar from '../../components/NavBar';
import { createPathUri } from '../../shared/factories';

export const GoalsDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { data: currentGoals, isLoading } = useGetChildrenGoalsQuery(id!);
  const { data: parentGoals } = useGetAllParentGoalsQuery(id!);

  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);

  useEffect(() => {
    if (parentGoals) {
      const bc = parentGoals.map((parent) => {
        return { label: parent.name, route: `/goal/${parent._id}` };
      });
      setBreadcrumbs(bc);
    }
  }, [parentGoals]);

  useEffect(() => {
    if (!isLoading && (!currentGoals || !currentGoals?.length) && id) {
      const targetUrl = createPathUri(id, 'pick_for_me');
      navigate(targetUrl);
    }
  }, [isLoading, currentGoals?.length])

  return (
    <Container>
      <NavBar />
      <Title title={breadcrumbs[breadcrumbs.length - 1]?.label} backButton />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <CardsContainer>
        {currentGoals?.map(({ name, description, _id, image }) => (
          <Card
            key={name}
            {...{
              image,
              name,
              description,
              onClick: () => {
                navigate(`/goal/${_id}`);
              },
            }}
          />
        ))}
      </CardsContainer>
    </Container>
  );
};

