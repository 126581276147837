import { Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, ContentWrapper, Title } from '../../components';
import NavBar from '../../components/NavBar';
import { TChainPartHandle } from '../../features/Surveys/types';
import { useLoadSettingsQuery } from '../../redux/api/settingsApiSlice';
import AuthMethodsPart, { getFilteredHelpers } from './AuthMethodsPart';
import SettingsPart from './SettingsPart';
import SubscriptionPart from './SubscriptionPart';

type TSettingsState = 'settings' | 'auth' | 'subscription';
type TSettingsParams = {
  section: 'settings' | 'auth' | 'subscription';
}

export default function SettingsPage() {
  const {section} = useParams<TSettingsParams>();
  const navigate = useNavigate();
  const [state, setState] = useState<TSettingsState>(section || 'settings');
  const settingsRef = useRef<TChainPartHandle>(null);
  const { data: settings } = useLoadSettingsQuery();

  const handleBack = () => {
    navigate(-1);
  };

  const filteredHelpers = getFilteredHelpers(settings?.helpers || {});

  return <Container>
    <NavBar />
    <Title
      title='Settings'
      onBack={() => handleBack()}
      backButton
    />
    <ContentWrapper>
      <Tabs
        variant='scrollable'
        allowScrollButtonsMobile
        value={state}
        onChange={(_, value: TSettingsState) => setState(value)}
      >
        <Tab label='Settings' value='settings' />
        {(settings?.type === 'production' || filteredHelpers.length > 0) &&
          <Tab label='Sign In Methods' value='auth' />}
        {settings?.type === 'production' &&
          <Tab label='Subscriptions' value='subscription' />}
      </Tabs>
      {state === 'settings' && <SettingsPart
        ref={settingsRef}

      />}
      {state === 'auth' && <AuthMethodsPart />}
      {state === 'subscription' && <SubscriptionPart />}
    </ContentWrapper>
  </Container>;
}
