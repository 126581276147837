import { Grid } from '@mui/material';
import { Component } from '../../redux/api/componentsApiSlice';
import { convertSecondsToHMS } from '../../shared/convertor';
import { GroupedComponent } from './GroupedComponent';
import SingleComponent from './SingleComponent';

export type TOwnComponentProps = {
  components: Component[];
  selectedComponent: string;
  onChange: (id: string) => void;
}

export default function OwnComponentsList({
  components,
  selectedComponent,
  onChange,
}: TOwnComponentProps) {
  const groupedComponents = components.reduce((
    all: Record<string, Component[]>,
    component,
  ) => {
    const { name } = component;
    if (!!all[name]) {
      all[name].push(component);
    }
    else {
      all[name] = [component];
    }
    return all;
  }, {});

  function findObjectsWithSameAudioLength(data: Record<string, Component[]>) {
    const audioLengthMap: { [key: string]: Component[] } = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const objectsList = data[key];

        for (const obj of objectsList) {
          const audioLength = convertSecondsToHMS(obj.audioLength, false);
          const key = `${audioLength}${obj.name}`;

          if (key in audioLengthMap) {
            audioLengthMap[key].push(obj);
          }
          else {
            audioLengthMap[key] = [obj];
          }
        }
      }
    }

    const result: { [key: string]: Component[] } = {};
    for (const key in audioLengthMap) {
      if (audioLengthMap.hasOwnProperty(key) && audioLengthMap[key].length > 1) {
        result[key] = audioLengthMap[key];
      }
    }

    return result;
  }

  return <Grid container flexDirection='column' gap='16px'>
    {Object.entries(groupedComponents).map(([name, component]) => (
      component.length === 1
        ? <SingleComponent
          key={component[0]._id}
          component={component[0]}
          selected={component[0]._id === selectedComponent}
          onSelect={() => onChange(component[0]._id)} />
        : <GroupedComponent
          key={component[0]._id}
          title={name}
          type={component[0].type}
          subtitle={component[0].goals.map(g => g.name).join(', ')}
          options={component}
          selected={selectedComponent}
          sameLength={findObjectsWithSameAudioLength(groupedComponents)}
          onChange={onChange} />
    ))}
  </Grid>;
}