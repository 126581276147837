import { Alert, Box } from '@mui/material';
import * as React from 'react';

export const MessageInfo = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Box>
      <Alert
        icon={false}
        variant="filled"
        severity="info"
        sx={{
          '& .MuiAlert-message': {
            width: '100%',
          },
          background: '#D4F1F9',
          borderRadius: '6px',
          maxWidth: '590px',
          boxShadow: '0px 6px 10px 0px rgba(66, 65, 75, 0.15)',
          margin: '20px auto',
        }}
      >
        {children}
      </Alert>
    </Box>
  );
};

