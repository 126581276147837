import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { clearLogout } from '../redux/actions/userActions';
import { useGetDashboardsQuery } from '../redux/api/dashboardsApiSlice';
import { useGetPagesQuery } from '../redux/api/pagesApiSlice';
import { useLoadSettingsQuery } from '../redux/api/settingsApiSlice';
import { useAppDispatch } from '../redux/hooks';
import { isFreeTier, isUnpaidTier } from '../shared/subscriptions';
import { TCustomTheme } from '../theme';
import { Timer } from './Timer';

const drawerWidth = 328;

const NavLinkStyled = styled(NavLink)`
    color: ${({ theme }) => (theme as TCustomTheme).palette.navLink.main};
    margin-left: 36px;
    text-decoration-line: none;
    position: relative;
    white-space: nowrap;

    &:hover {
        color: ${({ theme }) => (theme as TCustomTheme).palette.navLink.dark};

        &:before {
            content: '';
            display: block;
            border-radius: 1px;
            width: 100%;
            height: 2px;
            background: ${({ theme }) => (theme as TCustomTheme).palette.navLink.dark};
            left: 0;
            top: 20px;
            position: absolute;
        }
    }

    &.active {
        color: ${({ theme }) => (theme as TCustomTheme).palette.navLink.dark};

        &:before {
            content: '';
            display: block;
            border-radius: 1px;
            width: 100%;
            height: 2px;
            background: ${({ theme }) => (theme as TCustomTheme).palette.navLink.dark};
            left: 0;
            top: 20px;
            position: absolute;
        }
    }
`;

export default function NavBar() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const theme: TCustomTheme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { data: staticPages = [] } = useGetPagesQuery();
  const { data: dashboards = [] } = useGetDashboardsQuery();
  const { data: settings } = useLoadSettingsQuery();

  const navigate = useNavigate();

  const handleLogOut = () => {
    clearLogout(dispatch);
    localStorage.clear();
  };
  const navItems = [
    <NavLinkStyled key='home' theme={theme} to='/home'>
      Home
    </NavLinkStyled>,
  ];
  if (dashboards.length > 0 && !isUnpaidTier(settings)) {
    navItems.push(<NavLinkStyled key='dashboard' theme={theme} to='/dashboard'>
      Dashboard
    </NavLinkStyled>);
  }
  if (staticPages.length > 0) {
    navItems.push(...staticPages.map(page => <NavLinkStyled key={page._id} theme={theme}
                                                            to={`/page/${page._id}`}>
      {page.title}
    </NavLinkStyled>));
  }
  navItems.push(<NavLinkStyled key='settings' to='/settings'>Settings</NavLinkStyled>);
  navItems.push(<NavLinkStyled key='logoff' to='/' onClick={handleLogOut}>
     Sign Out
  </NavLinkStyled>);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <>
      <Stack sx={{ alignItems: 'flex-end' }}>
        <IconButton color='inherit' aria-label='open drawer' edge='end'
                    onClick={handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <List>
          {navItems.map((item, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton
                sx={{
                  textAlign: 'left',
                  paddingLeft: 0,
                }}
              >
                <Typography variant='h3'>{item}</Typography>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component='nav'>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            ...(isDesktop && {
              paddingLeft: '120px !important',
              paddingRight: '120px !important',
            }),
            height: '72px',
          }}
        >
          <Stack direction='row' alignItems='center' gap='12px'
                 onClick={() => navigate('/home')} sx={{ cursor: 'pointer' }}>
            <img src='/logo.png' alt='logo' style={{ height: '40px', width: '40px' }} />
            {isDesktop && <Typography variant='h2' color={theme.palette.mode === 'light'
              ? '#9E768F'
              : '#CBB5C3'}>Rose</Typography>}
          </Stack>
          {(settings && isFreeTier(settings)) && <Timer timerType={'free-days'}/>}
         {settings && <Timer />}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{<Typography
            variant='h3'>{navItems}</Typography>}</Box>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='end'
            onClick={handleDrawerToggle}
            sx={{
              paddingLeft: 0,
              display: { sm: 'none' },
            }}
          >
            <MenuIcon className='drawerOpener' />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          key='navbar_drawer'
          variant='temporary'
          anchor='right'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              padding: '16px 16px 0 42px',
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

