import { Button, Grid, styled, Typography, useTheme } from '@mui/material';
import { TFinancialDiscount } from '../../redux/api/stripeApiSlice';
import { TCustomTheme } from '../../theme';

export type TSubscriptionTileProps = {
  price: number;
  label: string;
  discount?: TFinancialDiscount;
  onSubscribe: () => void;
}

const SubscriptionTileRoot = styled(Grid, {
  name: 'SubscriptionTile',
  slot: 'root',
})(({ theme }: { theme: TCustomTheme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  width: '100%',
  padding: '16px',
  border: `1px solid ${theme.palette.settingsTile.main}`,
  background: 'transparent',
  borderRadius: theme.shape.borderRadius,
  flexWrap: 'wrap',
}));

function formatDiscountedPrice(discount: TFinancialDiscount, price: number) {
  if (discount.type === 'fixed') {
    return <>
      <s>${price}</s> ${price - (discount.amount / 100)}
    </>;
  }
  else {
    return <>
      <s>${price}</s> ${(price * (100 - discount.amount) / 100).toFixed(2)}
    </>;
  }
}

export default function SubscriptionTile({
  label,
  price,
  discount,
  onSubscribe,
}: TSubscriptionTileProps) {
  const theme = useTheme() as TCustomTheme;

  return <SubscriptionTileRoot
    theme={theme}
    item
    sx={{
      gap: '16px',
    }}>
    <Grid item display='flex' flexWrap='nowrap' flexGrow={100} flexBasis='350px'>
      <Typography>
        {label}
      </Typography>
      <Grid item flexGrow={1} />
      {discount ?
        <Typography>
          {formatDiscountedPrice(discount, price / 100)}
        </Typography> :
        <Typography>
          ${price / 100}
        </Typography>
      }
    </Grid>
    <Button variant='outlined' onClick={onSubscribe} sx={{flexGrow: 1}}>
      Subscribe
    </Button>
  </SubscriptionTileRoot>;
}
