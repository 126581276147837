import { ArrowDownward } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ARROW_TOLERANCE = 20 as const;

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const { height: windowHeight } = useWindowSize();
  const [{ y: scrollTop }] = useWindowScroll();
  const [bodyHeight, setBodyHeight] = useState(0);

  useEffect(() => {
    window.scroll({ top: -1, left: 0, behavior: 'smooth' });
  }, [pathname]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setBodyHeight(entries[0].target.clientHeight);
    });
    resizeObserver.observe(document.body);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  if (windowHeight === null || scrollTop === null) {
    return null;
  }

  const scrollOneScreenFurther = () => {
    window.scroll({
      top: scrollTop + windowHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const needArrow = windowHeight < bodyHeight &&
    scrollTop < bodyHeight - windowHeight - ARROW_TOLERANCE;

  return needArrow ? <Fab
    variant='circular'
    size='small'
    sx={{
      position: 'fixed',
      right: 5,
      bottom: 5,
    }}
    onClick={() => scrollOneScreenFurther()}
  >
    <ArrowDownward />
  </Fab> : null;
};

export default ScrollToTop;
