import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ClickAwayListener, Grid, Stack, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { convertSecondsToHMS } from '../shared/convertor';
import { useLoadSettingsQuery} from '../redux/api/settingsApiSlice';

interface IProps {
  timerType?: 'player' | 'free-days';
}

function dateDiffInDays(max: Date, curr: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  return Math.ceil((curr.getTime() - max.getTime()) / _MS_PER_DAY);
}

export const Timer = ({ timerType = 'player' }: IProps) => {
  const { data: settings } = useLoadSettingsQuery();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const renderTime = () => {
    let value: string = '';
    switch (timerType) {
      case 'player': {
        value = convertSecondsToHMS(settings?.timeLimit!, true);
        break;
      }
      case 'free-days': {
        const currentDate = new Date();

        if (settings?.freeTierEndDate) {
          const freeTierEndDate = new Date(settings.freeTierEndDate);
          const diffDays = dateDiffInDays(currentDate, freeTierEndDate);
          value = `${diffDays}`;
        }
        break;
      }
      default:
        break;
    }
    return value;
  };

  const renderTooltipTitle = (days: string) => {
    let value: React.ReactNode | null = null;
    switch (timerType) {
      case 'player': {
        value = settings?.paidTier === 'unpaid'
        ? <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Time remaining for audio playback. Time resets weekly.
        </Typography>
        : <>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Time remaining for audio playback. Time resets daily at<b> 12 midnight
          PST</b>.
        </Typography>
        <Typography variant='body1'>
          You may still finish a path that you have started if you go over the daily
          limit.
        </Typography>
        </>

        break;
      }
      case 'free-days': {
        value =  <Typography variant='body1' sx={{ marginBottom: '10px' }}>
        You have {days} more days of <b>Test Drive</b>.
      </Typography>;
        break;
      }
      default:
        break;
    }
    return value;
  };

  return (
    <Stack className='Timer' direction='row' gap='6px'>
      <Typography variant='body1'>{renderTime()}</Typography>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Grid container direction='column'
                  sx={{
                    background: '#D4F1F9',
                    padding: '16px',
                    borderRadius: '5px',
                    '& .MuiTypography-root': {
                      color: '#46485B',
                    },
                  }}
            >
              {renderTooltipTitle(renderTime())}
            </ Grid>
          }
          placement='bottom'
        >
          <InfoOutlinedIcon onClick={handleTooltipOpen} className='infoIcon' sx={{
            color: '#6A6C7E',
            width: '20px',
            height: '20px',
            cursor: 'pointer',
          }} />
        </Tooltip>
      </ClickAwayListener>
    </Stack>
  );
};

