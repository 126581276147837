import { Box, Button } from '@mui/material';
import { Modal } from '../../components';

export default function PlayerReloadModal({ onClose, restart }: any) {
  return (
    <Modal
      open={true}
      title="Are you sure you'd like to restart?"
      description='Restarting will close the current session and return you to the beginning survey.'
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          sx={{ marginRight: '24px' }}
          variant='outlined'
          onClick={() => {
            onClose(false);
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{ margin: 0 }}
          variant='contained'
          onClick={() => {
            restart();
          }}
        >
          Restart path
        </Button>
      </Box>
    </Modal>
  );
}