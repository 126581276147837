import React from 'react';
import z from 'zod';

type TUseValidateProps<T> = {
  scheme: z.ZodType<T>;
}

export function useValidate<DataType>({scheme}: TUseValidateProps<DataType>) {
  const [errors, setErrors] = React.useState<Partial<Record<keyof DataType, z.ZodIssue | null>>>({});

  const check = (data: DataType): Partial<Record<keyof DataType, z.ZodIssue | null>> => {
    try {
      scheme.parse(data);
      return {};
    } catch (error) {
      if(error instanceof z.ZodError) {
        return error.issues.reduce((acc, curr) => {
          if(curr.path) {
            acc[curr.path[0] as keyof DataType] = curr;
          }
          return acc;
        }, {} as Partial<Record<keyof DataType, z.ZodIssue | null>>);
      }
      return {};
    }
  }

  function validate(data: DataType){
    const result = check(data);
    setErrors(result);
    return Object.values(result).length === 0;
  }

  function clearOneError<Key extends keyof DataType>(field: Key) {
    setErrors(prevState => {
      const copy = { ...prevState };
      delete copy[field];
      return copy;
    })
  }

  function getErrorProps<Key extends keyof DataType>(field: Key, defaultHelper?: string) {
    return {
      error: errors[field] !== undefined,
      helperText: errors[field]?.message || defaultHelper,
    }
  }

  function clearErrors() {
    setErrors({});
  }

  return { errors, validate, clearOneError, clearErrors, getErrorProps };
}
