import { Button } from '@mui/material';
import React from 'react';
import { Modal } from '../../components';

export default function PlayerErrorModal({ errorMessage, onConfirmed }: {
  errorMessage: string;
  onConfirmed: () => void
}) {
  return (
    <Modal open={true} title='Connection was lost' description={errorMessage}>
      <Button
        sx={{ width: '100%' }}
        variant='contained'
        onClick={() => {
          onConfirmed();
        }}
      >
        Go to Home
      </Button>
    </Modal>
  );
}