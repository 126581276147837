import { Container } from '../../components';
import Loading from '../../components/Loading';
import LockedFeatureDialog from '../../components/LockedFeatureDialog';
import NavBar from '../../components/NavBar';
import { useGetDashboardsQuery } from '../../redux/api/dashboardsApiSlice';
import Dashboard from './Dashboard';

export const DashboardPage = () => {
  const { data: dashboards = [] } = useGetDashboardsQuery();

  if (dashboards.length < 1) {
    return <Loading />;
  }

  return (
    <Container>
      <NavBar />
      <LockedFeatureDialog />
      {dashboards.map(dash =>
        <Dashboard
          key={dash._id}
          dashboard={dash}
        />)}
    </Container>
  );
};

