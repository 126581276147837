import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FormControlLabel, Grid, Radio, Typography } from '@mui/material';
import { useState } from 'react';
import { Component } from '../../redux/api/componentsApiSlice';
import { convertSecondsToHMS } from '../../shared/convertor';

type TGroupedComponentProps = {
  title: string;
  subtitle: string;
  options: Component[];
  selected: string;
  onChange: (id: string) => void;
  sameLength: { [key: string]: Component[] };
  type: string;
}

export function GroupedComponent({
  title,
  subtitle,
  options,
  onChange,
  selected,
  sameLength,
}: TGroupedComponentProps) {
  const [isOpened, setIsOpened] = useState(false);

  return <>
    <Grid
      item
      container
      gap='8px'
      alignItems='center'
      direction='row'
      sx={{ cursor: 'pointer' }}
      onClick={() => setIsOpened(prev => !prev)}
      flexWrap='nowrap'
    >
      <Grid item container justifyContent="center" sx={{width: 'calc(1em + 30px)'}}>
        {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Grid>
      <Grid item flexDirection='column' alignItems='flex-start'>
        <Typography variant='body1' sx={{overflowWrap: 'anywhere'}}>
          {title} <span
          style={{ color: 'inherit' }}>(Adjust your time)</span>
        </Typography>
        <Typography variant='body1' sx={{ fontSize: '80%' }}>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
    {isOpened && <Grid container direction='column' sx={{paddingLeft: '2em'}}>
      {options.map(component => {
        const convertLength = convertSecondsToHMS(component.audioLength, false);

        let additionalText = '';
        if (sameLength[`${convertLength}${title}`]) {
          const item = sameLength[`${convertLength}${title}`].find((elem: any) => elem._id === component._id);
          additionalText = `(From "${item?.duration.name}")`;
        }

        return <FormControlLabel
          checked={component._id === selected}
          key={component._id}
          value={component._id}
          control={<Radio />}
          onChange={() => onChange(component._id)}
          label={`${convertSecondsToHMS(component.audioLength)} ${additionalText}`}
        />;
      })}
    </Grid>}
  </>;
}