import { TAuthHelperType } from '../redux/actions/userActions';
import {
  isUser, useCodeLoginMutation,
  useFacebookLoginMutation,
  useFacebookRegisterMutation,
  useGoogleLoginMutation,
  useGoogleRegisterMutation,
  useInstagramLoginMutation, useInstagramRegisterMutation,
  useTwitterLoginMutation, useTwitterRegisterMutation,
} from '../redux/api/authApiSlice';

export default function useHelperLogin() {
  const [facebookLogin, {isLoading: isFacebookLoginLoading}] = useFacebookLoginMutation();
  const [instagramLogin, {isLoading: isInstagramLoginLoading}] = useInstagramLoginMutation();
  const [twitterLogin, {isLoading: isTwitterLoginLoading}] = useTwitterLoginMutation();
  const [googleLogin, {isLoading: isGoogleLoginLoading}] = useGoogleLoginMutation();
  const [logIn, {isLoading: isCodeLoginLoading}] = useCodeLoginMutation();

  const [googleRegister, {isLoading: isGoogleRegisterLoading}] = useGoogleRegisterMutation();
  const [facebookRegister, {isLoading: isFacebookRegisterLoading}] = useFacebookRegisterMutation();
  const [instagramRegister, {isLoading: isInstagramRegisterLoading}] = useInstagramRegisterMutation();
  const [twitterRegister, {isLoading: isTwitterRegisterLoading}] = useTwitterRegisterMutation();

  const isLoading = isFacebookLoginLoading
          || isInstagramLoginLoading
          || isTwitterLoginLoading
          || isGoogleLoginLoading
          || isCodeLoginLoading
          || isGoogleRegisterLoading
          || isFacebookRegisterLoading
          || isInstagramRegisterLoading
          || isTwitterRegisterLoading;

  const tryAuth = async (
    token: string,
    helper: TAuthHelperType,
  ) => {
    let result;
    switch (helper) {
      case 'facebook':
        result = await facebookLogin(token).unwrap();
        break;
      case 'instagram':
        result = await instagramLogin(token).unwrap();
        break;
      case 'twitter':
        result = await twitterLogin(token).unwrap();
        break;
      case 'google':
        result = await googleLogin(token).unwrap();
        break;
    }
    return isUser(result) ? result : undefined;
  };

  const register = async (
    accessToken: string,
    accessCode: string,
    helper: TAuthHelperType,
  ) => {
    let result;
    switch (helper) {
      case 'google':
        result = await googleRegister({
          accessCode,
          accessToken,
          helperType: helper,
          type: 'code',
        }).unwrap();
        break;
      case 'twitter':
        result = await twitterRegister({
          accessCode,
          accessToken,
          helperType: helper,
        }).unwrap();
        break;
      case 'instagram':
        result = await instagramRegister({
          accessCode,
          accessToken,
          helperType: helper,
        }).unwrap();
        break;
      case 'facebook':
        result = await facebookRegister({
          accessCode,
          accessToken,
          helperType: helper,
        }).unwrap();
        break;
      case 'code':
        result = await logIn(accessCode).unwrap();
        break;
    }
    return result;
  };

  return { tryAuth, register, isLoading };
}
