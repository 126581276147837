import { useNavigate } from 'react-router-dom';
import LockedFeatureDialog from '../../components/LockedFeatureDialog';
import { useGetDurationsQuery } from '../../redux/api/durationsApiSlice';
import { useGeneratePathMutation } from '../../redux/api/pathAlpiSlice';
import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material';

type TPathProps = {
  onDone: (pathId: string) => void;
  goalId: string;
}

function Path({ onDone, goalId }: TPathProps) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const navigate = useNavigate();
  const { data } = useGetDurationsQuery();
  const [generatePath] = useGeneratePathMutation();

  const onClick = async (durationId: string) => {
    try {
      const response = await generatePath({ goalId: goalId, duration: durationId }).unwrap();
      onDone(response.path);
    } catch (e) {
      navigate(`/`);
    }
  };

  return data?.length ? (
    <Box>
      <LockedFeatureDialog />
      <Typography variant="h2">Select time</Typography>
      {data?.map((duration) => (
        <Button
          key={duration._id}
          sx={{
            width: isDesktop ? '224px' : '100%',
            marginTop: '24px',
            ...(isDesktop && { '&:not(:last-child)': { marginRight: '24px' } }),
          }}
          variant="contained"
          onClick={() => {
            onClick(duration._id);
          }}
        >
          {duration.name}
        </Button>
      ))}
    </Box>
  ) : null;
}

export default Path;

