import { TSettings } from "../redux/api/settingsApiSlice";

export const USER_PAID_TIER = {
    free: 'free',
    unpaid: 'unpaid',
    paid: 'paid',
}

export const isFreeTier = (settings?: TSettings) => {
    if (settings?.type === 'beta') {
        return false
    }
    return USER_PAID_TIER.free === settings?.paidTier
}
export const isPaidTier = (settings?: TSettings) => {
    if (settings?.type === 'beta') {
        return false
    }
    return USER_PAID_TIER.paid === settings?.paidTier;
}
export const isUnpaidTier = (settings?: TSettings) => {
    if (settings?.type === 'beta') {
        return false
    }
    return USER_PAID_TIER.unpaid === settings?.paidTier;
}