import { apiSlice } from './apiSlice';

export type ComponentType = 'Cue' | 'Opening' | 'Experience' | 'Reminder';
export type ComponentGoal = {
  _id: string;
  name: string;
};
export type ComponentVoice = ComponentGoal;
export type ComponentDuration = ComponentGoal;

export type Component = {
  _id: string;
  name: string;
  type: ComponentType;
  audioLength: number;
  goals: ComponentGoal[];
  voice: ComponentVoice;
  duration: ComponentDuration;
};

export const componentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadComponents: builder.query<Component[], void>({
      query: () => ({
        url: 'client/components',
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
  }),
});

export const { useLoadComponentsQuery } = componentsApi;

