import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded';
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import {
  Box,
  IconButton,
  Slider,
  Stack,
  styled,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { TPlayerState, TSourceState } from '../../features/Player/Player';
import { ReactComponent as VoiceIcon } from '../../svg/Group.svg';
import { ReactComponent as RestartIcon } from '../../svg/rotate-right.svg';
import { BasicList } from './List';

const Wrapper: any = styled(Box)((props: any) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `25px ${props.desktop === 'true' ? '120px' : '16px'}`,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  borderRadius: '6px 6px 0px 0px',
  background: props.theme.palette.contentBackground.main,
  boxShadow: '0px -7px 11px 0px rgba(66, 65, 75, 0.08)',
}));

const WallPaper = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  overflow: 'hidden',
});

const Widget: any = styled('div')((props: any) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  width: props.desktop === 'true' ? '100%' : 343,
  maxWidth: '100%',
  margin: 'auto',
  position: 'relative',
  zIndex: 1,
}));

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const IconsBlock = styled(Box)((props) => ({
  display: 'inline-flex',
  height: '56px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '15px',
  flexShrink: 0,
  '& .MuiIconButton-root': {
    color: props.theme.palette.primary.main,
  },
}));

const componentNames: Record<string, string> = {
  cue: 'Cue',
  opening: 'Opening',
  experience: 'Experience',
  reminder: 'Reminder',
};

interface IPlayerControls {
  trackList: {
    _id: string;
    name: string;
    isActive?: boolean;
    type: string;
    length: number;
  }[];
  voice: {
    _id: string;
    name: string;
  };
  onRestart: () => void;
  duration: number;
  progress: number;
  handlePlay: () => void;
  isFinished: boolean;
  sourceState: TSourceState;
  playerState: TPlayerState;
  additionalMessage?: string;
  additionalProgress: number;
  additionalDuration: number;
  onHeightChanged: (height: number) => void;
}

export const formatDuration = (value: number) => {
  const minute = Math.floor(value / 60);
  const secondLeft = Math.floor(value - minute * 60);
  return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
};

export const PlayerControls = function(
  {
    trackList,
    onRestart,
    voice,
    duration,
    progress,
    handlePlay,
    isFinished,
    sourceState,
    playerState,
    additionalMessage,
    additionalProgress,
    additionalDuration,
    onHeightChanged
  }: IPlayerControls) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const mode = useTheme().palette.mode;
  const ref = useRef<HTMLDivElement>(null);

  const [isTrackListOpen, setTrackListOpen] = useState(true);
  const [isVoiceOpen, setVoiceOpen] = useState(false);

  const currentTrack = trackList?.find((track) => track.isActive) || trackList[trackList.length - 1];

  const playlist = trackList.map((track) => ({
    ...track,
    name: `${componentNames[track.type]}: ${track.name}`,
  }));

  useEffect(() => {
    if(!ref.current){
      return;
    }
    onHeightChanged(ref.current.offsetHeight);
  });

  useEffect(() => {
    if (isFinished) {
      setTrackListOpen(false);
      setVoiceOpen(false);
    }
  }, [isFinished]);

  let marksValue = 0;
  const marks = trackList.slice(0, -1).map((track) => {
    marksValue += track.length;
    return {
      value: marksValue,
    };
  });
  const correctTimeLeft = duration - progress > 0 ? duration - progress : 0;

  return (
    <Wrapper desktop={`${isDesktop}`} ref={ref}>
      <Widget desktop={`${isDesktop}`}>
        {(isTrackListOpen || isVoiceOpen) && (
          <BasicList
            noMark={!!additionalMessage}
            data={
              isTrackListOpen
                ? playlist
                : [
                  {
                    ...voice,
                    name: `Voice: ${voice.name}`,
                  },
                ]
            }
          />
        )}
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column-reverse',
              ...(isDesktop && {
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10%',
              }),
            }}
          >
            <IconsBlock>
              <IconButton
                sx={{
                  '&:hover': {
                    borderRadius: '5px',
                    background: mode === 'light' ? '#DFDFE5' : '#6A6C7E',
                  },
                  ...(isVoiceOpen && {
                    borderRadius: '5px',
                    background: mode === 'light' ? '#DFDFE5' : '#6A6C7E',
                  }),
                }}
                onClick={() => {
                  setVoiceOpen(!isVoiceOpen);
                  setTrackListOpen(false);
                }}
              >
                <SvgIcon component={VoiceIcon} inheritViewBox color='primary'
                         sx={{ width: '30px', height: '30px' }} />
              </IconButton>
              <IconButton
                sx={{
                  '&:hover': {
                    borderRadius: '5px',
                    background: mode === 'light' ? '#DFDFE5' : '#6A6C7E',
                  },
                }}
                disabled={
                  !trackList.some((item) => item.isActive) || [
                    'idle',
                    'intro',
                    'introPlaying',
                  ].includes(playerState)
                }
                onClick={onRestart}
              >
                <SvgIcon
                  component={RestartIcon}
                  inheritViewBox
                  color='primary'
                  sx={{ width: '30px', height: '30px' }}
                />
              </IconButton>
              <Box>
                <IconButton
                  aria-label={sourceState === 'playing' ? 'pause' : 'play'}
                  disabled={!trackList.some((item) => item.isActive)}
                  onClick={handlePlay}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {sourceState === 'playing' || ['backPlaying', 'introPlaying'].includes(
                    playerState) ? (
                    <PauseCircleRoundedIcon sx={{ width: '56px', height: '56px' }} />
                  ) : (
                    <PlayCircleRoundedIcon sx={{ width: '56px', height: '56px' }} />
                  )}
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  aria-label='track-list'
                  sx={{
                    '&:hover': {
                      borderRadius: '5px',
                      background: mode === 'light' ? '#DFDFE5' : '#6A6C7E',
                    },
                    ...(isTrackListOpen && {
                      borderRadius: '5px',
                      background: mode === 'light' ? '#DFDFE5' : '#6A6C7E',
                    }),
                  }}
                  onClick={() => {
                    setTrackListOpen(!isTrackListOpen);
                    setVoiceOpen(false);
                  }}
                >
                  <FormatListBulletedRoundedIcon
                    sx={{
                      width: '30px',
                      height: '30px',
                    }}
                  />
                </IconButton>
              </Box>
            </IconsBlock>
            <Box sx={{ width: '100%' }}>
              <Stack
                sx={{
                  alignItems: isDesktop ? 'flex-start' : 'center',
                  marginTop: '13px',
                  marginBottom: '16px',
                }}
              >
                <Typography variant='h2' sx={{ overflowWrap: 'anywhere' }}>
                  {additionalMessage ??
                    (isTrackListOpen
                      ? `Total time remaining ${formatDuration(duration - progress > 0
                        ? duration - progress
                        : 0,
                      )} out of ${formatDuration(
                        duration || 0)}`
                      : `${componentNames[currentTrack?.type]}: ${currentTrack?.name}`)}
                </Typography>
              </Stack>
              {!additionalMessage ? (
                <>
                  <Slider
                    aria-label='time-indicator'
                    size='small'
                    value={progress}
                    disableSwap
                    min={0}
                    max={duration}
                    marks={marks}
                    sx={{
                      minWidth: '277px',
                      cursor: 'default',
                      height: 4,
                      '& .MuiSlider-thumb': {
                        display: 'none',
                      },
                      '& .MuiSlider-mark': {
                        height: 10,
                        '&.MuiSlider-markActive': {
                          opacity: 1,
                          backgroundColor: 'currentColor',
                        },
                      },
                    }}
                  />
                  <Stack
                    sx={{
                      alignItems: 'flex-end',
                      mt: -2,
                    }}
                  >
                    <TinyText variant='body1'>{formatDuration(correctTimeLeft)}</TinyText>
                  </Stack>
                </>
              ) : (
                <>
                  <Slider
                    aria-label='time-indicator'
                    size='small'
                    value={additionalProgress}
                    disableSwap
                    min={0}
                    max={additionalDuration}
                    sx={{
                      minWidth: '277px',
                      cursor: 'default',
                      height: 4,
                      '& .MuiSlider-thumb': {
                        display: 'none',
                      },
                      '& .MuiSlider-mark': {
                        height: 10,
                        '&.MuiSlider-markActive': {
                          opacity: 1,
                          backgroundColor: 'currentColor',
                        },
                      },
                    }}
                  />
                  <Stack
                    sx={{
                      alignItems: 'flex-end',
                      mt: -2,
                    }}
                  >
                    <TinyText variant='body1'>
                      {formatDuration(Math.max(
                        additionalDuration - additionalProgress,
                        0,
                      ))}
                    </TinyText>
                  </Stack>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Widget>
      <WallPaper />
    </Wrapper>
  );
};

