import { Box, Button, styled, SxProps, Typography as MuiTypography } from '@mui/material';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { MessageInfo } from '../MessageInfo';
import { Modal } from '../Modal';

const Typography = styled(MuiTypography)(() => ({
  textAlign: 'center',
  color: '#46485B',
}));

interface IProps {
  restart: () => void;
  goHome: () => void;
  onPauseTimeChanged: (time: number) => void;
  isPlayTouched: boolean;
  isFullMode: boolean;
  onHeightChanged: (height: number) => void;
}

const InfoModal = () => {
  return (
    <>
      <Typography variant='body1' textAlign={'center'} sx={{ marginBottom: '10px' }}>
       After  you press play below, you will have the option to pause.
      </Typography>
      <Typography variant='body1' textAlign={'center'} sx={{ marginBottom: '10px' }}>
       If you pause for <b>2 to 10 minutes</b>, and then press play, you will hear a brief message before continuing.
      </Typography>
      <Typography variant='body1' textAlign={'center'}>
       If you pause for more than <b>10 minutes</b>, and then press play, the path will restart from the beginning.
      </Typography>
    </>
  );
};

const WarnModal = () => {
  return (
    <Typography variant='body1' textAlign={'center'}>
      You had more than a <b>2-minute pause</b>, so we've added a reminder message to help
      you concentrate again.
    </Typography>
  );
};

const RestartModal = (props: { restart: () => void; goHome: () => void }) => {
  const { restart, goHome } = props;

  return (
    <Modal
      open={true}
      title=' Restart the path'
      description='You had more than a 10-minute break. In order to get the most benefit from the path, please restart from
          the beginning.'
    >
      <Button
        sx={{ width: '100%' }}
        variant='contained'
        onClick={() => {
          restart();
        }}
      >
        Restart path
      </Button>
      <Button
        sx={{ width: '100%', marginTop: '24px' }}
        variant='outlined'
        onClick={() => {
          goHome();
        }}
      >
        Return to Home
      </Button>
    </Modal>
  );
};

export const PlayerInfo = function(
  { restart, isPlayTouched, goHome, onPauseTimeChanged, onHeightChanged, isFullMode }: IProps,
) {
  const [time, setTime] = React.useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    onHeightChanged(ref.current.offsetHeight);
  }, [!!ref.current, ref.current?.offsetHeight]);

  React.useEffect(() => {
    onPauseTimeChanged(time);
  }, [Math.ceil(time)]);

  React.useEffect(() => {
    if (isPlayTouched) {
      const interval = setInterval(() => {
        setTime((prevState) => prevState + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isPlayTouched]);

  const renderContent = () => {
    if (isPlayTouched && time >= +process.env.REACT_APP_RESTART_TIMEOUT!) {
      return (
        <RestartModal
          restart={() => {
            restart();
            setTime(0);
          }}
          goHome={goHome}
        />
      );
    }

    if (
      isPlayTouched &&
      time < +process.env.REACT_APP_RESTART_TIMEOUT! &&
      time >= +process.env.REACT_APP_NOTIFICATION_TIMOUT!
    ) {
      return <WarnModal />;
    }

    return <InfoModal />;
  };

  const sx: SxProps = isFullMode ? {} : {
    position: 'absolute',
    width: '80%',
    left: '10%',
    top: '0'
  };

  return (
    <Box ref={ref} sx={sx}>
      <MessageInfo>{renderContent()}</MessageInfo>
    </Box>
  );
};

