import { z } from 'zod';

const rtkErrorScheme = z.object({
  data: z.string(),
  error: z.string(),
  status: z.string(),
  originalStatus: z.number(),
});


const rtkThrottleScheme = z.object({
  data: z.string(),
  status: z.number(),
});

const serverErrorScheme = z.object({
  status: z.number(),
  data: z.object({
    message: z.string(),
    statusCode: z.number(),
  }),
});

const serverErrorsScheme = z.object({
  status: z.number(),
  data: z.object({
    message: z.array(z.string()),
    statusCode: z.number(),
  }),
});

type RtkThrottleError = z.infer<typeof rtkThrottleScheme>;

type RtkError = z.infer<typeof rtkErrorScheme>;

type ServerError = z.infer<typeof serverErrorScheme>;

type ServerErrors = z.infer<typeof serverErrorsScheme>;

export function extractError(e: unknown): string {
  if (isRtkError(e)) {
    return e.error;
  }
  if (isServerError(e)) {
    return e.data.message;
  }
  if (isServerErrors(e)) {
    return e.data.message.join(', ');
  }
  if (isRtkThrottleError(e)) {
    return e.data;
  }
  return '';
}

function isRtkThrottleError(e: unknown): e is RtkThrottleError {
  return rtkThrottleScheme.safeParse(e).success;
}

function isRtkError(e: unknown): e is RtkError {
  return rtkErrorScheme.safeParse(e).success;
}

function isServerError(e: unknown): e is ServerError {
  return serverErrorScheme.safeParse(e).success;
}

function isServerErrors(e: unknown): e is ServerErrors {
  return serverErrorsScheme.safeParse(e).success;
}
