import {FilterList, Search} from '@mui/icons-material';
import {Alert, Grid, TextField, Theme, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Breadcrumbs, Container, Title} from '../../components';
import CardsContainer from '../../components/CardsContainer';
import Loading from '../../components/Loading';
import NavBar from '../../components/NavBar';
import Pagination from '../../components/Pagination';
import {Favorite, useLoadFavoritesQuery,} from '../../redux/api/favoritesApiSlice';
import {useLoadSettingsQuery} from '../../redux/api/settingsApiSlice';
import {createPathUri} from '../../shared/factories';
import {isUnpaidTier} from '../../shared/subscriptions';
import EditDialog from './EditDialog';
import {FavoriteCard} from './Favorite';
import RemoveDialog from './RemoveDialog';
import SortDialog from './SortDialog';

export const sortMap = {
  length: 'Length',
  listened: 'Recently listened to',
  saved: 'Recently saved',
  alphabetical: 'Alphabetical',
};

export const Favorites = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const {data: favorites = [], isLoading} = useLoadFavoritesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const {data: settings} = useLoadSettingsQuery();
  const [searchText, setSearchText] = useState('');
  const [sortOpen, setSortOpen] = useState(false);
  const [sortAlgo, setSortAlgo] = useState<'length' | 'listened' | 'saved' | 'alphabetical'>(
    'alphabetical');
  const [selectedToRemove, setSelectedToRemove] = useState<string | undefined>(undefined);
  const [selectedToEdit, setSelectedToEdit] = useState<Favorite | undefined>(undefined);
  const [page, setPage] = useState(1);

  const ITEMS_PER_PAGE = settings?.configuration['favorites-pages'] || 5;

  const filteredFavorites = useMemo(() => {
    return (favorites || [])
      .filter((fav) =>
          fav.name.toLowerCase().includes(searchText.toLowerCase())
          || fav.componentNames.some((name) => name.toLowerCase().includes(
            searchText.toLowerCase(),
          )),
      )
      .sort((a, b) => {
        switch (sortAlgo) {
          case 'length':
            return a.audioLength - b.audioLength;
          case 'saved':
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          case 'listened':
            return new Date(b.lastListen).getTime() - new Date(a.lastListen).getTime();
          case 'alphabetical':
            return a.name.localeCompare(b.name);
          default:
            return 0;
        }
      });
  }, [searchText, favorites, sortAlgo]);
  const pageFavorites = useMemo(() => {
    return filteredFavorites.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);
  }, [filteredFavorites, page]);

  useEffect(() => {
    if (favorites?.length! < 1 || filteredFavorites.length < 1) {
      return;
    }
    if (pageFavorites.length < 1) {
      setPage(1);
    }
  }, [pageFavorites.length, favorites?.length]);

  const maxFreeFavorites = settings?.configuration
    ? settings.configuration['free-tier-favorites-count']
    : 0;
  const isFreeTierSelectionNeeded =
    isUnpaidTier(settings)
    && favorites.filter(f => f.selectedForFreeTier).length < maxFreeFavorites
    && favorites.length > maxFreeFavorites;
  const isFreeTierLimit = isUnpaidTier(settings) && favorites.length > maxFreeFavorites;

  return (
    <Container>
      <NavBar/>
      <Title title='Favorite Paths' backButton/>
      <Breadcrumbs breadcrumbs={[{label: 'Favorite Paths', route: '/favorites'}]}/>
      <Typography variant='body1' sx={{padding: '0 0 32px 16px', color: '#FFF'}}>
        Playback length will vary based on your selected components and voice.
      </Typography>
      <Box>
        <Grid container justifyContent={'flex-start'} alignItems={'stretch'}
              flexWrap='nowrap' gap={'16px'} marginBottom={'24px'}>
          <TextField
            variant='outlined'
            color='primary'
            value={searchText}
            onChange={(ev) => setSearchText(ev.target.value)}
            label='Search'
            InputProps={{
              endAdornment: <Search/>,
            }}
            sx={{
              ...(!isDesktop && {flexBasis: '50%'}),
            }}
          />
          <Button
            variant='outlined'
            color='primary'
            onClick={() => setSortOpen(true)}
            sx={{
              ...(!isDesktop && {flexBasis: '50%'}),
            }}
          >
            <FilterList/>
            <Typography color='primary' noWrap={true}>
              Sort by {sortMap[sortAlgo]}
            </Typography>
          </Button>
        </Grid>
        {isLoading ? <Loading/> : <CardsContainer>
          {isFreeTierSelectionNeeded && (
            <Alert severity='info'>
              Test Drive has concluded. You can select and use up
              to {maxFreeFavorites} favorite paths during Free Tier or <Button
              href='/settings/subscription'>Subscribe to full access</Button>. Select at
              least one favorite path to move further.
            </Alert>
          )}
          {(pageFavorites || []).map((favorite) => (
            <FavoriteCard
              key={favorite._id}
              id={favorite._id}
              name={favorite.name}
              description={favorite.componentNames.join(', ')}
              voice={`${favorite.voice} voice`}
              length={favorite.audioLength}
              hasInactiveComponent={favorite.componentNames.some(name => favorite.inactiveComponents?.includes(
                name))}
              selectedForFreeTier={favorite.selectedForFreeTier}
              onPrepare={() => {
                navigate(createPathUri(favorite.goal, 'favorites', favorite.path));
              }}
              onRemove={() => setSelectedToRemove(favorite._id)}
              onEdit={() => setSelectedToEdit(favorite)}
              isFreeTierLimit={isFreeTierLimit}
              isFreeTierSelectionNeeded={isFreeTierSelectionNeeded}
            />
          ))}
          {pageFavorites.length < 1
            ? <Alert sx={{flexGrow: 1}} severity='info'>There are no favorited paths
              yet. You
              can add one after listening to a path via
              <Button variant='text' onClick={() => navigate('/')}>
                Build Path for Me
              </Button>
              or
              <Button variant='text' onClick={() => navigate('/build-your-own')}>
                Build Your Own Path
              </Button></Alert>
            : <Pagination
              page={page}
              onPageChange={(p) => setPage(p)}
              itemsCount={filteredFavorites.length}
              itemsPerPage={ITEMS_PER_PAGE}
            />
          }
        </CardsContainer>}
      </Box>
      <SortDialog
        open={sortOpen}
        onClose={() => setSortOpen(false)}
        onSortSelected={(algo) => {
          setSortAlgo(algo);
          setSortOpen(false);
        }}
        current={sortAlgo}
      />
      <EditDialog
        favorite={selectedToEdit}
        open={!!selectedToEdit}
        onClose={() => setSelectedToEdit(undefined)}
      />
      <RemoveDialog
        id={selectedToRemove || ''}
        open={!!selectedToRemove}
        onClose={() => setSelectedToRemove(undefined)}
      />
    </Container>
  );
};

