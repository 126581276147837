import {
  Grid,
  Stack,
  SvgIcon,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useWindowSize } from '@uidotdev/usehooks';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, PlayerInfo } from '../../components';
import PlayerAdditionalFragments, {
  TAdditionalFragmentHandler,
} from '../../components/Player/PlayerAdditionalFragments';
import { PlayerControls } from '../../components/Player/PlayerControls';
import { PlayerTimer } from '../../components/Player/PlayerTimer';
import { IGetPathResponse, useGetPathQuery } from '../../redux/api/pathAlpiSlice';
import {
  settingsApi,
  useLoadSettingsQuery,
  useUpdateOnboardingMutation,
} from '../../redux/api/settingsApiSlice';
import { useAppDispatch } from '../../redux/hooks';
import { extractError } from '../../shared/extractors';
import useGetFilePath from '../../shared/useGetFilePath';
import { TUsePlayerActions, usePlayer } from '../../shared/usePlayer';
import { ReactComponent as CloseIcon } from '../../svg/close-circle.svg';
import PlayerCloseModal from './PlayerCloseModal';
import PlayerErrorModal from './PlayerErrorModal';
import PlayerReloadModal from './PlayerReloadModal';
import PlayerWellDone from './PlayerWellDone';

const TOP_CONTROLS_HEIGHT = 40 as const;

const partKeys: Array<keyof Pick<IGetPathResponse, 'cue' | 'opening' | 'experience' | 'reminder'>> = [
  'cue',
  'opening',
  'experience',
  'reminder',
];

export type TPlayerState =
  | 'idle'
  | 'intro'
  | 'introPlaying'
  | 'playing'
  | 'paused'
  | 'back'
  | 'backPlaying'
  | 'exit'
  | 'exited'
  | 'restart'
  | 'restarted'
  | 'finished'
  | 'error'
  | 'done';
export type TSourceState = 'paused' | 'playing' | 'stopped';

const getBackground = (theme: string, isDesktop: boolean, isPaused: boolean) =>
  `url("/player_desktop_${theme}.${isPaused ? 'png' : 'gif'}")`;

type TPlayerProps = {
  onDone: () => void;
  onRestart: () => void;
  sessionId: string;
  pathId: string;
  source: string;
};

export const Player = ({
  onDone,
  onRestart,
  sessionId,
  pathId,
  source,
}: TPlayerProps) => {
  const themeMode = useTheme().palette.mode;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const audioRef = useRef<HTMLAudioElement>(null);
  const additionalRef = useRef<TAdditionalFragmentHandler>(null);

  const [progress, setProgress] = useState(0);
  const [additionalProgress, setAdditionalProgress] = useState(0);
  const [additionalDuration, setAdditionalDuration] = useState(0);
  const [generalProgress, setGeneralProgress] = useState(0);
  const [timerProgress, setTimerProgress] = useState(0);
  const [queue, setQueue] = useState<string[]>([]);
  const [playerState, setPlayerState] = useState<TPlayerState>('idle');
  const [sourceState, setSourceState] = useState<TSourceState>('stopped');
  const [addSourceState, setAddSourceState] = useState<TSourceState>('stopped');
  const [pathError, setPathError] = useState<string>('');
  const [sourceLoading, setSourceLoading] = useState(false);
  const [additionalFragment, setAdditionalFragment] = useState<'intro' | 'back' | null>(
    null);

  const [controlsHeight, setControlsHeight] = useState(0);
  const [infoHeight, setInfoHeight] = useState(0);

  const prevPlayerState = useRef(playerState);
  const prev2PlayerState = useRef(prevPlayerState.current);

  const { data: path, error: pathFetchError } = useGetPathQuery(pathId!);
  const file = useGetFilePath(
    `path/audio/${pathId}/${queue[0]}`,
    { skip: !queue.length },
  );

  const { data: settings} = useLoadSettingsQuery();
  const [updateOnboarding] = useUpdateOnboardingMutation();

  const { height: windowHeight } = useWindowSize();

  const REWIND_TIME = settings?.configuration['rewind-time'] || 5;

  const socketPayload = useMemo(
    () => ({
      path: pathId!,
      source,
      sessionId: sessionId,
    }),
    [pathId, sessionId, source],
  );

  useEffect(() => {
    if (!settings || settings.onboarding?.introductionCompleted) {
      return;
    }
    setPlayerState('intro');
    setAdditionalFragment('intro');
  }, [!!settings, settings?.onboarding?.introductionCompleted]);

  useEffect(() => {
    if (pathFetchError) {
      const message = extractError(pathFetchError);
      setPathError(message);
      setPlayerState('error');
    }
  }, [pathFetchError]);

  const onReconnect = useCallback(
    (actions: TUsePlayerActions) => {
      actions.play(socketPayload);
    },
    [pathId, sessionId, source],
  );

  const { actions, connect, disconnect, errorMessage, timer } = usePlayer(onReconnect);

  // Time limit changes
  useEffect(() => {
    if (timer.timeLimit === undefined) {
      return;
    }
    dispatch(
      settingsApi.util?.updateQueryData('loadSettings', undefined, (prev) => {
        return {
          ...prev,
          timeLimit: timer.timeLimit === undefined ? prev.timeLimit : timer.timeLimit,
        };
      }),
    );
    setTimerProgress(0);
  }, [timer.timeLimit]);

  const handlePlay = () => {
    if (playerState === 'back') {
      setPlayerState('backPlaying');
      additionalRef.current && additionalRef.current.play();
      return;
    }
    if (playerState === 'intro') {
      setPlayerState('introPlaying');
      additionalRef.current && additionalRef.current.play();
      return;
    }
    if (!['playing', 'backPlaying', 'introPlaying'].includes(playerState)) {
      setPlayerState('playing');
      if (!audioRef.current) {
        return;
      }
      if (!sourceLoading) {
        setSourceLoading(true);
        audioRef.current.play().then(() => {
          setSourceLoading(false);
        });
      }
    }
    else {
      if (additionalFragment) {
        setAddSourceState('stopped');
        setPlayerState(additionalFragment);
        additionalRef.current && additionalRef.current.stop();
      }
      else {
        setPlayerState('paused');
      }
    }
  };

  useEffect(() => {
    switch (playerState) {
      case 'idle':
        setSourceState('stopped');
        connect();
        break;
      case 'playing':
        if (['idle', 'introPlaying'].includes(prevPlayerState.current)) {
          actions.play(socketPayload);
        }
        if (['paused', 'backPlaying'].includes(prevPlayerState.current)) {
          actions.resume(socketPayload);
        }
        if (audioRef.current && prevPlayerState.current === 'backPlaying') {
          const newTime = (audioRef.current?.currentTime || 0) - REWIND_TIME;

          audioRef.current.currentTime = newTime > 0 ? newTime : 0;
        }
        setSourceState('playing');
        break;
      case 'paused':
        if (sourceState === 'playing') {
          actions.pause(socketPayload);
          setSourceState('paused');
        }
        setAddSourceState('stopped');
        break;
      case 'restarted':
        if (prev2PlayerState.current !== 'idle') {
          actions.close(socketPayload);
        }
        else {
          disconnect();
        }
        setPlayerState('idle');
        onRestart();
        break;
      case 'exited':
        if (prev2PlayerState.current !== 'idle') {
          actions.close(socketPayload);
        }
        else {
          disconnect();
        }
        setTimeout(() => navigate('/'), 100);
        break;
      case 'finished':
        actions.finish(socketPayload);
        setSourceState('stopped');
        if (settings?.onboarding && !settings.onboarding.firstPathListened) {
          updateOnboarding({
            firstPathListened: true,
          });
        }
        break;
      case 'done':
        onDone();
        break;
      case 'backPlaying':
      case 'introPlaying':
        setAddSourceState('playing');
        break;
      case 'error':
        setSourceState('stopped');
    }
    prev2PlayerState.current = prevPlayerState.current;
    prevPlayerState.current = playerState;
  }, [playerState]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    switch (sourceState) {
      case 'paused':
        !sourceLoading && audioRef.current.pause();
        break;
      case 'playing':
        if (!sourceLoading && playerState === 'playing') {
          setSourceLoading(true);
          audioRef.current.play().then(() => {
            setSourceLoading(false);
          });
        }
        break;
      case 'stopped':
        !sourceLoading && audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setProgress(0);
        setGeneralProgress(0);
        break;
    }
  }, [sourceState, file]);

  // Hook for handling error on pause during loading
  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    if (!sourceLoading && ['paused', 'stopped'].includes(sourceState)) {
      audioRef.current.pause();
    }
  }, [sourceLoading]);

  // Handling path changes
  useEffect(() => {
    if (path) {
      setQueue(partKeys);
      setTimerProgress(0);
    }
  }, [path]);

  // Handling path finish
  useEffect(() => {
    if (playerState === 'playing' && queue.length < 1) {
      setPlayerState('finished');
      setGeneralProgress(0);
    }
  }, [queue.length]);

  // Handling WS errors
  useEffect(() => {
    if (errorMessage !== '') {
      setPlayerState('error');
    }
  }, [errorMessage]);

  // Emulate time spending
  useEffect(() => {
    setTimerProgress((prev) => prev + 1);
  }, [Math.floor(progress)]);

  const trackList = useMemo(() => {
    if (!path) return [];
    return partKeys.map((key) => ({
      name: path[key].name as string,
      type: key,
      _id: path[key]._id,
      length: path[key].audioLength,
      isActive: key === queue[0],
    }));
  }, [queue, path]);

  const handleTrackEnd = () => {
    setGeneralProgress((prev) => prev + (trackList.find(el => el.isActive)?.length || progress));
    setProgress(0);
    setQueue((prev) => prev.slice(1));
  };

  const handlePauseTimeChanged = (time: number) => {
    if (time >= +process.env.REACT_APP_NOTIFICATION_TIMOUT! && playerState === 'paused') {
      setAdditionalFragment('back');
      setPlayerState('back');
    }
  };

  const handleAdditionEnd = () => {
    if (additionalFragment === 'intro') {
      updateOnboarding({
        introductionCompleted: true,
      });
    }
    setAdditionalFragment(null);
    setAddSourceState('stopped');
    if (['backPlaying', 'introPlaying'].includes(playerState)) {
      setPlayerState('playing');
    }
    else {
      setSourceState('playing');
      prev2PlayerState.current = 'playing';
    }
  };

  const fullMode = useMemo(() => {
    return (controlsHeight + infoHeight + TOP_CONTROLS_HEIGHT) < (windowHeight || 0);
  }, [
    controlsHeight,
    infoHeight,
    windowHeight,
  ]);

  const topControlsSx: SxProps = fullMode ? {} : {
    position: 'absolute',
    zIndex: '10',
    width: '90%',
    left: '5%',
    top: '6px',
  };

  return (
    <Container
      shadow={false}
      sx={{
        backgroundImage: getBackground(
          themeMode,
          isDesktop,
          sourceState !== 'playing' && addSourceState !== 'playing',
        ),
        position: 'relative',
        ...(!isDesktop && { '.contentWrapper': { paddingTop: '24px !important' } }),
      }}
    >
      {playerState !== 'finished' && (
        <Stack direction='row' sx={topControlsSx}>
          <PlayerTimer playbackTime={timerProgress} />
          <Grid item flexGrow={1} />
          <SvgIcon
            component={CloseIcon}
            inheritViewBox
            sx={{
              height: '36px',
              width: '36px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setPlayerState('exit');
            }}
          />
        </Stack>
      )}
      {playerState === 'error' && (
        <PlayerErrorModal
          errorMessage={errorMessage === '' ? pathError : errorMessage}
          onConfirmed={() => setPlayerState('exited')}
        />
      )}
      {['paused', 'stopped'].includes(sourceState) &&
        !['backPlaying', 'introPlaying', 'finished'].includes(playerState) && (
          <PlayerInfo
            isFullMode={fullMode}
            onHeightChanged={h => setInfoHeight(h)}
            isPlayTouched={sourceState === 'paused' && addSourceState !== 'playing'}
            goHome={() => setPlayerState('exited')}
            restart={() => setPlayerState('restarted')}
            onPauseTimeChanged={handlePauseTimeChanged}
          />
        )}
      {playerState === 'restart' && (
        <PlayerReloadModal
          onClose={() => setPlayerState(prev2PlayerState.current)}
          restart={() => setPlayerState('restarted')}
        />
      )}
      {playerState === 'exit' && (
        <PlayerCloseModal
          onConfirmed={() => setPlayerState('exited')}
          setShowCloseModal={() => setPlayerState(prev2PlayerState.current)}
        />
      )}
      {playerState === 'finished' &&
        <PlayerWellDone onContinue={() => setPlayerState('done')} />}
      <audio
        onTimeUpdate={(e) => {
          setProgress((e.target as HTMLAudioElement).currentTime);
        }}
        onEnded={() => handleTrackEnd()}
        style={{ display: 'none' }}
        ref={audioRef}
        controls
        src={file}
        playsInline
      />
      {settings && (
        <PlayerAdditionalFragments
          ref={additionalRef}
          settings={settings}
          pathVoice={path?.voice._id}
          type={additionalFragment || 'intro'}
          onEnd={handleAdditionEnd}
          playing={addSourceState === 'playing'}
          onTimeUpdate={setAdditionalProgress}
          onDurationChanges={setAdditionalDuration}
        />
      )}
      <PlayerControls
        onHeightChanged={h => setControlsHeight(h)}
        isFinished={playerState === 'finished'}
        trackList={trackList}
        voice={
          path?.voice ??
          ({} as unknown as {
            name: string;
            _id: string;
          })
        }
        onRestart={() => {
          setPlayerState('restart');
        }}
        progress={generalProgress + progress}
        additionalMessage={
          ['back', 'backPlaying'].includes(playerState)
            ? 'Welcome back'
            : ['intro', 'introPlaying'].includes(playerState)
              ? 'Using Rose'
              : undefined
        }
        additionalProgress={additionalProgress}
        additionalDuration={additionalDuration}
        duration={path?.audioLength || 0}
        handlePlay={handlePlay}
        sourceState={sourceState}
        playerState={playerState}
      />
    </Container>
  );
};

