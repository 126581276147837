import { apiSlice } from './apiSlice';

export type Favorite = {
  _id: string;
  name: string;
  path: string;
  goal: string;
  user: string;
  voice: string;
  voiceId: string;
  durationName: string;
  durationId: string;
  audioLength: number;
  componentNames: string[];
  createdAt: string;
  updatedAt: string;
  lastListen: string;
  inactiveComponents?: string[];
  selectedForFreeTier: boolean;
};
const DAY_SECONDS = 60 * 60 * 24;

export const favoritesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadFavorites: builder.query<Favorite[], void>({
      query: () => ({
        url: 'client/favorites',
        method: 'GET',
      }),
      keepUnusedDataFor: DAY_SECONDS,
      providesTags: ['Favorite'],
    }),
    getFeasibleVoices: builder.query<string[], string>({
      query: (id) => ({
        url: `client/favorites/voices-to-change/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: DAY_SECONDS,
      providesTags: ['FeasibleVoices'],
    }),
    deleteFavorite: builder.mutation<Favorite, string>({
      query: (id) => ({
        url: `client/favorites/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorite'],
    }),
    updateFavorite: builder.mutation<
      Favorite,
      {
        id: string;
        name: string;
        voice: string;
      }
    >({
      query: (data) => {
        const { id, ...rest } = data;
        return {
          url: `client/favorites/update/${id}`,
          method: 'PUT',
          body: rest,
        };
      },
      invalidatesTags: ['Favorite'],
    }),
    createFavorite: builder.mutation<Favorite, {
      path: string;
      name: string;
      sessionId?: string;
      skipHistoryUpdate?: boolean;
    }>({
      query: (data) => ({
        url: `client/favorites`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Favorite'],
    }),
    selectFavorite: builder.mutation<Favorite, string>({
      query: (id) => ({
        url: `client/favorites/${id}/select`,
        method: 'PUT',
      }),
      invalidatesTags: ['Favorite'],
    }),
  }),
});

export const {
  useLoadFavoritesQuery,
  useDeleteFavoriteMutation,
  useCreateFavoriteMutation,
  useGetFeasibleVoicesQuery,
  useUpdateFavoriteMutation,
  useSelectFavoriteMutation,
} = favoritesApi;

