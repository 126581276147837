export function convertCode(source: string): string {
  let tmp = source.toUpperCase();
  tmp = tmp.replace(/[iI]/g, '1');
  tmp = tmp.replace(/[oO]/g, '0');
  tmp = tmp.replace(/[sS]/g, '5');
  tmp = tmp.replace(/[bB]/g, '8');
  return tmp;
}

export function convertSecondsToHMS(seconds: number, allRequired: boolean = false): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60) - (hours * 60);
  const secondsLeft = Math.floor(seconds % 60);

  const result = [];
  if (allRequired || hours > 0) {
    result.push(`${hours}h`);
  }
  if (allRequired || minutes > 0) {
    result.push(`${minutes.toFixed().padStart(2, '0')}m`);
  }
  if (allRequired || secondsLeft > 0) {
    result.push(`${secondsLeft.toFixed().padStart(2, '0')}s`);
  }
  if(result.length === 0){
    result.push('0s');
  }
  return result.join(' ');
}

export function numberSignRequired(input: number): string {
  if (input > 0) {
    return `+${input}`;
  }
  return input.toString();
}