import { Alert, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import {
  changePasswordSchema,
  TChangePasswordData,
} from '../../login/schemas';
import {
  useChangePasswordMutation,
  useDisconnectHelperMutation,
} from '../../redux/api/authManagementApiSlice';
import {
  TAuthHelperFields, TAuthHelpers,
  useLoadSettingsQuery,
} from '../../redux/api/settingsApiSlice';
import { extractError } from '../../shared/extractors';
import { useValidate } from '../../shared/useValidate';
import AuthMethodTile, { TAuthMethod } from './AuthMethodTile';

export const authHelpersLibrary: Record<TAuthHelperFields, TAuthMethod> = {
  facebookUserId: 'facebook',
  googleUserId: 'google',
  instagramUserId: 'instagram',
  twitterUserId: 'twitter',
};

export const authHelpersLabels: Record<TAuthHelperFields, string> = {
  facebookUserId: 'Facebook',
  googleUserId: 'Google',
  instagramUserId: 'Instagram',
  twitterUserId: 'Twitter',
};

export const getFilteredHelpers = (helpers: TAuthHelpers) => {
  return Object.entries(authHelpersLibrary).filter(([field]) => !!(helpers
    ? helpers[field as TAuthHelperFields]
    : false));
}

export default function AuthMethodsPart() {
  const { data: settings } = useLoadSettingsQuery();
  const [changePassword] = useChangePasswordMutation();
  const [disconnectHelper] = useDisconnectHelperMutation();
  const [data, setData] = useState<TChangePasswordData>({
    password: '',
    currentPassword: '',
    retypePassword: '',
  });
  const [disconnectError, setDisconnectError] = useState<string | undefined>();
  const [disconnectMessage, setDisconnectMessage] = useState<string | undefined>();
  const [serverError, setServerError] = useState<string | undefined>();
  const [serverMessage, setServerMessage] = useState<string | undefined>();

  const handleChangePassword = async () => {
    if (!validate(data)) {
      return;
    }
    try {
      const response = await changePassword({
        currentPassword: data.currentPassword,
        newPassword: data.password,
      }).unwrap();
      setServerMessage(response.message);
      setServerError('');
      setData({
        password: '',
        currentPassword: '',
        retypePassword: '',
      });
    } catch (e) {
      setServerError(extractError(e));
      setServerMessage('');
    }
  };

  const {
    getErrorProps,
    validate,
    clearOneError,
  } = useValidate({ scheme: changePasswordSchema });

  function updateData<Key extends keyof TChangePasswordData>(
    field: Key,
    value: TChangePasswordData[Key],
  ) {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    clearOneError(field);
    setServerError(undefined);
  }

  async function handleDisconnect(type: TAuthMethod) {
    try{
      const response = await disconnectHelper(type).unwrap();
      setDisconnectMessage(response.message);
      setDisconnectError('');
    } catch (e) {
      setDisconnectError(extractError(e));
      setDisconnectMessage('');
    }
  }

  return (
    <>
      {settings?.type === 'production' && <Grid container direction='column' gap='12px'>
        <Grid item>
          <Typography>Change password</Typography>
        </Grid>
        <TextField
          fullWidth
          label='Current password'
          type='password'
          value={data.currentPassword}
          onChange={ev => updateData('currentPassword', ev.target.value)}
          {...getErrorProps('currentPassword')}
          required
        />
        <TextField
          fullWidth
          label='New password'
          type='password'
          value={data.password}
          onChange={ev => updateData('password', ev.target.value)}
          {...getErrorProps('password')}
          required
        />
        <TextField
          fullWidth
          label='Retype new password'
          type='password'
          value={data.retypePassword}
          onChange={ev => updateData('retypePassword', ev.target.value)}
          {...getErrorProps('retypePassword')}
          required
        />
        {serverError && <Alert severity='error'>{serverError}</Alert>}
        {serverMessage && <Alert severity='info'>{serverMessage}</Alert>}
        <Button variant='contained' onClick={handleChangePassword}>Change
          password</Button>
      </Grid>}
      {Object.keys(authHelpersLibrary).length > 0 && settings?.type === 'production'
        && <Divider sx={{ margin: '20px 0' }} />}
      {getFilteredHelpers(settings?.helpers || {}).length > 0 && <>
        <Grid container direction='column' gap='12px'>
          <Grid item>
            <Typography>Social Sign In methods</Typography>
          </Grid>
          {Object.entries(authHelpersLibrary).map(([field, icon]) => {
            if (!settings || !settings.helpers) {
              return null;
            }
            if (!settings.helpers[field as TAuthHelperFields]) {
              return null;
            }
            return <AuthMethodTile
              key={field}
              icon={icon}
              label={authHelpersLabels[field as TAuthHelperFields]}
              onDisconnect={() => handleDisconnect(icon)}
            />;
          })}
          {disconnectError && <Alert severity='error'>{disconnectError}</Alert>}
          {disconnectMessage && <Alert severity='info'>{disconnectMessage}</Alert>}
        </Grid>
      </>}
    </>
  );
}