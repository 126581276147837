import { apiSlice } from './apiSlice';

export interface TDashboard {
  _id: string;
  name: string;
  direction: string;
  metricId: string;
  min: number;
  max: number;
  notFitMessage: string;
  effectName: string;
  effectDescription: string;
  showGraph: boolean;
  graphName: string;
}

export interface TPath {
  _id: string;
  name: string;
  componentNames: string[];
  voiceName: string;
  audioLength: number;
  goalId: string;
  result: number;
}
export type TDashboardChartPoint = {
  x: number;
  y: number;
}

export type TDashboardChartData = Record<'pre' | 'post', TDashboardChartPoint[]> & {
  name: string;
};

export const dashboardsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboards: builder.query<TDashboard[], void>({
      query: () => ({
        url: 'client/dashboards',
        method: 'GET',
      }),
      providesTags: ['Dashboards'],
    }),
    getDashboardCards: builder.query<TPath[], string>({
      query: (id) => ({
        url: `client/dashboards/${id}`,
        method: 'GET',
      }),
      providesTags: ['DashboardCards'],
    }),
    getDashboardChartData: builder.query<TDashboardChartData, string>({
      query: (id) => ({
        url: `client/dashboards/${id}/chart`,
        method: 'GET',
      }),
      providesTags: ['DashboardChart'],
    }),
  }),
});

export const {
  useGetDashboardsQuery,
  useGetDashboardCardsQuery,
  useGetDashboardChartDataQuery,
} = dashboardsApi;

