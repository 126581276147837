import {Alert} from '@mui/material';
import Button from '@mui/material/Button';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Card, Container, Title} from '../../components';
import CardsContainer from '../../components/CardsContainer';
import NavBar from '../../components/NavBar';
import Goals from '../../features/Goals';
import {WelcomeTrip} from '../../features/WelcomeTrip/WelcomeTrip';
import {useLoadSettingsQuery} from '../../redux/api/settingsApiSlice';
import {isFreeTier, isUnpaidTier} from '../../shared/subscriptions';
import FavoritesIcon from '../../svg/favorites.png';
import MakeYourOwnIcon from '../../svg/make-your-own-path.png';

export const HomePage = () => {
  const navigate = useNavigate();
  const {data: settings} = useLoadSettingsQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (settings && !settings.onboarding.welcomeTrip) {
      setIsOpen(true);
    }
  }, [settings]);

  const firstPathListened = settings?.onboarding && settings.onboarding.firstPathListened;

  return (
    <Container>
      <NavBar/>

      {isFreeTier(settings) && <Alert sx={{marginTop: '32px'}} severity='info'>
        My ability to use this program during Test Drive requires me to select a minimum
        of one favorite, and I get to keep two favorite paths as long as Free Tier is
        supported.
      </Alert>}
      {isUnpaidTier(settings) && <Alert sx={{marginTop: '32px'}} severity='warning'>
        Your Test Drive has concluded. You can use up to two of your previously saved
        Favorites or <Button href='/settings/subscription'>Subscribe to full
        access</Button>.
      </Alert>}

      <Title title='I Choose Goal; Build Path for Me'/>
      <Goals disabled={isUnpaidTier(settings)}/>
      <Title title='I Build My Path' sx={{marginTop: '24px'}}/>
      {!firstPathListened &&
        <Alert sx={{margin: '32px 0'}} severity='info'>
          To unlock the “Build Your Own Path” option, listen to at least 1 path completely from either “Feel Better Now” or “Create Enduring Change.”
        </Alert>}
      <CardsContainer>
        <Card
          image={FavoritesIcon}
          disabled={!firstPathListened}
          key='favorites'
          name='Favorite Paths'
          description='A list of the paths that are your favorites.'
          linkTitle='Open List'
          onClick={() => {
            navigate(`/favorites`);
          }}
        />
        <Card
          image={MakeYourOwnIcon}
          disabled={!firstPathListened || isUnpaidTier(settings)}
          key='build-your-own'
          name='Build Your Own Path'
          description='After exploring, you can craft your own paths.'
          linkTitle='Make path'
          onClick={() => {
            navigate(`/build-your-own`);
          }}
        />
      </CardsContainer>
      <WelcomeTrip open={isOpen} setIsOpen={setIsOpen}/>
    </Container>
  );
};

