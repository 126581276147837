import { apiSlice } from './apiSlice';

export interface Goal {
  _id: string;
  name: string;
  description: string;
  image?: string;
  parent?: string;
  delta: string;
}

export const goalsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParentGoals: builder.query<Goal[], void>({
      query: () => ({
        url: 'client/goals',
        method: 'GET',
      }),
    }),
    getChildrenGoals: builder.query<Goal[], string>({
      query: (id) => ({
        url: `client/goals/root/${id}`,
        method: 'GET',
      }),
    }),
    getAllParentGoals: builder.query<Goal[], string>({
      query: (id) => ({
        url: `client/goals/parents/${id}`,
        method: 'GET',
      }),
    }),
    getAllGoals: builder.query<Goal[], void>({
      query: () => ({
        url: `client/goals/all`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetParentGoalsQuery,
  useGetChildrenGoalsQuery,
  useGetAllParentGoalsQuery,
  useGetAllGoalsQuery,
} = goalsApi;

