import { Alert, Stack, TextField, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs, Container, ContentWrapper, Title } from '../components';
import { useConfirmInviteMutation } from '../redux/api/userSlice';
import { extractError } from '../shared/extractors';
import { useValidate } from '../shared/useValidate';
import { resetPasswordSchema, TResetPasswordData } from './schemas';

export default function Invite() {
  const { code } = useParams<{ code: string }>();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [data, setData] = useState<TResetPasswordData>({
    password: '',
    retypePassword: '',
  });
  const [serverError, setServerError] = useState<string | undefined>();
  const [serverMessage, setServerMessage] = useState<string | undefined>();
  const {
    getErrorProps,
    validate,
    clearOneError,
  } = useValidate({ scheme: resetPasswordSchema });

  function updateData<Key extends keyof TResetPasswordData>(
    field: Key,
    value: TResetPasswordData[Key],
  ) {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    clearOneError(field);
    setServerError(undefined);
  }

  const navigate = useNavigate();

  const [confirmInvite] = useConfirmInviteMutation();

  const handleSubmit = async () => {
    if (!validate(data)) {
      return;
    }
    try {
      if (code) {
        const message = await confirmInvite({
          code,
          password: data.password,
        }).unwrap();
        setServerMessage(message.message);
        setServerError('');
      }
      else {
        navigate('/');
      }
    } catch (e) {
      setServerError(extractError(e));
    }
  };

  const getContent = () => {
    if (serverMessage) {
      return <>
        <Alert severity='info'>{serverMessage}</Alert>
        <Button
          type='button'
          sx={{ width: isDesktop ? '386px' : '100%', margin: '30px 0' }}
          variant='contained'
          color='primary'
          onClick={() => navigate('/login/email')}
        >
          Authorize with new password
        </Button>
      </>;
    }
    return <>
      <Typography component='p' variant='body1' align='center'>
        As a last step of account conversion please, provide a password, and will be able
        to Sign In to Rose using it.
      </Typography>
      <Stack
        component='div'
        sx={{
          alignItems: 'stretch',
          flexDirection: 'column',
          width: isDesktop ? '351px' : '100%',
          gap: '19px',
        }}
      >
        <TextField
          required
          autoFocus
          value={data.password}
          onChange={ev => updateData('password', ev.target.value)}
          name='password'
          label='Password'
          type='password'
          {...getErrorProps('password')}
        />
        <TextField
          required
          value={data.retypePassword}
          onChange={ev => updateData('retypePassword', ev.target.value)}
          name='retypePassword'
          label='Retype password'
          type='password'
          {...getErrorProps('retypePassword')}
        />
        {serverError && <Alert severity='error'>{serverError}</Alert>}
        <Button type='button' sx={{}} disabled={!(data.password && data.retypePassword)}
                variant='contained' onClick={handleSubmit}>
          Finish
        </Button>
      </Stack>
    </>;
  };

  return (
    <Container>
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src='/logo.png' alt='Logo' height={isDesktop ? '64px' : '64px'}
             width={isDesktop ? '64px' : '64px'} />
      </Box>
      <Title title='Setup your password' backButton />
      <Breadcrumbs firstTitle={'Login'}
                   breadcrumbs={[{ label: 'Setup your password', route: '' }]} />
      <ContentWrapper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
        }}>
        {getContent()}
      </ContentWrapper>
    </Container>
  );
}

